import React from 'react';
import logo from './../../assets/icons/logo-2.svg';
import facebook from './../../assets/icons/facebook.svg';
import instagram from './../../assets/icons/instagram.svg';
import linkedIn from './../../assets/icons/linkedin.svg';
import twitter from './../../assets/icons/twitter.svg';
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-white text-darkGray pb-12 px-4">
      <div className="container mx-auto">
        {/* <div className="flex flex-col justify-between">
          <div className="mb-8 md:mb-0">
            <img 
                alt='logo' 
                src={logo}
                className='h-12'
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8 mt-10">
            <div>
              <h3 className="font-bold mb-2 text-darkGray">Product</h3>
              <ul className="space-y-2 font-light text-darkGray text-sm mt-3">
                <li>Resume Screening</li>
                <li>HR interview</li>
                <li>Exit intervivew</li>
                <li>Technical Assessment</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-2 text-darkGray">Solution</h3>
              <ul className="space-y-2 font-light text-darkGray text-sm mt-3">
                <li>Reusme Screening</li>
                <li>HR Interview</li>
                <li>Technical Assessment</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-2 text-darkGray">Company</h3>
              <ul className="space-y-2 font-light text-darkGray text-sm mt-3">
                <li>
                  <a href='/about-us'>About Us</a>
                </li>
                <li>
                <a href='/about-us'>Our Vision</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-2 text-darkGray">Terms</h3>
              <ul className="space-y-2 font-light text-darkGray text-sm mt-3">
                <li>Privacy Policy</li>
                <li>Term & Conditions</li>
                <li>Refund Policy</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-2 text-darkGray">Contact</h3>
              <p className='font-light text-sm'>for any inquiry contact us</p>
              <p className='font-light text-sm text-blue underline'>support@athmick.com</p>
            </div>
          </div>
        </div> */}

        <div className="pt-8 border-t hidden items-center justify-between flex-wrap gap-4 md:visible md:flex">
          <p className='font-normal text-darkGray text-sm'>&copy;2024 All Rights Reserved</p>
            <ul className='flex gap-2 text-brownGray text-sm'>
              <li className="cursor-pointer hover:text-blue">
                <a href='https://skillassessment.blob.core.windows.net/files/privacy-policy.html'>Privacy policy</a>
              </li>
              <li>|</li>
              <li className="cursor-pointer hover:text-blue" onClick={() => {
                window.location = 'mailto:support@athmick.com'
              }}>Contact Us</li>
            </ul>
            <div className="flex space-x-4">
              <div
                size={18} 
                className="text-darkGray cursor-pointer" 
                onClick={() => {
                  window.open('https://www.facebook.com/people/Athmick/61558493352873/?mibextid=ZbWKwL')
                }}
              >
                <img
                  alt='facebook' 
                  src={facebook}
                  className='h-6 w-6'
                />
              </div>
              <div 
                size={18} 
                className="text-darkGray cursor-pointer"
                onClick={() => {
                  window.open('https://www.instagram.com/athmick/?igsh=MXZtNGM3aHlsaWs3eA%3D%3D')
                }}>
                <img
                  alt='instagram' 
                  src={instagram}
                  className='h-6 w-6'
                />
              </div>
              <div 
                size={18} 
                className="text-darkGray cursor-pointer"
                onClick={() => {
                  window.open('https://www.linkedin.com/company/athmick/posts/?feedView=all')
              }}>
                <img
                  alt='linkedIn' 
                  src={linkedIn}
                  className='h-6 w-6'
                />
              </div>
            </div>
        </div>
        
        <div className="pt-8 border-t flex items-center justify-between flex-wrap gap-4 md:hidden">
          <p className='font-normal text-darkGray text-sm'>&copy;2024 All Rights Reserved</p>
          <div className='flex items-center justify-between w-full'>
            <ul className='flex gap-2 text-brownGray text-sm'>
              <li className="cursor-pointer hover:text-blue">
                <a href='https://skillassessment.blob.core.windows.net/files/privacy-policy.html'>Privacy policy</a>
              </li>
              <li>|</li>
              <li className="cursor-pointer hover:text-blue" onClick={() => {
                window.location = 'mailto:support@athmick.com'
              }}>Contact Us</li>
            </ul>
            <div className="flex space-x-4">
              <div
                size={18} 
                className="text-darkGray cursor-pointer" 
                onClick={() => {
                  window.open('https://www.facebook.com/people/Athmick/61558493352873/?mibextid=ZbWKwL')
                }}
              >
                <img
                  alt='facebook' 
                  src={facebook}
                  className='h-6 w-6'
                />
              </div>
              <div 
                size={18} 
                className="text-darkGray cursor-pointer"
                onClick={() => {
                  window.open('https://www.instagram.com/athmick/?igsh=MXZtNGM3aHlsaWs3eA%3D%3D')
                }}>
                <img
                  alt='instagram' 
                  src={instagram}
                  className='h-6 w-6'
                />
              </div>
              <div 
                size={18} 
                className="text-darkGray cursor-pointer"
                onClick={() => {
                  window.open('https://www.linkedin.com/company/athmick/posts/?feedView=all')
              }}>
                <img
                  alt='linkedIn' 
                  src={linkedIn}
                  className='h-6 w-6'
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;