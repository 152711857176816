import { useEffect, useState } from "react";
import {BounceLoader} from 'react-spinners';
import { Helmet } from "react-helmet-async";
import { InputText } from "primereact/inputtext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from './../../assets/icons/logo-2.svg';
import AuthService from "../../services/auth.service";
import pattern from './../../assets/images/pattern-3.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../contextProviders/ToastContext";

export default function ResetPasswordPage(){
    let successTimeout;

    const navigate = useNavigate();
    const location = useLocation();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const navigateTo = (path) => navigate(path, {replace: true});

    const [newPassword, setNewPassword] = useState("");
    const {showErrorMessage, showSuccessMessage} = useToast();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const getTokenFromUrl = () => {
        const params = new URLSearchParams(location.search);
        return params.get("token");
    };

    useEffect(() => {
        return () => {
            if (successTimeout)
                clearTimeout(successTimeout);
        };
    }, []);


    const password = (value) => {
        if (value.length < 8)
            return "Password must be at least 8 characters.";
        if (!value.match(/\d/) || !value.match(/[a-zA-Z]/))
            return "Password must contain at least 1 letter and 1 number.";

        return "";
    };
    
    const validateForm = () => {
        const newPasswordError = password(newPassword);
        const confirmPasswordError =
          newPassword && confirmPassword !== newPassword
            ? "Passwords do not match." : "";
    
        setError({
            newPassword: newPasswordError,
            confirmPassword: confirmPasswordError,
        });
    
        return !newPasswordError && !confirmPasswordError;
    };
    
    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
    };
    
    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
    };

    const submit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                setLoading(true);
                const response = await AuthService.resetPassword(getTokenFromUrl(), {password: newPassword});
                setLoading(false)
                if(response.status === 200){
                    showSuccessMessage({
                        summary: 'Password successfully changed', 
                        detail: 'Your password has been updated. You can now use your new password to log in.'
                    });
                    successTimeout = setTimeout(() => {
                        navigateTo('/auth/login')
                    }, 3000);            
                }
            } catch (error) {
                setLoading(false)
                showErrorMessage({ 
                    summary: 'Failed', 
                    detail: error?.response?.data?.message  || 'Invalid email or password'
                })
            }
        }     
    }

    return (
        <div className="w-screen h-screen justify-center items-center flex" style={{backgroundImage: `url(${pattern})`}}>
            <Helmet>
                <title>Login | Gen AI Interviewer </title>
                <meta name="description" content="This is the login page of athmick." />
            </Helmet>
            <div className="bg-white shadow-[1px_4px_10px_rgba(0,0,0,0.12)] gap-2 items-start w-[80%] md:w-[60%] xl:w-[25%] justify-center flex flex-col p-6 rounded-xl">
                <img 
                    src={logo}
                    alt="logo"
                    className="h-12 2xl:h-14"
                />
                <h1 className="text-primary font-semibold text-xl mt-5">Enter your new password</h1>
                <span className="text-xs text-brownGray">Your new password must be different to prevous passwords.</span>
                <form onSubmit={submit} className="w-full mt-10 gap-5 flex flex-col text-sm">
                    <div className="flex flex-col gap-1 relative">
                        <label className="text-primary font-semibold text-xs">Enter New Password</label>
                        <InputText
                            type={showNewPassword ? "text" : "password"}
                            value={newPassword}
                            className="border border-l_border h-10 px-2 pr-10 rounded-md text-sm font-normal"
                            placeholder="Enter new password"
                            onChange={handleNewPasswordChange}
                        />
                        <span 
                            onClick={toggleNewPasswordVisibility}
                            className="absolute right-3 top-9 cursor-pointer text-gray-500">
                            {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        {error['newPassword'] && (
                            <span className="text-dr text-xs mt-1">{error['newPassword']}</span>
                        )}
                    </div>

                    <div className="flex flex-col gap-1 relative">
                        <label className="text-primary font-semibold text-xs">Confirm Password</label>
                        <InputText
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            className="border border-l_border h-10 px-2 pr-10 rounded-md text-sm font-normal"
                            placeholder="Confirm password"
                            onChange={handleConfirmPasswordChange}
                        />
                        <span
                            onClick={toggleConfirmPasswordVisibility}
                            className="absolute right-3 top-9 cursor-pointer text-gray-500">
                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        {error['confirmPassword'] && (
                            <span className="text-dr text-xs mt-1">{error['confirmPassword']}</span>
                        )}
                    </div>
                    
                    <button
                        type="submit"
                        className="bg-blue h-10 rounded text-white mt-10 justify-center flex items-center">
                        {loading && <BounceLoader size={26} color="#ffffff" /> }
                        <span className={`font-semibold text-xs ${!loading ? 'visible': 'hidden'}`}>Reset Password</span>
                    </button>
                    <button
                        type="submit"
                        onClick={navigateTo.bind(this, '/auth/login')}
                        className="text-blue h-10 rounded bg-white border-blue border justify-center flex items-center">
                        <span className='font-semibold text-xs'>Back to Sign In</span>
                    </button>
                </form>
            </div>
        </div>
    )
}