import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Common/Header";
import jobDescriptionLogo from './../../assets/icons/jd.svg';
import HRDashboardJDSection from "../../components/HR/Dashboard/HRDashboardJDSection";
import HRDashboardAnalyticsSection from "../../components/HR/Dashboard/HRDashboardAnalyticsSection";


export default function HRDashboard(){
    const navigate = useNavigate();
    const [selectedJD, setSelectedJD] = useState(null);
    const [menuVisibility, setMenuVisibility] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear()?.toString());

    const handleMenuVisibility = (value) => {
        if(value !== menuVisibility)
            setMenuVisibility(value)
    }

    const handleSelectedJd = (value) => {
        if(value !== selectedJD)
            setSelectedJD(value);
    }

    return (
        <div
            onClick={handleSelectedJd.bind(this, null)} 
            className="flex flex-col h-full gap-5 p-5">
            <Header 
                selectedYear={currentYear} 
                setSelectedYear={setCurrentYear}
            />
            <div className="w-full flex flex-col gap-10">
                <HRDashboardAnalyticsSection 
                    selectedYear={currentYear}
                    handleSelectedJd={handleSelectedJd}
                    handleMenuVisibility={handleMenuVisibility}
                />
                {/* <div className="flex gap-5">
                    <HRDashboardJobComposition />
                    <HRDashboardPhaseWise />
                </div> */}
                <HRDashboardJDSection 
                    menuVisibility={menuVisibility}
                    selectedJD={selectedJD}
                    handleSelectedJd={handleSelectedJd}
                    handleMenuVisibility={handleMenuVisibility}
                />
            </div>
            <button
                onClick={() => navigate('/dashboard/job-description/create')}
                className="h-14 w-14 flex justify-center items-center bg-primary rounded-full absolute bottom-10 right-10 drop-shadow shadow-md">
                <img 
                    alt="jd"
                    className="h-7 w-7"
                    src={jobDescriptionLogo}
                />
            </button>
        </div>
    )
}
