import useInView from "../../utils/animations/useInView";

export const AnimatedDiv = ({ children, className, animation = 'fade-up' }) => {
    const [ref, isInView] = useInView({ threshold: 0.1, triggerOnce: true });
    
    const animations = {
      'fade-up': isInView
        ? 'opacity-100 translate-y-0'
        : 'opacity-0 translate-y-10',
      'fade-in': isInView
        ? 'opacity-100'
        : 'opacity-0',
      'slide-in-left': isInView
        ? 'opacity-100 translate-x-0'
        : 'opacity-0 -translate-x-full',
      'slide-in-right': isInView
        ? 'opacity-100 translate-x-0'
        : 'opacity-0 translate-x-full',
    };
  
    return (
      <div 
        ref={ref}
        className={`${className} transition-all duration-1000 ease-out ${animations[animation]}`}
      >
        {children}
      </div>
    );
  };