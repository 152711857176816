// src/context/ToastContext.js
import React, { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);

  const showErrorMessage = (props) => {
    const {detail, summary= 'Required', life = 2000} = props;
    toastRef.current.show({ 
      life,
      detail,
      summary, 
      severity: 'error',
    });
  }

  const showWarningMessage = (props) => {
    const {detail, summary= 'Required', life = 2000} = props;
    toastRef.current.show({  
      life,
      detail,
      summary,
      severity: 'warn',
    });
  }

  const showSuccessMessage = (props) => {
    const {detail, summary= 'Success', life = 2000} = props;
    toastRef.current.show({  
      life,
      detail,
      summary,
      severity: 'success',
    });
  }

  return (
    <ToastContext.Provider value={{showErrorMessage, showSuccessMessage, showWarningMessage}}>
      {children}
      <Toast ref={toastRef} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
