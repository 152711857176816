import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import AnimatedText from '../Common/AnimatedText';
import { InputText } from 'primereact/inputtext';
import CommonService from '../../services/common.service';
import useFormValidation from '../../hooks/useFormValidation';
import { useToast } from '../../contextProviders/ToastContext';
import { useNavigate } from 'react-router-dom';
import ConsoleHelper from '../../utils/consoleHelper';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const EnquiryFormSection = () => {
    const {showSuccessMessage} = useToast();
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [subject, setSubject] = useState(null);
    const [message, setMessage] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);

    const {errors, validateForm } = useFormValidation();

    const [loading, setLoading] = useState(false);


    const submit = async (event) => {
        event.preventDefault()
        const formData = {
            email,
            firstName,
            lastName,
        };

        if (!validateForm(formData)) return;

        try {
            setLoading(true);
            const requestBody = {email, firstName, lastName};
            if(message) requestBody['message'] = message;
            if(subject) requestBody['subject'] = subject;
            if(mobileNumber) requestBody['mobileNumber'] = mobileNumber;

            const response = await CommonService.submitEnquiryRequest(requestBody);
            setLoading(false);
            if(response.status === 200){
                showSuccessMessage({
                    life: 4000,
                    summary: 'Submitted your enquiry',
                    detail: 'Our team will contact you soon.'
                });
                navigate('/');
            }
        } catch (error) {
            setLoading(false);
            ConsoleHelper.error(`error: ${error}`)
        }
    }

    return (
       <section className="bg-[#ECF5FA] py-8 md:pb-0 px-4 text-darkGray">
            <div className="md:max-w-[75%] mx-auto">
                <AnimatedText className="text-2xl md:text-3xl font-bold text-center text-l_g">Need help?</AnimatedText>
                <AnimatedText className="text-2xl md:text-3xl font-bold text-center mt-2">Get in touch with us today!!</AnimatedText>
                <form 
                    onSubmit={submit} 
                    className='bg-white rounded-lg rounded-br-none rounded-bl-none shadow-md w-full mx-auto p-5 md:p-10 py-10 mt-16 gap-5 flex flex-col'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label 
                                htmlFor="firstName" 
                                className="mb-1 font-bold text-sm">First Name<span className="text-[#FF0000]">*</span>
                            </label>
                            <InputText 
                                type="text"  
                                id="firstName"
                                placeholder="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                className="w-full px-3 h-11 border rounded-md text-darkGray mt-2 text-sm border-[#E1E1E1]" 
                            />
                            {errors?.['firstName'] && (!firstName || firstName?.trim()?.length === 0) && (
                                 <span className='mt-4 text-xs text-dr'>{errors['firstName']}</span>
                            )}
                           
                        </div>
                        <div>
                            <label 
                                htmlFor="lastName" 
                                className="mb-1 font-bold text-sm">Last Name<span className="text-[#FF0000]">*</span>
                            </label>
                            <InputText  
                                type="text" 
                                id="lastName"  
                                placeholder="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                                className="w-full px-3 h-11 border rounded-md text-darkGray mt-2 text-sm border-[#E1E1E1]" 
                            />
                            {errors?.['lastName'] && (!lastName || lastName?.trim()?.length === 0) && (
                                 <span className='mt-4 text-xs text-dr'>{errors['lastName']}</span>
                            )}
                        </div>
                    </div>  
                    <div className="">
                        <label 
                            htmlFor="mobileNumber" 
                            className="mb-1 font-bold text-sm">Mobile Number
                        </label>
                        <InputText 
                            type="tel" 
                            id="mobileNumber"
                            placeholder="Mobile Number"
                            onChange={(e) => setMobileNumber(e.target.value)}
                            className="w-full px-3 h-11 border rounded-md text-darkGray mt-2 text-sm border-[#E1E1E1]" 
                        />
                    </div>    
                    <div className="">
                        <label 
                            htmlFor="email" 
                            className="mb-1 font-bold text-sm">Email<span className="text-[#FF0000]">*</span>
                        </label>
                        <InputText  
                            id="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)} 
                            className="w-full px-3 h-11 border rounded-md text-darkGray mt-2 text-sm border-[#E1E1E1]"
                        />
                        {errors?.['email'] && (!email || !emailRegex.test(email)) && (
                            <span className='mt-4 text-xs text-dr'>{errors['email']}</span>
                        )}
                    </div>
                    <div className="">
                        <label htmlFor="subject" className="mb-1 font-bold text-sm">Subject</label>
                        <InputText 
                            type="text" 
                            id="subject" 
                            placeholder="Subject"
                            onChange={(e) => setSubject(e.target.value)} 
                            className="w-full px-3 h-11 border rounded-md text-darkGray mt-2 text-sm border-[#E1E1E1]" 
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="message" className="mb-1 font-bold text-sm">Message</label>
                        <InputText  
                            rows="4"
                            id="message"
                            placeholder="Write your message here"
                            onChange={(e) => setMessage(e.target.value)} 
                            className="w-full px-3 h-11 border rounded-md text-darkGray mt-2 text-sm border-[#E1E1E1]"
                        />
                    </div>
                    <button 
                        type="submit"
                        className="bg-blue text-white h-10 md:h-12 rounded-full text-sm md:text-base transition duration-300 w-40 m-auto transform hover:scale-110 ease-in-out flex justify-center items-center">
                        {!loading && <span className='font-bold'>Submit</span>}
                        {loading && <ReactLoading type="spin" color="white" height={30} width={30}/>}
                    </button>
                </form>
            </div>
        </section>
    );
};

export default EnquiryFormSection;