import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { MdOutlineMicNone, MdMic } from "react-icons/md";
import ConsoleHelper from '../../../utils/consoleHelper';
import { IoPlayCircleOutline, IoStopCircle } from "react-icons/io5";
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import CommonService from '../../../services/common.service';
import { useJDInfo } from '../../../contextProviders/JDInfoContext';
import { extractIdealCandidateAudiosFilePath } from '../../../utils/common';

function JDIdealCandidate(props){
    const {onFinalizedJD, goToPreviousTab} = props;

    const azureToken = useRef();
    const blobRef = useRef(null);
    const azureRegion = useRef();
    const audioRef = useRef(null);  
    const audioStream = useRef(null);
    const mediaRecorder = useRef(null);
    const tokenRefreshIntervalRef = useRef(null);
    const { changeJDInfo } = useJDInfo();
    const [audioURL, setAudioURL] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [recognizer, setRecognizer] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recognizedText, setRecognizedText] = useState('');

    useEffect(() => {
        tokenRefreshIntervalRef.current = setInterval(async () => {
            const response = await CommonService.getAzureToken();
            if (response.status === 200) {
                const { token } = response.data;
                setRecognizer((prevRecognizer) => {
                    if (token && prevRecognizer) prevRecognizer.authorizationToken = token;
                    return prevRecognizer;
                });
            }
        }, 7 * 60 * 1000);

        return () => {
            if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
                mediaRecorder.current.stop();
            }
    
            if (audioStream.current) {
                audioStream.current.getTracks().forEach(track => track.stop());
                audioStream.current = null;
            }

            clearInterval(tokenRefreshIntervalRef.current);
        };
    }, []);

    const initializeRecognizer = async (audioStream) => {
        let responseData = null;
        try {
            const response = await CommonService.getAzureToken();
            responseData = response.data;
            azureToken.current = responseData?.token;
            azureRegion.current = responseData?.region;
        } catch (error) {
            ConsoleHelper.log(JSON.stringify(error));
        }

        const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(azureToken.current, azureRegion.current);
        speechConfig.speechRecognitionLanguage = 'en-IN';

        const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(audioStream);
        
        const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    
        recognizer.recognized = (s, e) => {
            ConsoleHelper.log(`recognizer.recognized ${e.result.reason} ${e.result.text}`);
            if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                if(!e?.result?.text || e?.result?.text?.length === 0) return;
                
                if(e.result.text?.trim() !== 'Play.' && e.result.text.trim() !== 'BJP.'){
                    setRecognizedText(prevText => prevText + ' ' + e.result.text);
                }
            }
            else if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
                ConsoleHelper.log("NOMATCH: Speech could not be recognized.");
            }
        };
        recognizer.canceled = () => {
            ConsoleHelper.log(`recognizer.canceled`);
        };
    
        setRecognizer(recognizer);
        startListening(recognizer);
    };

    const startListening = async (recognizer) => {
        ConsoleHelper.log('startListening');
        setRecognizedText('');
        if (recognizer) {
            try {
                await recognizer.startContinuousRecognitionAsync();
                ConsoleHelper.log("Recognition started");
            } catch (err) {
                ConsoleHelper.error(`Error starting recognition:, ${JSON.stringify(err)}`);
            }
        }
    };

    const stopListening = async () => {
        ConsoleHelper.log('stopListening');
        if (recognizer) {
            try {
                recognizer.close();
                await recognizer.stopContinuousRecognitionAsync();
                if (tokenRefreshIntervalRef.current)
                    clearInterval(tokenRefreshIntervalRef.current);
                
                ConsoleHelper.log("Recognition stopped");
            } catch (err) {
                ConsoleHelper.log(`Error stopping recognition:, ${JSON.stringify(err)}`);
            }
        }
    };

    const startRecording = async () => {
        try {
            setAudioURL(null);
            stopAudio();
            if(isRecording) return;

            audioStream.current = await navigator.mediaDevices.getUserMedia({ audio: true });
            await initializeRecognizer(audioStream.current);
            mediaRecorder.current = new MediaRecorder(audioStream.current);
            mediaRecorder.current.start();
        
            const audioChunks = [];
            mediaRecorder.current.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });
        
            mediaRecorder.current.addEventListener("stop", () => {
                blobRef.current = new Blob(audioChunks);
                const audioUrl = URL.createObjectURL(blobRef.current);
                setAudioURL(audioUrl);
            });
    
            setIsRecording(true);

        } catch (err) {
            ConsoleHelper.error(`Error accessing microphone: ${JSON.stringify(err)}`);
        }
    };

    const onSubmit = async () => {
        try {
            stopRecording();
            stopAudio();

            if(blobRef.current && recognizedText && recognizedText?.length > 0){
                const uniqueId = uuidv4();
                const fileName = `${uniqueId}-${Date.now()}.mp3`;
    
                const presignUrl = await CommonService.generatePresignUrlForIdealCandidateAudio(fileName);
                const {url} = presignUrl.data.data;
                await axios.put(url, blobRef.current, {
                    headers: { 'x-ms-blob-type': 'BlockBlob'}
                });
                changeJDInfo({
                    idealCandidateText: recognizedText,
                    idealCandidateAudioUrl: extractIdealCandidateAudiosFilePath(url, '-'), 
                })
            }
            onFinalizedJD();
        } catch (error) {
            ConsoleHelper.error(`onSubmit ${JSON.stringify(error)}`)
        }
    } 
    
    const stopRecording = () => {
        if(mediaRecorder?.current)
            mediaRecorder.current.stop();
        stopListening();
        setIsRecording(false);
    };

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true)
        }
    };
    
    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }

    const onBack = () => {
        goToPreviousTab();
    }

    return (
        <div className="flex flex-col">
            <div className='bg-white p-5 rounded-md'>
                <div className="flex flex-col gap-5">
                    <h3 className="text-darkGray font-medium text-base">Describe ideal candidate (OPTIONAL)</h3>
                    <div className='flex items-center gap-5'>
                        <div onClick={startRecording} className={`border-[1px] ${isRecording ? 'border-primary': 'border-l_border'} h-12 w-12 rounded-full flex justify-center items-center text-primary cursor-pointer`}>
                            <div className={`border-[1px] ${isRecording ? 'border-primary': 'border-l_border'} h-10 w-10 rounded-full ${isRecording? 'bg-primary': 'bg-[#ECF5FA]'} flex justify-center items-center`}>
                                <MdMic size={21} className={`${isRecording ? 'visible text-white' : 'hidden'}`} />
                                <MdOutlineMicNone size={21} className={`${!isRecording ? 'visible' : 'hidden'}`} />
                            </div>
                        </div>
                        <button
                            onClick={stopRecording} 
                            className={`font-bold ${isRecording ? 'visible': 'hidden'}`}>
                            Stop Voice Recording
                        </button>
                        <div className={`${audioURL ? 'visible': 'hidden'} flex items-center gap-5 border-[1px] border-primary rounded-md h-10 px-3`}>
                            <span>{audioURL}</span>
                            <IoPlayCircleOutline 
                                size={20}
                                onClick={playAudio}
                                className={`cursor-pointer ${!isPlaying ? 'visible': 'hidden'}`}
                            />
                            <IoStopCircle 
                                size={20}
                                onClick={stopAudio}
                                className={`cursor-pointer ${isPlaying ? 'visible': 'hidden'}`}
                            />
                        </div>
                        <audio 
                            ref={audioRef} 
                            src={audioURL}
                            onEnded={() => setIsPlaying(false)} 
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={onBack}
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>
                    Back
                </button>
                <button
                    onClick={onSubmit} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-10'>
                    Finalize JD
                </button>
            </div>
        </div>
    )
}

JDIdealCandidate.propTypes = {
    onFinalizedJD: PropTypes.func,
    setActiveTabIndex: PropTypes.func
}

export default JDIdealCandidate;