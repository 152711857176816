import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {BounceLoader} from 'react-spinners';
import { FaCircleUser } from "react-icons/fa6";
import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import UserService from '../../services/user.service';
import CommonService from '../../services/common.service';
import { extractProfileFilePath } from '../../utils/common';
import { useAuth } from '../../contextProviders/AuthContext';
import { useToast } from '../../contextProviders/ToastContext';

const SettingsDialog = ({ onHide, showChangePasswordDialog }) => {
    const {user, setUser} = useAuth();
    const fileInputRef = useRef(null);
    const imageUrl = user?.['imageUrl'];
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const companyName = user?.['companyName'] || 'NA';
    const [profileImage, setProfileImage] = useState(null);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [email, setEmail] = useState(user?.['email'] || 'NA');
    const [mobile, setMobile] = useState(user?.['mobile'] || 'NA');
    const [lastName, setLastName] = useState(user?.['lastName'] || 'NA');
    const [firstName, setFirstName] = useState(user?.['firstName'] || 'NA');

    const handleEditClick = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setProfileImage(e.target.result);
            reader.readAsDataURL(file);
        }
    };

    const handleUpdate = async (event) => {
        event.preventDefault();
        if (!validateForm()) return; 

        const userId = user['_id'] || user['id'] || {}
        
        try {
            setLoading(true);
            const requestData = {};

            if(firstName !== user['firstName'])
                requestData['firstName'] = firstName;
                
            if(lastName !== user['lastName'])
                requestData['lastName'] = lastName;
            
            if(imageFile){
                const fileName = `${userId}_${imageFile.name.replace(/&/g, '-').replace(/ /g, '-')}`;
    
                const presignUrl = await CommonService.generatePresignUrlForProfiles(fileName);
                const {url} = presignUrl.data.data;
                
                await axios.put(url, imageFile, { headers: { 'x-ms-blob-type': 'BlockBlob'} });
                requestData['imageUrl'] = extractProfileFilePath(url, '-');
            }
    
            const response = await UserService.updateUser(userId, requestData);
            setLoading(false);
            if(response.status === 200){
                setProfileImage(null);
                setImageFile(null);
                const data = response.data['data'];
                setUser(data);
                localStorage.setItem('user', JSON.stringify(data));
                showSuccessMessage({summary: 'Updated', detail: "Updated user information"})
                onHide();
            }

        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newError = {};

        if (!firstName || firstName.trim() === '') {
            newError.firstName = 'First name is required.';
            isValid = false;
        }
        if (!lastName || lastName.trim() === '') {
            newError.lastName = 'Last name is required.';
            isValid = false;
        }
        // if (!mobile || mobile.trim() === '') {
        //     newError.mobile = 'Mobile number is required.';
        //     isValid = false;
        // } else if (!/^\d{10}$/.test(mobile)) { // Example validation for a 10-digit mobile number
        //     newError.mobile = 'Mobile number must be 10 digits.';
        //     isValid = false;
        // }

        setError(newError);
        return isValid;
    };

    const valuesChanged = () => {
        return (
            firstName !== user?.firstName ||
            lastName !== user?.lastName || imageFile
        );
    };

    return (
        <Dialog 
            header="Account" 
            visible={true} 
            onHide={onHide}
            draggable={false}
            headerClassName='py-4'
            className="w-full max-w-lg mx-auto">
            <form onSubmit={handleUpdate} className="flex flex-col space-y-4">
                <div className="flex items-center space-x-4">
                    {imageUrl && !profileImage && (
                        <img 
                            src={imageUrl}
                            alt="Company logo"
                            className="w-12 h-12 rounded-full" 
                        />
                    )}
                    {profileImage && (
                        <img 
                            src={profileImage}
                            alt="Company logo"
                            className="w-12 h-12 rounded-full" 
                        />
                    )}
                    {!imageUrl && !profileImage && (
                        <FaCircleUser 
                            size={50}
                            className="text-primary"
                        />
                    )}
                    <Button 
                        label="Edit"
                        onClick={handleEditClick}
                        className="p-button-text text-blue text-sm font-medium" 
                    />
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept="image/*"
                    className="hidden"
                />

                <div className="space-y-2">
                    <label 
                        htmlFor="firstName"
                        className="block text-sm font-medium text-brownGray">
                        First Name
                    </label>
                    <InputText
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full text-primary font-medium text-sm border h-10 border-l_border rounded-md px-2"
                    />
                    {error['firstName'] && <small className="text-dr block">{error['firstName']}</small>}
                </div>
                <div className="space-y-2">
                    <label 
                        htmlFor="lastName" 
                        className="block text-sm font-medium text-brownGray">
                        Last Name
                    </label>
                    <InputText
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full text-primary font-medium text-sm border h-10 border-l_border rounded-md px-2"
                    />
                    {error['lastName'] && <small className="text-dr block">{error['lastName']}</small>}
                </div>
                <div className="space-y-2">
                    <label 
                        htmlFor="companyName" 
                        className="block text-sm font-medium text-brownGray">
                        Company name
                    </label>
                    <InputText
                        id="companyName"
                        value={companyName}
                        disabled
                        // onChange={(e) => setCompanyName(e.target.value)}
                        className="w-full text-primary font-medium text-sm border h-10 border-l_border rounded-md px-2"
                    />
                </div>

                <div className="space-y-2">
                    <label 
                        htmlFor="mobile" 
                        className="block text-sm font-medium text-brownGray">
                        Mobile
                    </label>
                    <InputText
                        id="mobile"
                        value={mobile}
                        disabled
                        onChange={(e) => setMobile(e.target.value)}
                        className="w-full text-primary font-medium text-sm border h-10 border-l_border rounded-md px-2"
                    />
                </div>

                <div className="space-y-2">
                    <label 
                        htmlFor="email" 
                        className="block text-sm font-medium text-brownGray">
                        Email
                    </label>
                    <InputText
                        id="email"
                        value={email}
                        disabled
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full text-primary font-medium text-sm border h-10 border-l_border rounded-md px-2"
                    />
                </div>

                <div className="space-y-2">
                    <button 
                        onClick={showChangePasswordDialog.bind(this)}
                        className="p-button-text text-sm text-blue p-0 font-semibold">
                        Change password
                    </button>
                </div>

                <button
                    type="submit"
                    disabled={!valuesChanged()}
                    className={`w-40 bg-blue rounded-md text-white h-10 font-normal flex justify-center items-center ${!valuesChanged() ? 'opacity-40': 'opacity-100'}`} >
                    {loading && <BounceLoader size={26} color="#ffffff" /> }
                    <span className={`font-semibold text-sm ${!loading ? 'visible': 'hidden'}`}>Update & Save</span>
                </button>
            </form>
        </Dialog>
    );
};

export default SettingsDialog;