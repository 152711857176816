import Colors from '../../constants/colors';
import { FaMicrophone } from "react-icons/fa";
import { Dropdown } from 'primereact/dropdown';
import { IoMdCheckmark } from "react-icons/io";
import { PiSpeakerHighBold } from "react-icons/pi";
import { useEffect, useRef, useState } from "react";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { useMediaTest } from "../../contextProviders/MediaTestContext";
import { useSessionContext } from "../../contextProviders/SessionContext";
import { useMediaContext } from "../../contextProviders/InterviewMediaContext";
import ConsoleHelper from '../../utils/consoleHelper';
  

export default function InterviewDevicesSelectionComponent(){
    const videoRef = useRef();
    const {videoStream} = useMediaContext();
    const {setIsFaceDetected} = useSessionContext();
    const [videoReady, setVideoReady] = useState(false);
    const [faceDetected, setFaceDetected] = useState(false);
    const [modelsLoaded, setModelsLoaded] = useState(false);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);
    const [selectedMicrophone, setSelectedMicrophone] = useState(null);
  
    const {     
      requestCameraPermission,
      requestSpeakerPermission,
      cameraStatus,
      speakerStatus,
      requestMicrophonePermission,
      microphoneStatus,
      requestScreeningSharingPermission,
      screenSharingStatus, 
    } = useMediaTest();

    const {setIsPermissionsTested} = useSessionContext();

    const [devices, setDevices] = useState({
        microphones: [],
        speakers: [],
        cameras: [],
    });

    // useEffect(() => {
    //     const loadModels = async () => {
    //       await FaceDetection.loadModels();
    //       setModelsLoaded(true);
    //     }
    //     loadModels();
    // }, []);

    const handleVideoOnPlay = () => {
        setVideoReady(true);
    }

    useEffect(() => {
        if (videoReady && modelsLoaded) {
          const detectFaces = setInterval(async () => {
            if (videoRef.current) {
              const video = videoRef.current;
    
            //   const displaySize = { width: video.videoWidth, height: video.videoHeight };
            //   const isFaceDetected = await FaceDetection.isFaceDetected(video);
    
            //   if (isFaceDetected) {
            //     setFaceDetected(true);
            //     setIsFaceDetected(true);
                
            //   } else {
            //     setFaceDetected(false);
            //     setIsFaceDetected(false);
            //   }
            }
          }, 1000);
    
          return () => clearInterval(detectFaces);
        }
      }, [videoReady, modelsLoaded]);
    
        
    useEffect(() => {
        const getMediaDevices = async () => {
          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const microphones = devices.filter(device => device.kind === 'audioinput')
                ?.map((device) => ({label: device.label, code: device.deviceId}));
            const speakers = devices.filter(device => device.kind === 'audiooutput')
                ?.map((device) => ({label: device.label, code: device.deviceId}))
            const cameras = devices.filter(device => device.kind === 'videoinput')
                ?.map((device) => ({label: device.label, code: device.deviceId}))
            
            setDevices({ microphones, speakers, cameras });
            if(cameras?.length > 0)
                setSelectedCamera(cameras[0]);
            if(speakers?.length > 0)
                setSelectedSpeaker(speakers[0]);
            if(microphones?.length > 0)
                setSelectedMicrophone(microphones[0]);
          } catch (error) {
            ConsoleHelper.error(`Error accessing media devices.', ${JSON.stringify(error)}`);
          }
        };
    
        getMediaDevices();
    }, []);

    useEffect(() => {
      if(cameraStatus === "working" && microphoneStatus === "working" && screenSharingStatus === "working" && speakerStatus === "working"){
        setIsPermissionsTested(true);
      }

    }, [
      requestCameraPermission,
      requestSpeakerPermission,
      cameraStatus,
      speakerStatus
    ])

    useEffect(() => {
        if(videoRef.current){
            videoRef.current.srcObject = videoStream;
        }
    }, [videoStream])

    return (
        <div className='flex flex-col items-center gap-6 p-7 z-20'>
            <h3 className='font-semibold text-primary text-base 3xl:text-xl text-center'>
                You are about the join the meeting
            </h3>
            <div className='bg-black rounded-2xl relative justify-center items-center flex'>
                <video  
                    muted 
                    autoPlay
                    ref={videoRef}
                    onPlay={handleVideoOnPlay} 
                    className="rounded-2xl min-h-44 max-h-44 w-80 3xl:min-h-60 3xl:max-h-60 3xl:w-100"
                />
            </div>
            <div className='w-full flex flex-col gap-2'>
                <div className="flex justify-between text-xs 3xl:text-sm">
                    <label className='font-medium'>Camera</label>
                    <div className={`flex items-center gap-2 ${cameraStatus === "working"? 'text-dg': 'text-black'}`}>
                        <button 
                            onClick={requestCameraPermission}
                            className="text-xs 3xl:text-sm underline cursor-pointer">
                            {cameraStatus === "working"? 'Tested Camera': "Test Camera"}
                        </button>
                        <IoMdCheckmark 
                          size={window.screen.width > 1500 ? 20: 12}
                          className={`${cameraStatus === 'working' ? 'visible': 'hidden'}`}
                        />
                    </div>
                </div>
                <Dropdown 
                    value={selectedCamera}  
                    options={devices.cameras}
                    onChange={(e) => setSelectedCamera(e.value)}
                    className="w-full md:w-14rem rounded-lg border-[.1px] border-gray"
                    valueTemplate={(option) => {
                        return (
                            <div className='flex items-center gap-5'>
                                <BsFillCameraVideoFill 
                                    color={Colors.PRIMARY} 
                                    size={window.screen.width > 1500 ? 18: 12}
                                />
                                <span className='font-semibold text-primary text-xs 3xl:text-sm'>{option?.label}</span>
                            </div>
                        )
                    }}
                />
            </div>
            <div className='w-full flex flex-col gap-2'>
                <div className="flex justify-between text-xs 3xl:text-sm">
                    <label className='font-medium'>Microphone</label>
                    <div className={`flex items-center gap-2 ${microphoneStatus === "working"? 'text-dg': 'text-black'}`}>
                        <button 
                            onClick={requestMicrophonePermission}
                            className="text-xs 3xl:text-sm underline cursor-pointer">
                            {microphoneStatus === "working"? 'Tested Microphone': "Test Microphone"}
                        </button>
                        <IoMdCheckmark 
                          size={window.screen.width > 1500 ? 20: 12}
                          className={`${microphoneStatus === 'working' ? 'visible': 'hidden'}`}
                        />
                    </div>
                </div>
                <Dropdown 
                    value={selectedMicrophone}  
                    options={devices.microphones}
                    onChange={(e) => setSelectedMicrophone(e.value)}
                    className="w-full md:w-14rem rounded-lg border-[.1px] border-gray"
                    valueTemplate={(option) => {
                        return (
                            <div className='flex items-center gap-5'>
                                <FaMicrophone 
                                    color={Colors.PRIMARY} 
                                    size={window.screen.width > 1500 ? 18: 12}
                                />
                                <span className='font-semibold text-primary text-xs 3xl:text-sm'>{option?.label}</span>
                            </div>
                        )
                    }}
                />
            </div>
            <div className='w-full flex flex-col gap-2'>
                <div className="flex justify-between text-xs 3xl:text-sm items-center">
                    <label className='font-medium'>Speakers</label>
                    <div className={`flex items-center gap-2 ${speakerStatus === "working"? 'text-dg': 'text-black'}`}>
                        <button 
                            onClick={requestSpeakerPermission}
                            className="text-xs 3xl:text-sm underline cursor-pointer">
                            {speakerStatus === "working"? 'Tested Speaker': "Test Speaker"}
                        </button>
                        <IoMdCheckmark 
                          size={window.screen.width > 1500 ? 20: 12}
                          className={`${speakerStatus === 'working' ? 'visible': 'hidden'}`}
                        />
                    </div>
                </div>
                <Dropdown 
                    value={selectedSpeaker}  
                    options={devices.speakers}
                    onChange={(e) => setSelectedSpeaker(e.value)}
                    className="w-full md:w-14rem rounded-lg border-[.1px] border-gray"
                    valueTemplate={(option) => {
                        return (
                            <div className='flex items-center gap-5'>
                                <PiSpeakerHighBold 
                                    color={Colors.PRIMARY}
                                    size={window.screen.width > 1500 ? 18: 12}
                                />
                                <span className='font-semibold text-primary text-xs 3xl:text-sm text-sm'>{option?.label}</span>
                            </div>
                        )
                    }}
                />
            </div>
            <div className='w-full flex flex-col gap-2'>
                <div className="flex justify-between text-xs 3xl:text-sm items-center">
                    <label className='font-medium'>Screen sharing</label>
                    <div className={`flex items-center gap-2 ${screenSharingStatus === "working"? 'text-dg': 'text-black'}`}>
                        <button 
                            onClick={requestScreeningSharingPermission}
                            className="text-xs 3xl:text-sm underline cursor-pointer">
                            {screenSharingStatus === "working"? 'Tested Screening sharing': "Test Screening sharing"}
                        </button>
                        <IoMdCheckmark 
                          size={window.screen.width > 1500 ? 20: 12}
                          className={`${screenSharingStatus === 'working' ? 'visible': 'hidden'}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}