import HiringStats from "../components/Landing/HiringStats";
import HeroSection from "../components/Landing/HeroSection";
import InterviewSection from "../components/Landing/InterviewSection";
import SatisfactionBanner from "../components/Landing/SatisfactionBanner";
import TalentInnovatorPage from "../components/Landing/TalentInnovatorPage";
import TestimonialSection from "../components/Landing/TestimonialSection";
import FeatureCardsSection from "../components/Landing/FeatureCardsSection";
import HiringFeaturesSection from "../components/Landing/HiringFeaturesSection";
import InterviewPlatformSection from "../components/Landing/InterviewPlatformSection";
import { Helmet } from "react-helmet-async";

export default function LandingPage(){
    return (
        <div className="w-screen overflow-hidden">
            <Helmet>
                <title>Home | Gen AI Interviewer </title>
                <meta name="description" content="Athmick AI revolutionizes recruitment with AI-driven, automated solutions that enable hiring best-fit candidates within 24 hours." />
                <meta name="keywords" content="AI recruitment, automated hiring, bias-free evaluations, resume-less hiring, JD-less hiring, 24-hour hiring, candidate matching, AI-driven assessments, global talent acquisition, recruitment automation" />
            </Helmet>
            <HeroSection />
            <SatisfactionBanner />
            <InterviewPlatformSection />
            <InterviewSection />
            <TalentInnovatorPage />
            <HiringFeaturesSection />
            <HiringStats />
            <TestimonialSection />
            <FeatureCardsSection />
        </div>
    )
}