import AnimatedText from '../Common/AnimatedText';
import logo from './../../assets/icons/logo.svg';
import ourVision from './../../assets/images/our-vision.svg';
import heroPattern from './../../assets/images/hero-pattern.svg';
import { AnimatedImage } from '../Common/AnimatedImage';
import { AnimatedDiv } from '../Common/AnimatedDiv';

export default function HeroSection() {

    return (
        <section className="bg-white">
            <img 
                alt='hero-pattern'
                src={heroPattern}
                className='absolute top-0 left-0'
            />
            <div className='w-full md:max-w-[75%] mx-auto py-10 md:py-16 flex items-center flex-col justify-center'>
                <div className='flex flex-col gap-3 justify-center items-center'>
                    <img
                        alt="logo"
                        src={logo}
                        className='w-32'
                    />
                    <AnimatedText className="font-semibold text-3xl flex-col gap-3 text-darkGray tracking-wide leading-relaxed text-center md:text-left">
                        We are <span className="text-l_g">Athmick</span>                   
                    </AnimatedText>
                    <p className="mb-8  text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                        Gen AI based talent recruitment platform bridging potential talent to peak performance
                    </p>
                </div>
                <div className='flex gap-20 justify-center items-center md:flex-row flex-col-reverse md:p-0 p-8'>
                    <div className='flex-1 flex flex-col md:gap-5 gap-12'>
                        <div className='flex-1 flex flex-col gap-5'>
                            <h1 className="font-semibold text-3xl flex-col gap-3 text-darkGray tracking-wide leading-relaxed text-center md:text-left">
                                Our Vision                  
                            </h1>
                            <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-left">
                                To revolutionize recruitment by harnessing the power of AI to create a fair, efficient, and seamless hiring process that consistently delivers top talent and drives <span className="text-l_g font-semibold">business success</span>.</p>
                        </div>
                        <div className='flex-1 flex flex-col gap-5'>
                            <h1 className="font-semibold text-3xl flex-col gap-3 text-darkGray tracking-wide leading-relaxed text-center md:text-left">
                                Our Mission                  
                            </h1>
                            <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-left">
                                To empower organizations with cutting-edge AI-driven tools that streamline every stage of the hiring process. We enable enterprises to hire for any role, in any industry, <span className="text-l_g font-semibold">within 24 hours</span>, ensuring the best-fit candidates.
                            </p>
                        </div>
                    </div>
                    
                    <AnimatedDiv className='flex-1 flex justify-end items-end'>
                        <AnimatedImage 
                            src={ourVision}
                            alt='Our Vision'
                        />
                    </AnimatedDiv>
                </div>
            </div>
        </section>
    );
}