import PropTypes from 'prop-types';
import logo from './../assets/icons/logo.svg';

function ConfirmDialog(props){
    const {cancel, accept, message, title} = props;

    return (
        <div className='absolute h-screen w-screen top-0 left-0 flex justify-center items-center z-40 bg-transparent'>
            <div className="bg-white p-3 rounded-md flex justify-center items-center w-1/4 shadow shadow-gray">
                <div className='flex flex-col justify-center items-center gap-5'>
                    <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex">
                        <img 
                            src={logo} 
                            className="h-11 w-11"
                            alt="logo"
                        />
                    </div>
                    <h2 className="text-[#333333] font-bold text-xl">{title || 'Are you sure?'}</h2>
                    <p className="text-brownGray text-base font-normal text-center w-2/3">{message || 'Would you like to send the invitation of the resume less interview?'}</p>
                    <div className="flex items-center justify-center gap-10 py-5">
                        <button onClick={cancel} className="h-10 border border-primary w-32 rounded-md text-sm font-semibold">NO</button>
                        <button onClick={accept} className="bg-primary h-10 w-32 text-white text-sm rounded-md font-semibold">YES</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    cancel: PropTypes.func,
    accept: PropTypes.func,
}

export default ConfirmDialog;