import { useState } from "react";
import Lottie from 'react-lottie';
import {BounceLoader} from 'react-spinners';
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import logo from './../../assets/icons/logo-2.svg';
import AuthService from "../../services/auth.service";
import pattern from './../../assets/images/pattern-3.svg';
import { useToast } from "../../contextProviders/ToastContext";
import * as animationData from '../../assets/animations/email-animation.json';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export default function ForgotPasswordPage(){
    const navigate = useNavigate();
    const {showErrorMessage} = useToast();
    const [error, setError] = useState({});
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSentMailPopup, setShowSentMailPopup] = useState(false);

    const navigateTo = (path) => navigate(path);

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const validate = () => {
        const error = {};
        if(!email)
            error['email'] = 'Please enter email';
        else if ( !emailRegex.test(email))
            error['email'] = 'Enter correct email';

        setError(error);

        if(Object.keys(error).length === 0)
            return true;
        return false;
    }

    const submit = async (event) => {
        event.preventDefault();

        if(!validate()) return;

        try {
            setLoading(true);
            const response = await AuthService.forgotPassword({email});
            setLoading(false)
            if(response.status === 200){
                setShowSentMailPopup(true);
            }
        } catch (error) {
            setLoading(false)
            showErrorMessage({ 
                summary: 'Failed', 
                detail: error?.response?.data?.message  || 'Invalid email or password'
            })
        }
    }

    return (
        <div className="w-screen h-screen justify-center items-center flex" style={{backgroundImage: `url(${pattern})`}}>
            <Helmet>
                <title>Login | Gen AI Interviewer </title>
                <meta name="description" content="This is the login page of athmick." />
            </Helmet>
            <div className={`bg-transparent h-full w-full z-50 absolute top-0 left-0 flex justify-center items-center ${showSentMailPopup ? 'visible': 'hidden'}`}>
                <div className="bg-white shadow-md drop-shadow p-5 py-10 w-1/3 justify-center items-center flex flex-col gap-5 rounded-md">
                    <Lottie 
                        height={120}
                        width={120}
                        options={defaultOptions}
                    />
                    <p className="text-primary text-base text-center">
                        An email has been sent to your address. Please check your inbox to reset your password.
                    </p>
                </div>
            </div>
            <div className="bg-white shadow-[1px_4px_10px_rgba(0,0,0,0.12)] gap-2 items-start w-[80%] md:w-[60%] xl:w-[25%] justify-center flex flex-col p-6 rounded-xl">
                <img 
                    src={logo}
                    alt="logo"
                    className="h-12 2xl:h-14"
                />
                <h1 className="text-primary font-semibold text-xl mt-5">Reset your password</h1>
                <span className="text-xs text-brownGray">Enter the email address you used to register</span>
                <form onSubmit={submit} className="w-full mt-10 gap-5 flex flex-col text-sm">
                    <div className="flex flex-col gap-1">
                        <label className="text-primary font-semibold text-xs">Email</label>
                        <InputText 
                            value={email}
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value?.toLocaleLowerCase())}
                            className="border border-l_border h-10 px-2 rounded-md text-sm font-normal"
                        />
                        {error['email'] &&(
                            <span className="text-dr text-xs mt-1"> {error['email']}</span>
                        )}
                    </div>
                    <button
                        type="submit"
                        className="bg-blue h-10 rounded text-white mt-10 justify-center flex items-center">
                        {loading && <BounceLoader size={26} color="#ffffff" /> }
                        <span className={`font-semibold text-xs ${!loading ? 'visible': 'hidden'}`}>Reset Password</span>
                    </button>
                    <button
                        type="submit"
                        onClick={navigateTo.bind(this, '/auth/login')}
                        className="text-blue h-10 rounded bg-white border-blue border justify-center flex items-center">
                        <span className='font-semibold text-xs'>Back to Sign In</span>
                    </button>
                </form>
            </div>
        </div>
    )
}