import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

const CircularProgress = ({ score }) => {
    const [currentScore, setCurrentScore] = useState(0);
    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    const progress = (currentScore / 100) * circumference;
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setCurrentScore(score);
      }, 100);
      return () => clearTimeout(timer);
    }, [score]);
  
    return (
      <div className="circular-progress">
        <svg width="100" height="100">
          <circle
            className="circle-background"
            cx="50"
            cy="50"
            r={radius}
            strokeWidth="10"
          />
          <circle
            className="circle-progress"
            cx="50"
            cy="50"
            r={radius}
            strokeWidth="10"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - progress}
          />
        </svg>
        <div className="score">{currentScore}%</div>
      </div>
    );
};

CircularProgress.propTypes = {
    score: PropTypes.number
}

export default CircularProgress;