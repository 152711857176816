import { RESUME_STATUS } from '../../utils/constants';
import { useReportContext } from '../../contextProviders/ReportContext';
import CircularProgress from '../../components/Report/CircularProgress';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import { capitalizeFirstLetterOfEachWord } from '../../utils/common';

export default function ReportVideoProfiling(){
    const {report} = useReportContext();
    const resumeVideoProfiling = report?.video_profiling[0] || {};

    if(!resumeVideoProfiling || Object.keys(resumeVideoProfiling).length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    (report?.status['resumeVideoProfiling'] === RESUME_STATUS.INVITE_SENT || report?.status['resumeVideoProfiling'] === RESUME_STATUS.COMPLETED) ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'} 
                </h1>
            </div>
        )
    }

    return (
        <div className='flex w-full flex-col pt-5 gap-10'>
            <div className='flex justify-center items-center gap-10'>
                {/* <Knob 
                    size={100}
                    value={resumeVideoProfiling?.score || 0} 
                    valueTemplate={'{value}%'} 
                    valueColor={resumeVideoProfiling?.score < 50 ? '#D30B53' : 
                        (resumeVideoProfiling?.score >= 50 && resumeVideoProfiling?.score < 80) ? '#B99000' : '#278500'
                    }
                /> */}
                <CircularProgress score={resumeVideoProfiling?.total_score}/>
                <div className='flex gap-2 flex-col'>
                    <label className='text-xl font-bold'>Summary</label>
                    <CustomReactMarkdown data={resumeVideoProfiling?.summary}/>
                </div>
            </div>
            <div className={`flex flex-col ${resumeVideoProfiling['strengths']?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Strengths</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {resumeVideoProfiling['strengths'].map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-base font-bold'>{capitalizeFirstLetterOfEachWord(item['strength'])}</h5>
                                    <p className='text-sm font-normal'>{item['description']}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${resumeVideoProfiling['areas_of_improvement']?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Areas of improvement</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                    {resumeVideoProfiling['areas_of_improvement'].map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-base font-bold'> {capitalizeFirstLetterOfEachWord(item['area'])}</h5>
                                    <p className='text-sm font-normal'>{item['description']}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${Object.keys(resumeVideoProfiling['responses'])?.length > 0 ? 'visible': 'hidden'}`}>
                <h2 className='text-xl font-bold'>Responses</h2>
                <ul className='flex gap-10 flex-wrap mt-3'>
                {Object.keys(resumeVideoProfiling['responses']).map((item, index) => {
                        return (
                            <li 
                                className='w-[45%]'
                                key={index?.toString()}>
                                <div className='flex justify-between gap-2 flex-col'>
                                    <h5 className='text-base font-bold'> {capitalizeFirstLetterOfEachWord(item)?.replaceAll('_', ' ')}</h5>
                                    <p className='text-sm font-normal'>{resumeVideoProfiling['responses'][item]['response']}</p>
                                    <div className='mt-2 flex flex-col gap-2'>
                                        <h4 className='text-sm font-bold'>Analysis</h4>
                                        <p className='text-xs font-semibold text-dg'>{resumeVideoProfiling['responses'][item]['analysis']['positive']}</p>
                                        <p className='text-xs font-semibold text-dr'>{resumeVideoProfiling['responses'][item]['analysis']['negative']}</p>
                                    </div>
                                </div>
                            </li>
                        )
                })}
                </ul>
            </div>
        </div>
    )
}