import { useEffect, useState } from 'react';
import {BounceLoader} from 'react-spinners';
import OverallReport from './OverallReport';
import DetailsReport from './DetailsReport';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { TabView, TabPanel } from 'primereact/tabview';
import { useLocation, useNavigate } from 'react-router-dom';
import ScreeningService from '../../services/screening.service';
import { useReportContext } from '../../contextProviders/ReportContext';
import ConsoleHelper from '../../utils/consoleHelper';

export default function ReportPage(){
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [loading, setLoading] = useState(false);
    const {setReport, report} = useReportContext();
    const resumeId = pathname?.split("reports/")[1];

    useEffect(() => {
        setLoading(true);
        async function init(){
            try {
                const response = await ScreeningService.getEvaluation(resumeId);
                setLoading(false);
                if(response.status === 200)
                    setReport(response.data)
            } catch (error) {
                setLoading(false);
                ConsoleHelper.log(JSON.stringify(error));
            }
        }
        init();
    }, [])

    const goBack = () => {
        navigate(-1)
    }

    if(loading){
        return (
            <div className="justify-center items-center flex">
                <BounceLoader size={40} color="#2B4360"/>
            </div>
        )
    }

    if(report == null && loading === false){
        return (
            <div className="flex flex-col p-5 pb-10 gap-10 rounded bg-white justify-center items-center">
                <p className='  text-primary'>Interview evaluation is in progress. The report will be generated once the evaluation is complete. This process can take up to 30 minutes. We appreciate your patience.</p>
            </div>
        )
    }

    
    return (
        <div className="h-screen p-5">
            <div className='flex flex-col gap-5 sticky top-0 z-40 bg-[#ECF5FA] pb-5'>
                <div className="flex flex-row items-center justify-between w-full">
                    <div 
                        onClick={goBack}
                        className="flex items-center gap-1 text-[#161616] cursor-pointer">
                        <MdKeyboardArrowLeft size={26}/>
                        <h1 className='text-xl text-primary font-bold'>Report</h1>
                    </div>
                </div>
                <p className=' text-brownGray text-xl'>Interview report of 
                    <span className='text-darkGray font-semibold px-2'>{report?.candidate_name}</span> for 
                    <span className='text-darkGray font-semibold px-2'>{report?.job_title}</span>  role
                </p>
            </div>
            <div className=''>
                <TabView
                    className='text-sm p-0'>
                    <TabPanel 
                        header="Overall"
                        contentClassName='mt-5'>
                        <OverallReport />
                    </TabPanel>
                    <TabPanel 
                        header="Details"
                        contentClassName='mt-5'>
                        <DetailsReport />
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}

