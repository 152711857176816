import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../services/user.service';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useToast } from '../../../contextProviders/ToastContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';

export default function HRAdminUserCreate(){
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const {showSuccessMessage, showErrorMessage} = useToast();

    const navigate = useNavigate();
    
    const goBack = () => navigate(-1);

    const navigateTo = (path) => {
        navigate(path)
    }

    const [user, setUser] = useState({
        email: null, 
        mobile: null,
        password: null,
        firstName: null, 
        lastName: null,
    })

    const validate = () => {
        setError({})
        const error = {};
        if(!user?.firstName)
            error['firstName'] = 'First Name required';
        if(!user?.lastName)
            error['lastName'] = 'Last Name required';


        if(!user?.email)
            error['email'] = 'Email required';
        else if (!user.email?.match(/^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov|info|io|co|us|uk)$/))
            error['email'] = 'Please enter valid email';

        if(!user?.mobile)
            error['mobile'] = 'Mobile number required';
        else if(!user?.mobile?.match(/^\+?(\d[\d\s-]{8,}\d)$/))
            error['mobile'] = 'Please enter a valid mobile number';

        if(!user?.password)
            error['password'] = 'Password required';
        else if (user?.password?.length < 8)
            error['password'] = 'Password must be at least 8 characters'
        else if (!user?.password.match(/\d/) || !user?.password.match(/[a-zA-Z]/))
            error['password'] = 'Password must contain at least 1 letter and 1 number'

        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const handleInput = (key, event) => {
        const value = event?.target?.value;
        let data = {...user};
        data[key] = value;
        setUser(data)
    }

    const submit = async (event) => {
        event.preventDefault(); 
        if(!validate()) return;

        try {
            setLoading(true);
            const response = await UserService.createUser(user);
            if(response.status === 201){
                setLoading(false);
                showSuccessMessage({
                    summary: 'Created User', 
                    detail: 'Successfully created user'
                })
                navigateTo('/dashboard/users-management')
            }
        } catch (error) {
            setLoading(false);
            const message = error?.response?.data?.message;
            showErrorMessage({
                summary: 'Failed', 
                detail: message || 'Failed to create user'
            })
        }
    }
  
    return (
        <div className="flex flex-col h-full gap-4 p-5">
            <div className={`${loading ? 'visible': 'hidden'} h-screen w-screen top-0 left-0 absolute z-10 bg-transparent justify-center flex items-center`}>
                <div className='w-40'>
                    <LoadingComponent />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div 
                    onClick={goBack}
                    className="flex items-center gap-1 text-primary cursor-pointer">
                    <MdKeyboardArrowLeft size={26} />
                    <h1 className='text-xl font-bold'>Create HR Login</h1>
                </div>
            </div>
            <form className='flex flex-col gap-8 mt-3' onSubmit={submit}>
                <div className='bg-white p-5 flex flex-col gap-8'>
                    <h2 className='font-bold text-darkGray text-xl'>Admin account detail</h2>
                    <div className='flex flex-col gap-4'>
                        {/* <div className='flex justify-between items-center'>
                            <h3 className='text-primary text-base font-bold'>User {index + 1}</h3>
                            <div
                                onClick={removeUser.bind(this, index)}
                                className={`rounded-full shadow-md h-8 w-8 bg-white flex justify-center items-center text-darkGray cursor-pointer`}>
                                <MdClear size={20} />
                            </div>
                        </div> */}
                        <div className='flex gap-5 flex-wrap'>
                            <div className="flex-col flex w-[30%] gap-2">
                                <label className="text-darkGray font-medium text-xs" htmlFor="firstName">First Name</label>
                                <InputText 
                                    id="firstName"
                                    value={user?.firstName}
                                    placeholder="Enter First Name" 
                                    onChange={handleInput.bind(this, 'firstName')}
                                    className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                                />
                                {(error['firstName'] || (!user?.firstName || user?.firstName?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['firstName']}</span>}
                            </div>
                            <div className="flex-col flex w-[30%] gap-2">
                                <label className="text-darkGray font-medium text-xs" htmlFor="lastName">Last Name</label>
                                <InputText 
                                    id="lastName"
                                    value={user?.lastName}
                                    placeholder="Enter Last Name" 
                                    onChange={handleInput.bind(this, 'lastName')}
                                    className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                                />
                                {(error['lastName'] || (!user?.lastName || user?.lastName?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['lastName']}</span>}
                            </div>
                            <div className="flex-col flex w-[30%] gap-2">
                                <label className="text-darkGray font-medium text-xs" htmlFor="email">Email</label>
                                <InputText 
                                    id="email"
                                    value={user?.email}
                                    placeholder="Enter Email"
                                    onChange={handleInput.bind(this, 'email')} 
                                    className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                                />
                                {(error['email'] || (!user?.email || user?.email?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['email']}</span>}
                            </div>
                            <div className="flex-col flex w-[30%] gap-2">
                                <label className="text-darkGray font-medium text-xs" htmlFor="mobile">Mobile Number</label>
                                <InputText 
                                    id="mobile"
                                    value={user?.mobile}
                                    placeholder="Enter Mobile Number" 
                                    onChange={handleInput.bind(this, 'mobile')} 
                                    className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                                />
                                {(error['mobile'] || (!user?.mobile || user?.mobile?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['mobile']}</span>}
                            </div>  
                            <div className="flex-col flex w-[30%] gap-2">
                                <label className="text-darkGray font-medium text-xs" htmlFor="password">Password</label>
                                <div className='flex gap-2 items-center'>
                                    <InputText 
                                        id="password"
                                        // type="password"
                                        value={user?.password}
                                        placeholder="Enter Password" 
                                        onChange={handleInput.bind(this, 'password')} 
                                        className="border-l_border border-[1px] h-10 p-2 text-sm font-normal w-full"
                                    />
                                    {/* <BiSolidHide /> */}
                                </div>
                                {(error['password'] || (!user?.password || user?.password?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['password']}</span>}
                            </div>          
                        </div>
                    </div>
                </div>
                <button className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-5'>
                    Create
                </button>
            </form>
        </div>
    )
}