import PropTypes from 'prop-types';
import { useState } from "react";
import { MdClear } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import LoadingComponent from "../Common/LoadingComponent";
import SuccessPopup from '../Common/SuccessPopup';
import ConfirmDialog from '../ConfirmDialog';

function AddResumeLessPopup(props){
    const {hide} = props;
    const [isSubmited, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const sendInvite = async () => {
        setLoading(true);
        setShowConfirmDialog(false);
        try {
            setTimeout(() => {
                setLoading(false);
                setIsSubmitted(true);
                setTimeout(() => {
                    setIsSubmitted(false);
                    hide();
                }, 3000);
            }, 3000)
        } catch (error) {
            setLoading(false);
            setIsSubmitted(false);
        }
    }

    const onCancel = () => {
        setShowConfirmDialog(false);
    }

    if(showConfirmDialog){
        return (
            <ConfirmDialog 
                accept={sendInvite}
                cancel={onCancel}
            />
        )
    }

    else if (isSubmited && !loading){
        return <SuccessPopup />
    }

    else if (showConfirmDialog){
        return (
            <ConfirmDialog 
                accept={sendInvite}
                loading={loading}
                cancel={onCancel}
            />
        )
    }

    return (
        <div className="h-screen w-screen bg-transparent top-0 left-0 absolute z-50 flex justify-center items-center">
            <div className={`${loading ? 'visible': 'hidden'}`}>
                <LoadingComponent />
            </div>
            <div className={`${!loading ? 'visible': 'hidden' } w-1/3 bg-white p-5 rounded-md text-darkGray`}>
                <div className="flex justify-between items-center">
                    <h1 className="font-semibold">Add resume less candidate</h1>
                    <MdClear 
                        size={18}
                        onClick={hide}
                        color="#999999"
                        className="cursor-pointer"
                    />
                </div>
                <div className="flex flex-col gap-4 pt-10 pb-5">
                    <div className="flex flex-col gap-2">
                        <label className="text-sm">Name</label>
                        <InputText 
                            className="2xl:h-12 h-10 border-l_border border-[1px] text-sm text-[#999999] px-2"
                            placeholder="Candidate Name"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm">Email</label>
                        <InputText 
                            className="2xl:h-12 h-10 border-l_border border-[1px] text-sm text-[#999999] px-2"
                            placeholder="Candidate Email"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-sm">Name</label>
                        <InputText 
                            className="2xl:h-12 h-10 border-l_border border-[1px] text-sm text-[#999999] px-2"
                            placeholder="Candidate Phone Number"
                        />
                    </div>
                    <button
                        onClick={() => setShowConfirmDialog(true)} 
                        className="bg-blue w-40 text-sm text-white rounded-md h-10 mt-10">
                        Initiate Processing
                    </button>
                </div>
            </div>
        </div>
    )
}

AddResumeLessPopup.propTypes = {
    hide: PropTypes.func
}

export default AddResumeLessPopup;