import React from 'react';
import uploadIcon from './../../assets/icons/upload-icon.svg';

const UploadAnimation = (props) => {

  return (
    <div className="upload-animation">
        <div className="upload-icon">
            <img 
                alt='upload'
                src={uploadIcon} 
                className='h-20'
            />
        </div>
    </div>
  );
};

export default UploadAnimation;