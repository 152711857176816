import { Route, Routes } from "react-router-dom";
import JDPage from "../pages/HR/Job/JDPage";
import JDCreatePage from "../pages/HR/Job/JDCreatePage";
import JDDetailsPage from "../pages/HR/Job/JDDetailsPage";
import JDEditPage from "../pages/HR/Job/JDEditPage";

export default function JDRoutes(){
    return (
        <Routes>
            <Route index path="" element={<JDPage />} />
            <Route path="create" element={<JDCreatePage />} />
            <Route path=":id/edit" element={<JDEditPage />} />
            <Route path=":id" element={<JDDetailsPage />} />
        </Routes>
    )
}