import JDRoutes from "./JDRoutes";
import HRHome from "../pages/HR/HRHome";
import ReportRoutes from "./ReportRoutes";
import HRProfile from "../pages/HR/HRProfile";
import HRSupport from "../pages/HR/HRSupport";
import HRDashboard from "../pages/HR/HRDashboard";
import JDProvider from "../contextProviders/JDInfoContext";
import { Navigate, Route, Routes } from "react-router-dom";
import { ReportProvider } from "../contextProviders/ReportContext";
import NotificationPage from "../pages/HR/Dashboard/NotificationPage";
import HRConfig from "../pages/HR/HRConfig";
import ExitInterviewRoutes from "./ExitInterviewRoutes";

export default function HRRoutes() {

    return (
      <ReportProvider>
        <JDProvider>
          <Routes>
            <Route 
              path="" 
              element={<HRHome />}>
                <Route 
                  index 
                  path="" 
                  element={<HRDashboard />} 
                />
                <Route 
                  path="job-description/*" 
                  element={<JDRoutes />} 
                />
                <Route 
                  path="notifications" 
                  element={<NotificationPage />} 
                />
                <Route 
                  path="reports/*" 
                  element={<ReportRoutes />} 
                />
                <Route 
                  path="exit-interview/*" 
                  element={<ExitInterviewRoutes />} 
                />
                <Route 
                  path="profile" 
                  element={<HRProfile />} 
                />
                <Route 
                  path="support" 
                  element={<HRSupport />} 
                />
                <Route 
                  path="config" 
                  element={<HRConfig />} 
                />
                <Route 
                  path="setting" 
                  element={<HRSupport />} 
                />
                <Route 
                  path="*" 
                  element={<Navigate to="/notfound"/>}
                />
            </Route>
          </Routes>
        </JDProvider>
      </ReportProvider>
    );
  }