import React from 'react';
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import pattern1 from './../../assets/images/interview-pattern1.svg';
import pattern2 from './../../assets/images/interview-pattern2.svg';
import exitInterview from './../../assets/images/exit-interview.svg';
import component1 from './../../assets/images/interview-component1.svg';
import component2 from './../../assets/images/interview-component2.svg';

function ProductInfoSection() {
  return (
    <section className="p-8 w-screen bg-gradient-to-r from-white to-[#F6F7F9]">
      <div className="md:max-w-[80%] mx-auto md:p-16 md:py-10 md:gap-32 flex flex-col justify-center items-center">
        <div className="flex flex-col-reverse md:flex-row md:gap-0 justify-between">
              <div className="flex-1 md:pr-8 flex flex-col justify-center items-end" >
                  <AnimatedDiv className="justify-center flex items-center flex-col gap-4">
                      <AnimatedText className="text-2xl font-bold mb-4 text-darkGray text-center">
                        Conduct efficient <span className='text-l_g'>HR and  technical/Ops interviews</span>
                      </AnimatedText>
                      <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                          Save time and resources with our AI-powered system, automating initial interviews and delivering comprehensive candidate reports.
                      </p>
                  </AnimatedDiv>
              </div>
              <AnimatedDiv className="flex-1 flex items-center justify-end p-10 md:p-0 relative">
                <img  
                  alt="pattern"
                  src={pattern1} 
                  className="object-contain absolute w-48" 
                />
                <img  
                  alt="pattern"
                  src={pattern2} 
                  className="absolute top-5 -right-10 animate-float" 
                />
                <img  
                  alt="component"
                  src={component1} 
                  className="absolute top-10 left-10 animate-float w-48" 
                />
                <img  
                  alt="component"
                  src={component2} 
                  className="absolute -bottom-[60%] right-0 animate-float w-48" 
                />
              </AnimatedDiv>
        </div>
        <div className="flex flex-col md:flex-row gap-0 justify-between">
              <AnimatedDiv className="flex-1 flex items-center p-10 md:p-0">
                  <img
                    alt="pattern" 
                    src={exitInterview}  
                    className="object-contain" 
                  />
              </AnimatedDiv>
              <div className="flex-1 md:pr-8 flex flex-col justify-center items-end">
                  <AnimatedDiv className="flex flex-col justify-center items-center w-full gap-4">
                      <AnimatedText className="text-2xl font-bold mb-4 text-darkGray text-center"><span className='text-l_g'>Get insight</span> from employee departures</AnimatedText>
                      <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                        Use our exit interview tool to uncover trends, address issues, and improve your workplace. Leverage actionable feedback to enhance retention and build a stronger work culture
                      </p>
                  </AnimatedDiv>
              </div>
        </div>
      </div>
    </section>
  );
}

export default ProductInfoSection;