import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { CgTranscript } from "react-icons/cg";
import { Rating } from '@smastrom/react-rating';
import { useReportContext } from '../../contextProviders/ReportContext';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import { convertUTCToLocal, extractOverallAssessment, getRating, getRatingForBpoFlow, secondsToMinutes } from '../../utils/common';
import { RESUME_STATUS } from '../../utils/constants';
import { useAuth } from '../../contextProviders/AuthContext';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';

export default function ReportOpsScreening(){
    const {user} = useAuth();
    const {report} = useReportContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const opsScreening = report?.ops_screening?.[0] || {};
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);

    const audioAnalysis = opsScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_00')?.[0];

    const videoRef = useRef(null);

    const showAudioAnalysisReportPopup = () => {
        setShowAudioAnalyisReport(true);
    }

    const hideAudioAnalysisReportPopup = () => {
        setShowAudioAnalyisReport(false);
    }

    const getValue = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    if(Object.keys(opsScreening).length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    (report?.status['opsScreening'] === RESUME_STATUS.INVITE_SENT || report?.status['opsScreening'] === RESUME_STATUS.COMPLETED) ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'
                    } 
                </h1>            
            </div>
        )
    }

    const getScoreTheme = (score) => {
        if(score >= 71) return 'text-dg';
        else if (score >= 49 && score < 71 ) return 'text-do';
        return 'text-dr';
    }

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () =>  setIsPlaying(false);

    return (
        <div className='flex flex-col gap-10'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    <ReactPlayer
                        ref={videoRef}
                        controls
                        pip={false}
                        playing={isPlaying}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        className="react-player flex-1"
                        url={opsScreening?.video_path}
                    />
                    <div className='flex flex-col gap-4 items-start flex-1'>
                        <div className='flex flex-col gap-4 py-5 items-center w-full'>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRatingForBpoFlow(report?.total_score, user?.['organization']).value} 
                            />
                            <h2 className={`text-sm font-semibold ${getScoreTheme(report?.total_score)}`}>
                                {getRatingForBpoFlow(report?.total_score, user?.['organization']).title}
                            </h2>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${opsScreening?.start_time ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Date of the interview :</h3>
                            <span className=''>{opsScreening?.start_time ? convertUTCToLocal(opsScreening?.start_time) : 'Not captured' }</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Duration:</h3>
                            <span className=''>{opsScreening?.duration ? secondsToMinutes(opsScreening?.duration) : 'NA'}</span>
                        </div>
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Tab switches count:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.tab_switches_count}</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Full Screen Exits Count:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.full_screen_exits_count}</span>
                        </div> */}
                        {/* <div className='flex gap-2 text-primary'>
                            <h3 className='font-semibold'>Anomalies detected:</h3>
                            <ReactMarkdown>{opsScreening?.anomalies}</ReactMarkdown>
                        </div> */}
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Facial Expression:</h3>
                            <span className=''>{opsScreening?.anomalies_data?.facial_expression}</span>
                        </div> */}
                        <div className='flex items-center gap-3'>
                            <p className='font-bold text-base'>Transcript of the interview</p>
                            <CgTranscript 
                                size={32}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div>
                    </div>
                </div>
                {showTranscript && <TranscriptComponent id={opsScreening?.id} hide={() => setShowTranscript(false)}/>}
                {opsScreening?.summary && (
                    <div>
                        <h4 className='text-xl font-bold'>Summary</h4>
                        <p className='text-primary my-5'>{opsScreening?.summary}</p>
                    </div>
                )}
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className={`flex mt-2 flex-col gap-3 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                        {/* <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRatingForBpoFlow(audioAnalysis?.score, user?.['organization']).value} 
                        />
                        <h2 className={`text-sm font-semibold text-primary`}>
                            {getRatingForBpoFlow(report?.total_score, user?.['organization']).title}
                        </h2> */}
                        {extractOverallAssessment(audioAnalysis?.['summary']) && 
                            <CustomReactMarkdown data={extractOverallAssessment(audioAnalysis?.['summary'])}/>
                        }
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}