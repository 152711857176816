import PropTypes from 'prop-types';
import { Dialog } from "primereact/dialog";
import UploadAnimation from "./UploadAnimation";
import ConsoleHelper from '../../utils/consoleHelper';

function RecordingUploadComponent(props){
    ConsoleHelper.log('RecordingUploadComponent')
    const {visible} = props;

    if(visible){
        return (
            <Dialog 
                visible={true}
                closable={false}
                draggable={false}
                className="w-1/3 text-primary"
                header='Uploading Interview Data'>
                <div className='flex flex-col justify-center items-center mt-10'>
                    <UploadAnimation />
                    <p className="text-sm text-center font-medium">We're currently uploading your interview recordings. This process ensures that all your responses are safely stored. Please don't close this window or navigate away from this page until the upload is complete</p>
                </div>
            </Dialog>
        )    
    }

    return <div className="hidden"/>
}

RecordingUploadComponent.propTypes = {
    visible: PropTypes.bool
}

export default RecordingUploadComponent;