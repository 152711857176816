import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSessionContext } from '../../contextProviders/SessionContext';

const ProtectedRoute = ({ element }) => {
    const {state} = useSessionContext();

    if (state === "ALREADY_COMPLETED" || state === "EXPIRED")
        return <Navigate to="/interview" replace />;

    return element;
};

export default ProtectedRoute;
