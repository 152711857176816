import React, { useEffect } from 'react';
import { useSessionContext } from '../../contextProviders/SessionContext';
import { useAnomalies } from '../../contextProviders/AnomaliesContext';
import ConsoleHelper from '../../utils/consoleHelper';

function TabSwitchDetector() {
  ConsoleHelper.log('TabSwitchDetector')
  const {state} = useSessionContext();
  const { anomaliesData, updateAnomalies } = useAnomalies();

  useEffect(() => {
    const handleVisibilityChange = () => {
      ConsoleHelper.log('handleVisibilityChange', document.hidden, state);
      if (document.hidden && state === 'AUTHORISED') {
        updateAnomalies(prevAnomaliesData => ({
          ...prevAnomaliesData,
          tab_switches_count: prevAnomaliesData.tab_switches_count + 1
        }));
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [state, updateAnomalies]);

  return null;
}

export default React.memo(TabSwitchDetector);