import { useNavigate } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import AnimatedText from '../Common/AnimatedText';
import AnimatedButton from "../Common/AnimatedButton";
import { AnimatedImage } from "../Common/AnimatedImage";
import heroPattern from './../../assets/images/hero-pattern.svg';
import dashboardImage from './../../assets/images/dashboard.svg';
import pattern1 from './../../assets/images/dashboard2-pattern1.svg';
import pattern2 from './../../assets/images/dashboard2-pattern2.svg';
import { trackEvent } from "../../utils/googleAnalytics";

export default function HeroSection() {

    const navigate = useNavigate();
    const navigateTo = (path) => navigate(path);
    
    const onBookADemo = () => {
        trackEvent({
            value: 1,
            action: "Click",
            transport: "beacon",
            nonInteraction: false,
            label: "Book a Demo Button",
            category: "Lead Generation",
        })
        navigateTo('contactus')
    }

    return (
        <section className="bg-white">
            <img 
                alt='hero-pattern'
                src={heroPattern}
                className='absolute top-0 left-0'
            />
            <div className='w-full md:max-w-[75%] mx-auto py-10 md:py-16 md:pt-20 flex flex-col md:flex-row justify-center md:px-0 px-5 gap-5'>
                <div className="flex-1 flex flex-col gap-5 justify-center items-center md:items-start">
                    <AnimatedText className="font-semibold text-3xl md:text-4xl flex-col gap-3 text-darkGray md:leading-snug tracking-wide text-center md:text-left">
                        Recruit <span className="text-l_g">best-fit </span> candidates with Athmick's <span className="text-l_g">All-in-one </span> <br/>AI-powered talent acquisition solution.
                    </AnimatedText>
                    <p className="text-xl mb-8 max-w-2xl text-darkGray font-normal text-center md:text-left">
                        Experience unmatched speed and performance with our cutting-edge recruitment platform
                    </p>
                    <AnimatedButton
                        onClick={onBookADemo} 
                        className="bg-black text-white rounded-full md:w-48 hover:bg-blue hover:text-white">
                        <div className='flex gap-2 justify-between items-center md:py-1 text-base'>
                            <span className='font-medium'>Book A Demo</span>
                            <GoArrowUpRight color='white' size={18} />
                        </div>
                    </AnimatedButton>
                </div>
                <div className='flex-1 flex justify-end p-5 md:p-0 relative'>
                    <AnimatedImage
                        src={pattern1}
                        className="absolute w-20 md:w-36 animate-float"
                    />
                    <AnimatedImage
                        src={pattern2}
                        className="absolute md:-left-20 -left-10 -bottom-10 md:-bottom-20 w-16  md:w-24 animate-float"
                    />
                    <AnimatedImage
                        alt='dashboard' 
                        src={dashboardImage}
                    />
                </div>
            </div>
        </section>
    );
}