import React, { createContext, useState, useContext } from 'react';

// Create the context
export const InterviewMediaContext = createContext();

// Define the provider component
export const InterviewMediaProvider = ({ children }) => {
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [displayStream, setDisplayStream] = useState(null);

  const [hasPermission, setHasPermission] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [selectedMicrophone, setSelectedMicrophone] = useState(null);

  return (
    <InterviewMediaContext.Provider value={{ 
        selectedCamera, 
        selectedMicrophone, 
        selectedSpeaker, 
        setSelectedCamera, 
        setSelectedMicrophone, 
        setSelectedSpeaker, 
        hasPermission,
        setHasPermission,
        audioStream,
        videoStream,
        setAudioStream,
        setVideoStream,
        displayStream,
        setDisplayStream
    }}>
      {children}
    </InterviewMediaContext.Provider>
  );
};



export const useMediaContext = () => {
  return useContext(InterviewMediaContext);
};