import React from 'react';
import ReactPlayer from 'react-player/lazy'
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import downArrow from '../../assets/icons/down-arrow.svg';
import ellipse from './../../assets/images/green-ellipse.svg';
import { AnimatedImage } from '../Common/AnimatedImage';

const InterviewSection = () => {
  return (
    <div className="md:min-h-screen p-5 md:p-10 justify-between flex flex-col text-white bg-gradient-to-b from-[#2B4360] to-[#3C99CA]">
      <div className="max-w-4xl mx-auto flex flex-col gap-10 py-10">
        <AnimatedText className="text-3xl md:text-4xl font-bold text-center md:w-2/3 m-auto">
          Make confident, precise hiring decisions with ease
        </AnimatedText>
        
        <AnimatedDiv className="text-center font-light leading-7 md:text-xl text-white">
          <p>'Athmiya', the AI interviewer, delivers thorough screenings with <span className='text-l_g font-medium'><br/> dynamic, skill-focused, follow-on questions</span> tailored to your needs.</p>

        </AnimatedDiv>
      </div>
      <AnimatedDiv className='w-full flex justify-center items-center'>
        <div className='max-w-6xl w-full relative'>
          <AnimatedImage
            src={downArrow}
            alt='arrow'
            className='md:h-28 md:w-28 h-10 w-10 absolute right-4 -top-5 z-20 animate-float' 
          />
          <img
            alt='ellipse'
            src={ellipse}
            className='absolute translate-y-[50%] -left-20 z-0'
          />
          <img
            alt='ellipse-2'
            src={ellipse}
            className='absolute -right-10 -translate-y-[50%] z-0'
          />
          <div className="rounded-lg overflow-hidden max-w-4xl mx-auto relative z-10">
            <ReactPlayer 
              className="w-full"
              width='100%'
              height='100%'
              controls={true}
              url='https://skillassessment.blob.core.windows.net/files/website-video.mp4' 
            />
          </div>
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default InterviewSection;