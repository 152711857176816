import AdminConfig from "../pages/Admin/AdminConfig";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminHome from "../pages/Admin/Dashboard/AdminHome";
import AdminDashboard from "../pages/Admin/Dashboard/AdminDashboard";
import BlogManagement from "../pages/Admin/BlogManagement/BlogManagement";
import PromptManagement from "../pages/Admin/PromptManagement/PromptManagement";
import BlogManagementHome from "../pages/Admin/BlogManagement/BlogManagementHome";
import BlogManagementCreate from "../pages/Admin/BlogManagement/BlogManagementCreate";
import BlogManagementUpdate from "../pages/Admin/BlogManagement/BlogManagementUpdate";
import PromptManagementHome from "../pages/Admin/PromptManagement/PromptManagementHome";
import PromptManagementCreate from "../pages/Admin/PromptManagement/PromptManagementCreate";
import PromptManagementUpdate from "../pages/Admin/PromptManagement/PromptManagementUpdate";
import OrganisationManagement from "../pages/Admin/OrganisationManagement/OrganisationManagement";
import OrganisationManagementHome from "../pages/Admin/OrganisationManagement/OrganisationManagementHome";
import OrganisationManagementUpdate from "../pages/Admin/OrganisationManagement/OrganisationManagementUpdate";
import OrganisationManagementCreate from "../pages/Admin/OrganisationManagement/OrganisationManagementCreate";

export default function AdminRoutes() {

    return (
        <Routes>
            <Route 
              path="" 
              element={<AdminHome />}>
                <Route 
                  index 
                  path="" 
                  element={<AdminDashboard />} 
                />

                <Route 
                  path="/blogs-management" 
                  element={<BlogManagementHome />} >

                  <Route 
                    index
                    path="" 
                    element={<BlogManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<BlogManagementCreate />} 
                  />
                  <Route 
                    path=":id/update" 
                    element={<BlogManagementUpdate />} 
                  />
                </Route>
                <Route 
                  path="/prompts-management" 
                  element={<PromptManagementHome />} >

                <Route 
                    index
                    path="" 
                    element={<PromptManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<PromptManagementCreate />} 
                  />
                  <Route 
                    path=":id/update" 
                    element={<PromptManagementUpdate />} 
                  />
                </Route>
                <Route 
                  path="/organisations-management" 
                  element={<OrganisationManagementHome />} >

                  <Route 
                    index
                    path="" 
                    element={<OrganisationManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<OrganisationManagementCreate />} 
                  />
                  <Route 
                    path=":id/update" 
                    element={<OrganisationManagementUpdate />} 
                  />
                </Route>
                <Route 
                  path="/config" 
                  element={<AdminConfig />}
                />
                <Route 
                  path="*" 
                  element={<Navigate to="/notfound"/>}
                />
            </Route>
        </Routes>
    );
  }