import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {BounceLoader} from 'react-spinners';
import AuthService from '../../services/auth.service';
import { useToast } from '../../contextProviders/ToastContext';

const passwordValidation = (value) => {
  if (value.length === 0) return "This field is required.";
  if (value.length < 8) return "Password must be at least 8 characters.";
  if (!value.match(/\d/) || !value.match(/[a-zA-Z]/))
    return "Password must contain at least 1 letter and 1 number.";
  return "";
};

const PasswordInput = ({ id, value, onChange, label, error, touched }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="space-y-2">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="relative">
        <InputText
          id={id}
          value={value}
          onChange={onChange}
          type={showPassword ? "text" : "password"}
          className="w-full pr-10 border px-2 h-10 border-l_border rounded-md"
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? (
            <FaEye size={18} className="text-brownGray" />
          ) : (
            <FaEyeSlash size={18} className="text-brownGray" />
          )}
        </button>
      </div>
      {touched && error && <small className="text-dr block">{error}</small>}
    </div>
  );
};

const ChangePasswordDialog = ({ onHide }) => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [touched, setTouched] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const {showErrorMessage, showSuccessMessage} = useToast();

  useEffect(() => {
    const currentPasswordError = passwordValidation(currentPassword);
    const newPasswordError = passwordValidation(newPassword);
    const confirmPasswordError = newPassword !== confirmPassword ? "Passwords do not match." : "";

    setErrors({
      currentPassword: currentPasswordError,
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError
    });

    setIsFormValid(
      !currentPasswordError &&
      !newPasswordError &&
      !confirmPasswordError &&
      newPassword === confirmPassword
    );
  }, [currentPassword, newPassword, confirmPassword]);
  
  const handleInputChange = (field, value) => {
    const setters = {
      currentPassword: setCurrentPassword,
      newPassword: setNewPassword,
      confirmPassword: setConfirmPassword
    };
    setters[field](value);
    setTouched(prev => ({ ...prev, [field]: true }));
  };

  const handleSubmit =  async (event) => {
    event.preventDefault();

    setIsSubmitAttempted(true);
    if (isFormValid) {
      try {
        setLoading(true);
        const requestData = {
          currentPassword,
          newPassword,
          confirmPassword
        }
        const response = await AuthService.changePassword(requestData);
        setLoading(false)
        if(response.status === 200){
            showSuccessMessage({
              life: 3000,
              summary: 'Password successfully changed', 
              detail: 'Your password has been updated.'
            });
            onHide();            
        }
    } catch (error) {
        setLoading(false)
        showErrorMessage({ 
          life: 3000,
          summary: 'Failed', 
          detail: error?.response?.data?.message  || 'Invalid email or password'
        })
      }
    }
  };

  return (
    <Dialog
      visible={true}
      onHide={onHide}
      draggable={false}
      header="Change password"
      className="w-full max-w-md mx-auto">
      <form 
          onSubmit={handleSubmit} 
          className="flex flex-col space-y-4">
        <PasswordInput
          id="currentPassword"
          value={currentPassword}
          onChange={(e) => handleInputChange('currentPassword', e.target.value)}
          label="Current password"
          error={errors.currentPassword}
          touched={touched.currentPassword || isSubmitAttempted}
        />

        <PasswordInput
          id="newPassword"
          value={newPassword}
          onChange={(e) => handleInputChange('newPassword', e.target.value)}
          label="New password"
          error={errors.newPassword}
          touched={touched.newPassword || isSubmitAttempted}
        />

        <PasswordInput
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
          label="Re-enter password"
          error={errors.confirmPassword}
          touched={touched.confirmPassword || isSubmitAttempted}
        />       

        <Button
          disabled={!isFormValid}
          className="w-full md:w-auto bg-blue text-white h-10 font-normal flex justify-center items-center" 
          >
             {loading && <BounceLoader size={26} color="#ffffff" /> }
             <span className={`font-semibold text-sm ${!loading ? 'visible': 'hidden'}`}>Submit</span>
          </Button>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialog;