import React, { createContext, useContext, useState } from 'react';

const InterviewAuthContext = createContext();

export const InterviewAuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    return (
        <InterviewAuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </InterviewAuthContext.Provider>
    );
};

export const useInterviewAuth = () => useContext(InterviewAuthContext);
