import React from 'react';
import feature1 from './../../assets/images/feature-1.svg';
import feature2 from './../../assets/images/feature-2.svg';
import pattern4 from './../../assets/images/pattern-4.svg';
import pattern5 from './../../assets/images/pattern-5.svg';
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';

const HiringFeaturesSection = () => {
  return (
    <div className="px-8 w-screen">
        <div className="md:max-w-[80%] mx-auto md:p-16 flex flex-col justify-center items-center gap-10">
            <AnimatedText className="text-3xl md:text-4xl font-bold text-darkGray text-center mb-5">
                <span className='text-l_g'>Evolutionize</span> the hiring
            </AnimatedText>
            
            <div className="flex flex-col-reverse md:flex-row md:gap-0 justify-between">
                <div 
                    className="flex-1 md:w-1/2 md:pr-8 flex flex-col justify-center items-end" 
                    style={{backgroundRepeat: 'no-repeat', backgroundImage: `url(${pattern4})`}}>
                    <AnimatedDiv className="md:p-6 justify-center flex items-center flex-col">
                        <h2 className="text-2xl font-bold mb-4 text-darkGray text-center">In-depth talent assessment</h2>
                        <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                            Athmiya, the AI interviewer, conducts comprehensive evaluations of all required and soft skills. Plus, provides actionable next steps, including tailored development plans for continuous growth.
                        </p>
                    </AnimatedDiv>
                </div>
                <AnimatedDiv className="md:w-2/3 flex items-center justify-center p-10 md:p-0">
                    <img src={feature1} alt="feature1" className="object-contain" />
                </AnimatedDiv>
            </div>
            <div className="flex flex-col md:flex-row gap-0 justify-between">
                <AnimatedDiv className="md:w-2/3 flex items-center p-10 md:p-0">
                    <img src={feature2} alt="feature1" className="object-contain" />
                </AnimatedDiv>
                <div 
                    className="flex-1 md:w-1/2 md:pr-8 flex flex-col justify-center items-end" 
                    style={{backgroundRepeat: 'no-repeat', backgroundImage: `url(${pattern5})`}}>
                    <AnimatedDiv className="p-6 flex flex-col justify-center items-center w-full">
                        <h2 className="text-2xl font-bold mb-4 text-darkGray text-center">Detailed candidate scoring</h2>
                        <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                            Gain access to clear, objective scores for every interview, enabling precise decision-making. Leverage actionable insights for confident and informed candidate selection.
                        </p>
                    </AnimatedDiv>
                </div>
            </div>
        </div>
    </div>
  );
};

export default HiringFeaturesSection;