const log = (value) => {
    // if (process.env.NODE_ENV === 'production') return;
    // console.log(value);
}

const error = (value) => {
    // if (process.env.NODE_ENV === 'production') return;
    // console.error(value);
}
  
const ConsoleHelper = {
    log,
    error
}

export default ConsoleHelper;
  