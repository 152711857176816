import React, { createContext, useContext, useState, useCallback } from 'react';

// Create the context
const QAContext = createContext();

// Create the provider component
const JDProvider = ({ children }) => {
  const [jdInfo, setJDInfo] = useState(() => {
    const storedData = localStorage.getItem('jdInfo');
    return storedData ? JSON.parse(storedData) : {};
  });

  const changeJDInfo = useCallback((values) => {
    setJDInfo(prevInfo => {
      const updatedInfo = { ...prevInfo, ...values };
      localStorage.setItem('jdInfo', JSON.stringify(updatedInfo));
      return updatedInfo;
    });
  }, []);

  const getJDInfo = useCallback(() => jdInfo, [jdInfo]);

  const clearJD = useCallback(() => {
    setJDInfo({});
    localStorage.removeItem('jdInfo');
  }, []);

  return (
    <QAContext.Provider value={{ getJDInfo, changeJDInfo, clearJD }}>
      {children}
    </QAContext.Provider>
  );
};

export const useJDInfo = () => {
  return useContext(QAContext);
};

export default JDProvider;