import React from 'react';
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import jobDescription from './../../assets/images/job-description.svg';
import resumeShortlisting from './../../assets/images/resume-shortlisting.svg';
import pattern4 from './../../assets/images/pattern-4.svg';
import pattern5 from './../../assets/images/pattern-5.svg';

function Features() {
  return (
    <section className="p-8 pb-0 w-screen">
      <div className="md:max-w-[80%] mx-auto md:p-16 md:pb-0 flex flex-col justify-center items-center">
        <AnimatedText className="text-3xl md:text-4xl font-bold text-center md:w-1/2 m-auto">
          How <span className="text-l_g">AI Can Transform</span> Your Hiring?
        </AnimatedText>
        <AnimatedDiv>
          <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center md:w-2/3 m-auto px-10 pt-5">
            Revolutionize your recruitment with our AI-powered talent evaluation platform. Streamline every stage, elevate candidate quality, and dramatically reduce time-to-hire.
          </p>
        </AnimatedDiv>
        <div className="flex flex-col-reverse md:flex-row md:gap-10 mt-20 justify-between">
          <div className="flex-1 md:pr-8 flex flex-col justify-center items-end" 
            style={{backgroundRepeat: 'no-repeat', backgroundImage: `url(${pattern4})`}}>
              <AnimatedDiv className="justify-center flex items-center flex-col">
                  <h2 className="text-2xl font-bold mb-4 text-darkGray text-center"><span className='text-l_g'>Effortlessly craft</span> precise Job Descriptions</h2>
                  <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                    Utilize our AI-driven tool to create accurate, detailed job descriptions tailored to your needs. Save time and enhance clarity by articulating ideal candidate.
                  </p>
              </AnimatedDiv>
          </div>
          <AnimatedDiv className="flex-1 flex items-center justify-center p-10 md:p-0">
              <img src={jobDescription} alt="feature1" className="object-contain" />
          </AnimatedDiv>
        </div>
        <div className="flex flex-col md:flex-row md:gap-10 my-20 justify-between">
            <AnimatedDiv className="flex-1 flex items-center p-10 md:p-0">
                <img src={resumeShortlisting} alt="feature1" className="object-contain" />
            </AnimatedDiv>
            <div 
              className="flex-1 md:pr-8 flex flex-col justify-center items-end" 
              style={{backgroundRepeat: 'no-repeat', backgroundImage: `url(${pattern5})`}}>
              <AnimatedDiv className="flex flex-col justify-center items-center w-full">
                  <h2 className="text-2xl font-bold mb-4 text-darkGray text-center">Resume Shortlisting - Streamline  <span className='text-l_g'>Candidate Selection</span></h2>
                  <p className="text-brownGray font-normal text-base leading-6 tracking-wider text-center">
                  Quickly identify top candidates with our AI-powered tool, filtering and ranking resumes based on your criteria. Focus on the best matches for the role
                </p>
              </AnimatedDiv>
            </div>
          </div>
      </div>
    </section>
  );
}

export default Features;