import AnimatedText from "../Common/AnimatedText";
import puzzle from './../../assets/images/puzzle.svg';
import maleImage from './../../assets/images/male.jpg';
import femaleImage from './../../assets/images/female.jpg';

export default function TeamSection(){
    const team = [
        {id: 1, name: 'Roopa Raj', role: 'Founder & CEO', image: femaleImage},
        {id: 2, name: 'Abhishek kumar', role: 'Lead - Product Development', image: maleImage},
        {id: 3, name: 'Nikita Chitkara', role: 'Lead - Marketing', image: femaleImage},
        {id: 4, name: 'Ganesh harish', role: 'Lead - Business Development', image: maleImage},
    ]
    return (
        <section className="bg-[#F6F7F9]">
            <div className='w-full md:max-w-[75%] mx-auto py-10 md:py-16 flex items-center flex-col justify-center'>
                <AnimatedText className="text-2xl md:text-3xl font-bold flex-col gap-3 text-darkGray tracking-wide leading-relaxed text-center md:text-left">
                    Meet the <span className="text-l_g">team</span>                   
                </AnimatedText>
                <div className="flex relative py-10">
                    <img
                        alt="puzzle"
                        className="h-20 absolute right-10 md:-right-10 top-3 animate-float"
                        src={puzzle}
                    />
                    <img
                        alt="puzzle"
                        className="h-20 absolute bottom-10 -left-10 md:-left-32 -translate-x-[50%] animate-float"
                        src={puzzle}
                    />
                   <ul className="flex flex-wrap justify-center gap-4 sm:gap-6 md:gap-8 lg:gap-10">
                        {team?.map((item) => (
                            <li
                            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex flex-col items-center mb-6"
                            key={item.id}
                            >
                            <img
                                alt={`${item.name} - ${item.role}`}
                                className="w-full max-w-[200px] h-auto rounded-lg shadow-md"
                                src={item.image}
                            />
                            <div className="flex flex-col items-center p-2 text-center mt-3">
                                <h3 className="text-blue font-bold">{item.name}</h3>
                                <span className="font-bold text-xs mt-1 text-darkGray">{item.role}</span>
                            </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}