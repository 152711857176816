import { Route, Routes } from "react-router-dom";
import ExitInterview from "../pages/HR/ExitInterview/ExitInterview";
import ExitInterviewCreate from "../pages/HR/ExitInterview/ExitInterviewCreate";

export default function ExitInterviewRoutes(){
    return (
        <Routes>
            <Route index path="" element={<ExitInterview />} />
            <Route path="create" element={<ExitInterviewCreate />} />
        </Routes>
    )
}