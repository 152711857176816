import notfoundImage from './../assets/images/not-found.svg';

export default function NotFound(){
    return (
        <div className="bg-[#ECF5FA] h-screen w-screen justify-center items-center flex">
            <img
                className='h-80'
                alt='Page not found'
                src={notfoundImage}
            />
        </div>
    )
}