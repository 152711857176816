import { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ReportInitialScreening from "./ReportInitalScreening";
import ReportResumeScreening from "./ReportResumeScreening";
import ReportTechnicalScreening from "./ReportTechnicalScreening";
import ReportOpsScreening from './ReportOpsScreening';
import ReportVideoProfiling from './ReportVideoProfiling';
import { useConfig } from '../../contextProviders/ConfigContext';
import ReportBpoHrScreening from './ReportBpoHrScreening';

export default function DetailsReport(){
    const config = useConfig();
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const tabsConfig = [
        {
            key: "videoProfiling",
            header: "Video Profiling",
            component: <ReportVideoProfiling />,
            condition: config?.enabledServices?.['videoProfiling'],
        },
        {
            key: "resumeScreening",
            header: "Resume Screening",
            component: <ReportResumeScreening />,
            condition: config?.enabledServices?.['resumeScreening'],
        },
        {
            key: "initialScreening",
            header: "Initial Screening",
            component: <ReportInitialScreening />,
            condition: config?.enabledServices?.['hrScreening']
        },
        {
            key: "initialScreening",
            header: "Bpo HR Screening",
            component: <ReportBpoHrScreening />,
            condition: config?.enabledServices?.['bpoHrScreening'],
        },
        {
            key: "technicalScreening",
            header: "Technical Screening",
            component: <ReportTechnicalScreening />,
            condition: config?.enabledServices?.['technicalScreening'],
        },
        {
            key: "opsScreening",
            header: "Ops Screening",
            component: <ReportOpsScreening />,
            condition: config?.enabledServices?.['opsScreening'],
        }
    ];

    const filteredTabs = tabsConfig.filter(tab => tab.condition);

    return (
        <div className="px-5 bg-white rounded-md">
            <TabView
                tabIndex={1}
                className='text-sm'
                activeIndex={currentTabIndex}
                pt={{ inkbar: { className: 'bg-white' }}}
                onBeforeTabChange={(event) => setCurrentTabIndex(event.index)}
            >
                {filteredTabs.map((tab, index) => (
                    <TabPanel
                        key={tab.key}
                        header={tab.header}
                        contentClassName='p-5'
                        pt={{
                            headerTitle: {
                                className: `${currentTabIndex === index ? 'bg-blue text-white' : 'bg-[#F6F7F9] text-brownGray'} py-2 px-4 rounded-md text-sm font-normal`,
                            }
                        }}
                    >
                        {tab.component}
                    </TabPanel>
                ))}
            </TabView>
        </div>
    )
}