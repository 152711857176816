function connectToInitialInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/initial?token=${token}`);
}

function connectToTechnicalInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/technical?token=${token}`);
}

function connectToBPOInitialInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/bpo/initial?token=${token}`);
}

function connectToBPOpsTextInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/bpo/ops/text?token=${token}`);
}

function connectToBPOpsVoiceInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/bpo/ops/voice?token=${token}`);
}

function connectToVideoProfilingInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/video-profiling?token=${token}`);
}

function connectToExitInterviewBot(token){
    return new WebSocket(`wss://${process.env.REACT_APP_ML_API_URL?.replace('https://', '')}/ws/screening/exit-interview?token=${token}`);
}

const InterviewService = {
    connectToExitInterviewBot,
    connectToInitialInterviewBot,
    connectToTechnicalInterviewBot,
    connectToBPOInitialInterviewBot,
    connectToBPOpsTextInterviewBot,
    connectToBPOpsVoiceInterviewBot,
    connectToVideoProfilingInterviewBot
}

export default InterviewService;