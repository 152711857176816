import React, { 
  useState, 
  useRef, 
  useEffect 
} from 'react';
import { FaStar } from 'react-icons/fa';
import user1 from './../../assets/images/user1.svg'
import user2 from './../../assets/images/user2.svg'
import user3 from './../../assets/images/user3.svg'
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';

const TestimonialSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const scrollRef = useRef(null);
  const intervalRef = useRef(null);

  const testimonials = [
    {
      name: 'HR Lead',
      role: 'Large BPO',
      image: user2,
      content: `Athmiya automates resume screening and eliminates scheduling challenges all together – a game-changer for HR`,
      rating: 4,
    },
    {
      name: 'Sourcing Marketplace',
      role: 'Founder & CEO',
      image: user3,
      content: 'There are lot of tools in the market where interview questions need to be fed and AI will ask those questions to candidates. Witnessing Athmiya doing human like interaction and asking dynamic and follow-on questioning is truly innovative!',
      rating: 5,
    },
    {
      name: 'Talent Acquisition Lead',
      role: 'Large Technology MNC',
      image: user2,
      content: 'HR spends enormous time in doing the resume screening and having initial set of conversations with candidates. Athmiya completely automating this and eliminating all of scheduling challenges is just amazing!',
      rating: 5,
    },
    {
      name: 'Hiring manager',
      role: 'Large BPO',
      image: user3,
      content: `Athmiya is so human-like, I'm half expecting her to ask for a raise`,
      rating: 5,
    },
    {
      name: 'CHRO',
      role: 'Large MNC',
      image: user2,
      content: `We were setting up new center with aggressive numbers. Following traditional evaluations would have taken us enormous efforts and time. Athmick helped us fulfill our targets with record high efficiency.`,
      rating: 4,
    },
    {
      name: 'TA Lead',
      role: 'Financial services',
      image: user3,
      content: `We were expanding Blockchain COE and we didnt have any local hiring managers to conduct interviews. Athmick helped us with thorough interviews in the niche skills and hire the best talent for us.`,
      rating: 5,
    },
  ];

  const scrollToSlide = (index) => {
    setCurrentSlide(index);
    scrollRef.current.scrollTo({
      left: index * scrollRef.current.offsetWidth,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const autoScroll = () => {
      const nextSlide = (currentSlide + 1) % testimonials.length;
      scrollToSlide(nextSlide);
    };

    intervalRef.current = setInterval(autoScroll, 5000);

    return () => {
      if (intervalRef.current) 
        clearInterval(intervalRef.current);
    };
  }, [currentSlide, testimonials.length]);

  const handleDotClick = (index) => {
    if (intervalRef.current)
      clearInterval(intervalRef.current);
    scrollToSlide(index);
  };

  return (
    <AnimatedDiv className="py-10 px-4 bg-[#F6F7F9]">
      <div className="w-full md:w-[90%] lg:max-w-[75%] mx-auto flex items-center flex-col justify-center">
        <div className='text-3xl lg:text-4xl font-bold text-center flex flex-col gap-2 mb-6 md:mb-10'>
          <AnimatedText>See what <span className='text-l_g'>our clients</span> have to say</AnimatedText>
        </div>

        <div ref={scrollRef} className="w-full overflow-x-auto snap-x snap-mandatory hide-scrollbar py-4">
          <div className="flex">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-[85vw] md:w-1/2 flex-shrink-0 px-2 md:px-4">
                <div className="bg-white rounded-lg shadow p-4 h-full flex flex-col">
                  <div className="flex items-start md:items-center">
                    <img src={testimonial.image} alt={testimonial.name} className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full mr-3 md:mr-4 flex-shrink-0" />
                    <div className="flex flex-col gap-1">
                      <div className="flex mb-2">
                        {[...Array(5)].map((_, i) => (
                          <FaStar key={i} className={`text-sm md:text-base ${i < testimonial.rating ? "text-[#F7CE08]" : "text-[#999999]"}`} />
                        ))}
                      </div>
                      <h3 className="font-bold text-darkGray text-sm md:text-base">{testimonial.name}</h3>
                      <p className="text-brownGray text-xs md:text-sm">{testimonial.role}</p>
                    </div>
                  </div>
                  <p className="text-brownGray text-xs md:text-sm font-light mt-3">{testimonial.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center mt-4 md:mt-8">
          {testimonials?.slice(0, 3).map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 md:w-4 md:h-4 rounded-full mx-1 transition-all duration-300 ${
                index === currentSlide ? 'bg-primary scale-125' : 'bg-[#ECF5FA]'
              }`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </AnimatedDiv>
  );
};

export default TestimonialSection;