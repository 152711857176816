import moment from 'moment';
import ReactPlayer from 'react-player';
import { useRef, useState } from 'react';
import { CgTranscript } from "react-icons/cg";
import { Rating } from '@smastrom/react-rating';
import { capitalizeFirstLetterOfEachWord, convertUTCToLocal, getRating, secondsToMinutes } from '../../utils/common';
import { useReportContext } from '../../contextProviders/ReportContext';
import TranscriptComponent from '../../components/Report/TranscriptComponent';
import AudioAnalysisReportPopup from '../../components/Report/AudioAnalysisReportPopup';
import ConsoleHelper from '../../utils/consoleHelper';
import { RESUME_STATUS } from '../../utils/constants';
import { useAuth } from '../../contextProviders/AuthContext';

export default function ReportTechnicalScreening(){
    const {user} = useAuth();
    const videoRef = useRef(null);
    const {report} = useReportContext();
    const [isPlaying, setIsPlaying] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const technicalScreening = report?.technical_screening?.[0] || {};
    const [showAudioAnalyisReport, setShowAudioAnalyisReport] = useState(false);

    const gmtDateTime = moment.utc(new Date(technicalScreening?.start_time));
    const startTime = gmtDateTime.local().format('YYYY-MMM-DD h:mm A');

    const audioAnalysis = technicalScreening?.audio_analysis?.filter((item) => item?.speaker === 'SPEAKER_00')[0];

    const timestampToSeconds = (timestamp) => {
      const match = timestamp.match(/(\d{2}):(\d{2}):(\d{2}),(\d{3})/);
      if (!match) {
        throw new Error('Invalid timestamp format');
      }
  
      const [, hours, minutes, seconds, milliseconds] = match.map(Number);
      return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    };

    if(Object.keys(technicalScreening).length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    (report?.status['technicalScreening'] === RESUME_STATUS.INVITE_SENT || report?.status['technicalScreening'] === RESUME_STATUS.COMPLETED) ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'} 
                </h1>
            </div>
        )
    }

    const getValue = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('low'))
            return <span className='font-semibold text-dr'>{value}</span>
        else if(v?.includes('medium'))
            return <span className='font-semibold text-do'>{value}</span>
        else if(v?.includes('high'))
            return <span className='font-semibold text-dg'>{value}</span>
    }

    const getTone = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('happy') || v?.includes('neutral'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('anger'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const getPace = (value) => {
        if(!value) return;

        const v = value.toLowerCase();
        if(v?.includes('fast'))
            return <span className='font-semibold text-dg'>{value}</span>
        else if(v?.includes('slow'))
            return <span className='font-semibold text-dr'>{value}</span>
        else
            return <span className='font-semibold text-do'>{value}</span>
    }

    const handlePlay = () => {
        setIsPlaying(true);
    };
    
    const handlePause = () => {
        setIsPlaying(false);
    };

    const showAudioAnalysisReportPopup = () => {
        setShowAudioAnalyisReport(true);
    }

    const hideAudioAnalysisReportPopup = () => {
        setShowAudioAnalyisReport(false);
    }

    const handleSeek = (timestamp) => {
        try {
            const seconds = timestampToSeconds(timestamp);
            if (videoRef.current) {
                videoRef.current.seekTo(Math.floor(seconds), 'seconds');
                setIsPlaying(true);
            }
        } catch (error) {
          ConsoleHelper.error(`Error seeking video: ${JSON.stringify(error)}`);
        }
    };

    return (
        <div className='flex flex-col gap-10'>
            {showAudioAnalyisReport && (
                <AudioAnalysisReportPopup 
                    audioAnalysis={audioAnalysis}
                    close={hideAudioAnalysisReportPopup}
                />
            )}
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between flex-1 h-full gap-3'>
                    <ReactPlayer
                        ref={videoRef}
                        controls
                        pip={false}
                        playing={isPlaying}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        className="react-player flex-1"
                        url={technicalScreening?.video_path}
                    />
                    <div className='flex flex-col gap-4 items-start flex-1'>
                        <div className='flex flex-col gap-4 py-10 items-center w-full'>
                            <Rating 
                                readOnly
                                color='#B99000' 
                                cancel={false} 
                                style={{width: 120}}
                                value={getRating(report?.total_score, user?.['organization']).value} 
                            />
                            <h2 className='text-sm text-primary font-semibold'>{getRating(report?.total_score, user?.['organization']).title}</h2>
                        </div>
                        <div className={`flex items-center gap-2 text-primary ${technicalScreening?.start_time ? 'visible': 'hidden'}`}>
                            <h3 className='font-semibold'>Date of the interview :</h3>
                            <span className=''>{technicalScreening?.start_time ? convertUTCToLocal(technicalScreening?.start_time) : 'Not captured' }</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Duration:</h3>
                            <span className=''>{technicalScreening?.duration ? secondsToMinutes(technicalScreening?.duration) : 'NA'}</span>
                        </div>
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Tab switches count:</h3>
                            <span className=''>{technicalScreening?.anomalies_data?.tab_switches_count}</span>
                        </div>
                        <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Full Screen Exits Count:</h3>
                            <span className=''>{technicalScreening?.anomalies_data?.full_screen_exits_count}</span>
                        </div> */}
                        {/* <div className='flex gap-2 text-primary'>
                            <h3 className='font-semibold'>Anomalies detected:</h3>
                            <ReactMarkdown>{technicalScreening?.anomalies}</ReactMarkdown>
                        </div> */}
                        {/* <div className='flex items-center gap-2 text-primary'>
                            <h3 className='font-semibold'>Facial Expression:</h3>
                            <span className=''>{technicalScreening?.anomalies_data?.facial_expression}</span>
                        </div> */}
                        <div className='flex items-center gap-2 text-primary'>
                            <p className='font-semibold'>Transcript of the interview</p>
                            <CgTranscript 
                                size={28}
                                className="cursor-pointer"
                                onClick={() => setShowTranscript(!showTranscript)}
                            />
                        </div>
                    </div>
                </div>
                {showTranscript && <TranscriptComponent id={technicalScreening?.id} hide={() => setShowTranscript(false)}/>}
                {technicalScreening?.summary && (
                    <div>
                        <h4 className='text-xl font-bold'>Summary</h4>
                        <p className='text-primary my-5'>{technicalScreening?.summary}</p>
                    </div>
                )}
            </div>
            <div className='flex flex-col'>
                <h2 className='text-xl font-bold'>Primary Skills</h2>
                <ul className='flex gap-10 flex-wrap mt-5'>
                    {Object.keys(technicalScreening?.primary_skills).map((item, index) => {
                        const object = technicalScreening?.primary_skills[item];
                        const score = object.score;
                        return (
                            <li 
                                className='w-[30%]'
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='text-sm font-bold'>
                                        {capitalizeFirstLetterOfEachWord(item)}
                                    </h5>
                                    <Rating 
                                        readOnly
                                        color='#B99000' 
                                        cancel={false} 
                                        style={{width: 120}}
                                        value={score/20} 
                                    />
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold text-sm'>Reason:</h5>
                                    <p className='pb-3'>{object.reason}</p>
                                    <div className={`flex flex-col gap-3 ${technicalScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {technicalScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div>
                <h2 className='text-xl font-bold'>Secondary Skills</h2>
                <ul className='flex gap-10 flex-wrap mt-5'>
                    {Object.keys(technicalScreening?.secondary_skills).map((item, index) => {
                        const object = technicalScreening.secondary_skills[item];
                        const score = object.score;
                        return (
                            <li
                                className='w-[30%]'
                                key={index?.toString()}>
                                <div className='flex justify-between items-center'>
                                    <h5 className='font-bold'>
                                        {capitalizeFirstLetterOfEachWord(item)}
                                    </h5>
                                    <Rating 
                                        readOnly
                                        color='#B99000' 
                                        cancel={false} 
                                        style={{width: 120}}
                                        value={score/20} 
                                    />
                                </div>
                                <div className='flex flex-col gap-2 mt-2'>
                                    <h5 className='font-semibold'>Reason:</h5>
                                    <p className='pb-3'>{object.reason}</p>
                                    <div className={`flex flex-col gap-3 ${technicalScreening["video_snippets"]?.[item]?.length > 0 ? 'visible': 'hidden'}`}>
                                        <h3 className="font-semibold text-sm">Video Coverage</h3>
                                        <ul className={'flex flex-wrap gap-5'}>
                                            {technicalScreening["video_snippets"]?.[item]?.map((item) => (
                                                <li key={item} className='font-semibold text-xs flex gap-5 text-blue'>
                                                    <span className="cursor-pointer" onClick={handleSeek.bind(this, item[0])}>{item[0]}</span>
                                                    <span  className="cursor-pointer" onClick={handleSeek.bind(this, item[1])}>{item[1]}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className={`flex flex-col ${audioAnalysis?.['summary'] ? 'visible': 'hidden'} gap-2`}>
                <div className='flex gap-4'>
                    <h2 className='text-xl font-bold'>Communication Skills</h2>
                    <button 
                        onClick={showAudioAnalysisReportPopup} 
                        className='font-medium text-blue'>
                        Show more
                    </button>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className={`flex mt-2 ${audioAnalysis?.score !== undefined ? 'visible': 'hidden' }`}>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(audioAnalysis?.score, user?.['organization']).value} 
                        />
                    </div>
                    <div className="flex flex-wrap gap-5">
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.tone ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Tone : </h2>
                            {getTone(audioAnalysis?.tone)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.pace || 'Medium' ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Pace : </h2>
                            {getPace(audioAnalysis?.pace || 'Medium')}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.speech_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Speech Quality : </h2>
                            {getValue(audioAnalysis?.speech_quality)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_volume ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Volume : </h2>
                            {getValue(audioAnalysis?.audio_volume)}
                        </div>
                        <div className={`flex gap-2 flex-1/3 ${audioAnalysis?.audio_quality ? 'visible' : 'hidden'}`}>
                            <h2 className="font-semibold text-darkGray">Audio Quality : </h2>
                            {getValue(audioAnalysis?.audio_quality)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}