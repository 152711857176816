import moment from 'moment/moment';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { BsThreeDotsVertical } from "react-icons/bs";
import addIcon from './../../../assets/icons/add.svg';
import UserService from '../../../services/user.service';
import editIcon from './../../../assets/icons/edit.svg';
import { getPaginationLimitForJD} from '../../../utils/common';
import ConsoleHelper from '../../../utils/consoleHelper';

export default function HRAdminUserManagement(){

    const navigate = useNavigate();
    const [first, setFirst] = useState(0);
    const [users, setUsers] = useState();
    
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectUser, setSelectedUser] = useState(null);

    const [rows, setRows] = useState(getPaginationLimitForJD());
    const [limit, setLimit] = useState(getPaginationLimitForJD());

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    useEffect(() => {
        
        const handleResize = () => {
            setLimit(getPaginationLimitForJD())
            setRows(getPaginationLimitForJD())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        async function getUsers(){
            try {
                setLoading(true);
                const response = await UserService.getUsers(currentPage, limit);
                setLoading(false);
                if(response.status === 200){
                    const {data, totalCount, totalPage} = response.data;
                    setUsers(data)
                    setTotalCount(totalCount);
                    setTotalPage(totalPage);
                }
            } catch (error) {
                setLoading(false);
                ConsoleHelper.error(error?.response?.data?.message)
            }
        }
        getUsers();
    }, [currentPage, limit]);

    const navigateTo = (path) => {
        navigate(path)
    }

    const renderCreatedAt = ({createdAt}) => {
        return (
            <div className='rounded-full justify-center items-center flex'>
                <span className='w-40 text-sm'>{moment(createdAt).format('DD MMM yyyy')}</span>
            </div>
         )
    }

    const options = (item) => {
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className={`absolute transform -translate-x-1/2 -translate-y-1/2`}>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className="cursor-pointer"
                    onClick={() => setSelectedUser(item)}
                />
                <div className={`${selectUser?._id === item?._id ? 'visible' : 'hidden'} rounded-lg shadow shadow-gray bg-white -top-5 right-0 z-20 absolute`}>
                    <ul className=" gap-2 flex flex-col">
                        <li
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}}
                            onClick={navigateTo.bind(this, `/dashboard/users-management/${selectUser?._id}/update`)} 
                            className={`py-2 text-blue w-20 flex items-center gap-3 px-3 rounded cursor-pointer ${item?.active === false ? 'hidden': 'visible'}`}>
                            <img 
                                alt='edit-icon'
                                src={editIcon}
                                className='h-4 w-4'
                            />
                            <h4 className='text-sm'>Edit</h4>
                        </li>
                        {/* <li 
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active !== false ? 'visible': 'hidden'}`} 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOff size={20}/>
                            <h4 className='text-sm bg'>Delete</h4>
                        </li> */}
                    </ul>
                </div>
            </div>
        )
    }

    const renderLastName = ({lastName}) => {
        return <span className=''>{lastName || 'NA'}</span>
    }

    const renderMobile = ({mobile}) => {
        return <span className=''>{mobile || 'NA'}</span>
    }

    return (
        <div 
            onClick={() => setSelectedUser(null)}
            className="flex flex-col h-full gap-4 p-5">
            <div className="flex justify-between items-center">
                <h1 className='text-[#161616] text-xl font-bold'>User Management</h1>
                <div 
                    onClick={navigate.bind(this, '/dashboard/users-management/create')}
                    className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4">
                    <span>Create User</span>
                    <img 
                        alt='add'
                        src={addIcon}
                        className='h-5 w-5'
                    />
                </div>
            </div>
            {/* <div className='flex gap-4'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <ExperienceDropdownComponent 
                    label={selectedExperience}
                    setLabel={setSelectedExperience}
                />
                <LocationDropdownComponent 
                    label={selectedLocation}
                    setLabel={setSelectedLocation}
                />
            </div> */}
            <div 
                className="bg-white rounded mt-5">
                <DataTable 
                    value={users}
                    loading={loading}
                    emptyMessage="No Users available"
                    rowClassName='bg-white'>
                    <Column 
                        field="firstName" 
                        header="FirstName" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="lastName" 
                        header="LastName" 
                        body={renderLastName}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="email" 
                        header="Email" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="mobile" 
                        header="Mobile"
                        body={renderMobile}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="createdAt" 
                        header="Created Dt"
                        body={renderCreatedAt}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                className={`${totalPage > 1 ? 'visible': 'hidden'} justify-end bg-[#ECF5FA]`}
            />
        </div>
    )
}