import moment from 'moment/moment';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import logo from './../../../assets/icons/logo.svg';
import JDService from '../../../services/jd.service';
import { PiVideoConferenceBold } from "react-icons/pi";
import addIcon from './../../../assets/icons/add.svg';
import ConsoleHelper from '../../../utils/consoleHelper';
import { formatLabel, getPaginationLimitForJD} from '../../../utils/common';
import { useToast } from '../../../contextProviders/ToastContext';
import { useJDInfo } from '../../../contextProviders/JDInfoContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import ExitInterviewService from '../../../services/exitInterview.service';
import { InputText } from 'primereact/inputtext';
import { BsThreeDotsVertical } from 'react-icons/bs';

export default function ExitInterview(){

    const {clearJD} = useJDInfo();
    const navigate = useNavigate();
    
    const [first, setFirst] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [clickedOn, setClickedOn] = useState(null);
    const [selectedExitInterviews, setSelectedExitInterviews] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [uploading, setUploading] = useState(false);
    const [exitInterviews, setExitInterviews] = useState([]);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [menuVisibility, setMenuVisibility] = useState(false);

    const [rows, setRows] = useState(getPaginationLimitForJD());
    const [limit, setLimit] = useState(getPaginationLimitForJD());

    const [selectedExitInterview, setSelectedExitInterview] = useState(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    useEffect(() => {
        clearJD();
        
        const handleResize = () => {
            setLimit(getPaginationLimitForJD())
            setRows(getPaginationLimitForJD())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    async function getExitInterviews(){
        try {
            setLoading(true);
            const response = await ExitInterviewService.getExitInterviews(currentPage, limit);
            setLoading(false);

            if(response.status === 200){
                const {
                    data, 
                    totalPage,
                    totalCount,
                } = response.data;

                setExitInterviews(data)
                setTotalCount(totalCount);
                setTotalPage(totalPage);
            }
        
        } catch (error) {
            setLoading(false);
            ConsoleHelper.error(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        getExitInterviews();
    }, [currentPage, limit]);


    const toggleJDActiveStatus = async () => {
        try {
            setUploading(true);
            const jdResponse = 
                clickedOn === "activate" ? await JDService.activateJD(selectedExitInterview?._id) : await JDService.inactivateJD(selectedExitInterview?._id);
            setUploading(false);
            if(jdResponse.status === 200){
                setShowConfirmationDialog(false);
                setExitInterviews(exitInterviews?.map((item => {
                    if(item?._id === selectedExitInterview?._id)
                        return {...item, active: clickedOn === "activate" ? true : false}
                    return item;
                })))
                setSelectedExitInterview(null);
                if(clickedOn === "inactivate"){
                    showSuccessMessage({
                        life: 5000,
                        summary: 'Alert', 
                        detail: "Please ensure there are no active interview invites. Candidate can continue the interview if any invites are sent"
                    })
                }
                else {
                    showSuccessMessage({
                        summary: 'Success', 
                        detail: "JD is activated"
                    })
                }
            } 
        }
        catch (error) {
            setUploading(false);
            setShowConfirmationDialog(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }

    const renderCreatedAt = ({createdAt}) => {
        return (
            <div className='rounded-full justify-center items-center flex'>
                <span className='text-sm'>{moment(createdAt).format('DD MMM yyyy')}</span>
            </div>
         )
    }

    const sendExitInterviewInvite = async (_id) => {
        try {
            setLoading(true);
            const response = await ExitInterviewService.sendExitInterviewInvite(_id,) 
            setLoading(false);
            if(response.status === 200){
                showSuccessMessage({
                    summary: 'Invite Sent', 
                    detail: 'Exit Interview invite sent'
                })
                setSelectedExitInterview(null);
                await getExitInterviews();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: "Failed", detail: 'Failed to sent invite'})
        }
    }

    const options = (item) => {
        return (
            <div 
                onClick={(event) => {
                    event?.stopPropagation();
                    setMenuVisibility(false);
                }}
                className='absolute z-50 transform -translate-x-1/2 -translate-y-1/2'>
                <div className={`${selectedExitInterview?._id === item?._id ? 'visible' : 'hidden'} rounded-lg  shadow-gray shadow bg-white z-50 -top-12 right-0 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col w-48 text-sm">
                        <li 
                            className='py-2 text-[#3C99CA] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer'
                            onClick={sendExitInterviewInvite.bind(this, item['_id'])}
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}} >
                                <PiVideoConferenceBold size={16}/>
                                <h4 className='text-sm'>Exit Interview Invite</h4>
                        </li>
                    </ul>
                </div>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className='cursor-pointer z-0'
                    onClick={() => setSelectedExitInterview(item)}
                />
            </div>
        )
    }

    const onRowEditComplete = async (e) => {
        try {
            let data = [...exitInterviews];
            let { newData, index } = e;
            const candidateEmail = newData['candidateEmail'];
            const candidateName = newData['candidateName'];

            const requestData = {};
            if(candidateName) requestData['candidateName'] = candidateName;
            if(candidateEmail) requestData['candidateEmail'] = candidateEmail?.trim();
            
            if(Object.keys(requestData).length){
                const response = await ExitInterviewService.updateExitInterview(newData?._id, requestData);
                if(response.status === 200){              
                    data[index] = { ...newData, candidateEmail, candidateName};
                    setExitInterviews(data);
                    showSuccessMessage({ summary: 'Updated', detail: "Updated exit interview" })
                }
            }
        } catch (error) {
            showErrorMessage({ summary: "Failed", detail: 'Failed to update exit interview'})
        }
    };

    const renderStatus = ({status}) => {
        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                    {formatLabel(status)}
                </span>
            </div>
        )
    }

    const textEditor = (options) => {
        const data = options?.rowData;
        if(!data) return;

        let defaultValue = null;
        if(options.field === 'candidateName')
            defaultValue = data['Name'];
        else if(options.field === 'candidateEmail')
            defaultValue = data['Email'];
        return (
            <InputText 
                type="text" 
                value={options?.value} 
                defaultValue={defaultValue}
                className='border border-primary px-1 h-10'
                onChange={(e) => options.editorCallback(e.target.value)} 
            />
        )
    };

    return (
        <div 
            onClick={() => setSelectedExitInterview(null)}
            className="flex flex-col h-full gap-4 p-5">
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <div className='flex flex-col items-center justify-center'>
                            <p className="text-brownGray text-base">Would you want to {clickedOn === "activate"? "activate" : "inactivate"} the JD?</p>
                        </div>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={toggleJDActiveStatus}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <h1 className='text-[#161616] text-xl font-bold'>Exit Interview List</h1>
                <div 
                    onClick={() => navigate('/dashboard/exit-interview/create')}
                    className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4">
                    <span>Initiate Exit Interview</span>
                    <img 
                        alt='add'
                        src={addIcon}
                        className='h-5 w-5'
                    />
                </div>
            </div>
            {/* <div className='flex gap-4'>
                <SearchbarComponent 
                    label={search}
                    onChange={setSearch}              
                />
                <ExperienceDropdownComponent 
                    label={selectedExperience}
                    setLabel={setSelectedExperience}
                />
                <LocationDropdownComponent 
                    label={selectedLocation}
                    setLabel={setSelectedLocation}
                />
            </div> */}
            <div 
                className="bg-white rounded mt-5">
                <DataTable
                    dataKey="_id"
                    editMode="row" 
                    value={exitInterviews}
                    loading={loading}
                    onRowEditComplete={onRowEditComplete}
                    emptyMessage="No interviews available"
                    selectionMode='checkbox' 
                    className="text-brownGray"
                    // onRowClick={({data}) => {
                    //     navigate(`/dashboard/job-description/${data._id}`, {state: data})
                    // }}
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                    onSelectionChange={(e) => setSelectedExitInterviews(e.value)}>
                    <Column 
                        selectionMode="multiple" 
                        headerClassName='max-w-5'
                        className='max-w-10'
                    />
                    <Column 
                        field="candidateName" 
                        header="Name" 
                        className='text-sm 2xl:text-base'
                        editor={(options) => textEditor(options)}
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        field="candidateEmail" 
                        header="Email" 
                        className='text-sm 2xl:text-base'
                        editor={(options) => textEditor(options)}
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="jobRole" 
                        header="Role" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Status" 
                        body={renderStatus}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="createdAt" 
                        header="Created Dt"
                        body={renderCreatedAt}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        rowEditor={true} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white'>
                    </Column>
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                className={`${totalPage > 1 ? 'visible': 'hidden'} justify-end bg-[#ECF5FA]`}
            />
        </div>
    )
}