import { useState } from 'react';
import { Rating } from '@smastrom/react-rating';
import { RESUME_STATUS } from '../../utils/constants';
import { getRating } from '../../utils/common';
import { useReportContext } from '../../contextProviders/ReportContext';
import CircularProgress from '../../components/Report/CircularProgress';
import CustomReactMarkdown from '../../components/Common/CustomReactMarkdown';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function ReportResumeScreening(){
    const {report} = useReportContext();
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [hoveredSkillColor, setHoveredSkillColor] = useState(null);
    const [hoveredExperienceSkill, setHoveredExperienceSkill] = useState(null);
    const [hoveredExperienceSkillColor, setHoveredExperienceSkillColor] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const resumeScreening = report?.resume_screening[0] || {};
    const skillRelevance = [
        ...(resumeScreening['mandatory_skills'] || []),
        ...(resumeScreening['optional_skills'] || [])
    ];

    if(Object.keys(resumeScreening).length === 0){
        return (
            <div className='flex flex-col gap-10'>
                <h1 className='font-medium text-base'>{
                    (report?.status['resumeScreening'] === RESUME_STATUS.INVITE_SENT || report?.status['resumeScreening'] === RESUME_STATUS.COMPLETED) ? 
                        'Report is being prepared, will be available shortly.' : 'Report not initiated'} 
                </h1>
            </div>
        )
    }

    const renderSkills = (skills) => {
        if (!skills || skills.length === 0) return null;
    
        return (
            <div className='flex flex-col gap-3'>
                <ul className="flex gap-4 flex-wrap">
                    {skills.map((item, index) => {
                        const { name, score, weightage } = item;
                        const percentage = weightage > 0 ? ((score / weightage) * 100).toFixed(2) : 0;
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                onMouseEnter={() => {
                                    setHoveredSkill(item);
                                    setHoveredSkillColor(colorClass);
                                }}
                                onMouseLeave={() => {
                                    setHoveredSkill(null);
                                    setHoveredSkillColor(null);
                                }}
                                className={`flex gap-2 rounded-full cursor-pointer px-2 py-1 font-medium text-xs ${colorClass}`}
                            >
                                <h5>{name}</h5>
                                <span>{percentage}%</span>
                            </li>
                        );
                    })}
                </ul>
                {hoveredSkill && (
                    <div className={`text-sm font-normal w-2/3 px-4 py-2 rounded-full ${hoveredSkillColor} transition-opacity duration-1000 ${
                        hoveredSkill ? 'opacity-100' : 'opacity-0'
                    }`}>
                        <p>{hoveredSkill['summary']}</p>
                    </div>
                )}
            </div>
        );
    };

    const renderExperienceSkills = (skills = []) => {
        if (!skills.length) return null;
    
        return (
            <div className='flex flex-col gap-3'>
                <ul className="flex gap-4 flex-wrap">
                    {skills.map((item, index) => {
                        const { name, required_experience, candidate_experience } = item;
                        const percentage = 
                        required_experience === 0 && candidate_experience > 0 ? 100 :
                        required_experience ? Math.min((candidate_experience / required_experience) * 100, 100).toFixed(2) : 
                            0;
        
                        const colorClass =
                            percentage >= 80 ? 'bg-lg text-dg' :
                            percentage >= 40 ? 'bg-lo text-do' :
                            'bg-lr text-dr';
        
                        return (
                            <li
                                key={index}
                                onMouseEnter={() => {
                                    setHoveredExperienceSkill(item);
                                    setHoveredExperienceSkillColor(colorClass);
                                }}
                                onMouseLeave={() => {
                                    setHoveredExperienceSkill(null);
                                    setHoveredExperienceSkillColor(null);
                                }}
                                className={`flex relative gap-2 cursor-pointer rounded-full px-2 py-1 font-medium text-xs ${colorClass}`}
                            >
                                <h5>{name}</h5>
                                <span>{percentage}%</span>
                            </li>
                        );
                    })}
                </ul>
                {hoveredExperienceSkill && (
                    <div className={`text-sm font-normal gap-4 flex w-1/2 justify-between items-center px-4 py-2 rounded-full ${hoveredExperienceSkillColor} font-medium transition-opacity duration-1000 ${
                        hoveredExperienceSkill ? 'opacity-100' : 'opacity-0'
                    }`}>
                        <span className=''>Required Experience: {hoveredExperienceSkill['required_experience']} years</span>
                        <span>Candidate Experience: {hoveredExperienceSkill['candidate_experience']} years</span>
                    </div>
                )}
            </div>
        );
    };    

    return (
        <div className='flex w-full flex-col pt-5 gap-10'>
            <div className='flex justify-center items-center gap-10'>
                {/* <Knob 
                    size={100}
                    value={resumeScreening?.score || 0} 
                    valueTemplate={'{value}%'} 
                    valueColor={resumeScreening?.score < 50 ? '#D30B53' : 
                        (resumeScreening?.score >= 50 && resumeScreening?.score < 80) ? '#B99000' : '#278500'
                    }
                /> */}
                <CircularProgress score={parseFloat(resumeScreening?.score).toFixed(1)}/>
                <div className='flex gap-2 flex-col'>
                    <label className='text-xl font-bold'>Summary</label>
                    <CustomReactMarkdown data={resumeScreening?.summary}/>
                </div>
            </div>
            <div className={`flex gap-2 flex-col ${resumeScreening?.matchedReason ? 'visible' : 'hidden'}`}>
                <label className='text-xl font-bold'>Matched Reason</label>
                <p className='text-primary'>{resumeScreening?.matchedReason}</p>
            </div>
            <div className={`flex gap-2 flex-col ${resumeScreening?.irrelevant ? 'visible' : 'hidden'}`}>
                <label className='text-xl font-bold'>Irrelevance</label>
                <p className='text-primary'>{resumeScreening?.irrelevant}</p>
            </div>
            <div 
                onClick={() => setShowAll(true)}
                className={`flex items-center gap-1 m-auto cursor-pointer ${!showAll ? 'visible': 'hidden'}`}>
                <span className='font-semibold'>Show more</span>
                <MdOutlineKeyboardArrowDown size={20}/>
            </div>
            <div className={`${showAll ? 'visible': 'hidden'} flex flex-col gap-10`}>
                <div className={`flex gap-2 flex-col ${resumeScreening?.educationRelevanceSummary && resumeScreening?.candidateExperience <= 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Education relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_education)?.value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_education ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.educationRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.skillRelevanceSummary ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Skill relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_skill)?.value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_skill ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.skillRelevanceSummary}</p>
                    {renderSkills(skillRelevance)}
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.experienceRelevanceSummary && resumeScreening?.candidateExperience > 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Experience Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_experience).value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_experience ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.experienceRelevanceSummary}</p>
                    {renderExperienceSkills(skillRelevance)}
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.workRelevanceSummary ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Work Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.relevant_work).value} 
                            className={`${resumeScreening?.scoreInfo?.relevant_work ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.workRelevanceSummary}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.industryRelevance && resumeScreening?.candidateExperience > 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Industry Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.industry_relevance).value} 
                            className={`${resumeScreening?.scoreInfo?.industry_relevance ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.industryRelevance}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.companyRelevance && resumeScreening?.candidateExperience > 2 ? 'visible' : 'hidden'}`}>
                    <div className='flex gap-5'>
                        <h1 className='text-xl font-bold'>Company Relevance</h1>
                        <Rating 
                            readOnly
                            color='#B99000' 
                            cancel={false} 
                            style={{width: 120}}
                            value={getRating(resumeScreening?.scoreInfo?.company_relevance).value} 
                            className={`${resumeScreening?.scoreInfo?.company_relevance ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <p className='text-primary'>{resumeScreening?.companyRelevance}</p>
                </div>
                <div className={`flex gap-2 flex-col ${resumeScreening?.missingSkillsSummary ? 'visible' : 'hidden'}`}>
                    <h1 className='text-xl font-bold'>Missing Skills</h1>
                    <p className='text-primary'>{resumeScreening?.missingSkillsSummary}</p>
                </div>
                <div 
                    onClick={() => setShowAll(false)}
                    className={`flex items-center gap-1 m-auto cursor-pointer ${showAll ? 'visible': 'hidden'}`}>
                    <span className='font-semibold'>Show less</span>
                    <MdOutlineKeyboardArrowUp size={20}/>
                </div>
            </div>
        </div>
    )
}