import axiosInstance from '.';

const getConfig = (organizationId) => {
    return axiosInstance.get(`/organization/${organizationId}/config`);
};

const ConfigService = {
    getConfig
}

export default ConfigService;