import axios from "axios";

function getEvaluation(sessionId){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    return axios.get(`${process.env.REACT_APP_API_URL}/screening/evaluation/${sessionId}`, {
        headers: { 'Authorization': `Bearer ${accessToken.token}`}
    })
}

function getVoices(){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    return axios.get(`${process.env.REACT_APP_API_URL}/screening/voices`, {
        headers: {'Authorization': `Bearer ${accessToken.token}`}
    })
}

function getVoiceAudio(data){
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    return axios.post(`${process.env.REACT_APP_API_URL}/screening/voices/audio`, data, {
        headers: {'Authorization': `Bearer ${accessToken.token}`}
    })
}

const ScreeningService = {
    getVoices,
    getEvaluation,
    getVoiceAudio
}

export default ScreeningService;