import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import image2 from './../../assets/images/report.svg';
import image3 from './../../assets/images/techincal-screening.svg';
import image7 from './../../assets/images/initial-assessment.svg';
import image4 from './../../assets/images/solution-4.svg';
import image5 from './../../assets/images/solution-5.svg';
import image6 from './../../assets/images/solution-6.svg';
import image8 from './../../assets/images/interview-scheduling.svg';
import image9 from './../../assets/images/face-detection.svg';
import image10 from './../../assets/images/initial-screening-report.svg';
import pattern1 from './../../assets/images/ring-arrow3.svg';
import pattern2 from './../../assets/images/ring-arrow2.svg';
import pattern3 from './../../assets/images/hash.svg';

import pattern4 from './../../assets/images/squiggle.svg';
import pattern5 from './../../assets/images/ring-arrow2.svg';
import pattern6 from './../../assets/images/hash.svg';

import pattern7 from './../../assets/images/line-ring.svg';
import pattern8 from './../../assets/images/hash.svg';
import pattern9 from './../../assets/images/sparkle.svg';

const AccordionItem = ({ title, content, isOpen, onClick }) => (
  <div className="">
    <button
      className="flex justify-between items-center w-full py-3 px-6 text-left bg-[#F6F7F9] text-darkGray"
      onClick={onClick} >
      <span className="font-medium">{title}</span>
      <span className="text-2xl">{isOpen ? '-' : '+'}</span>
    </button>
    {isOpen && (
      <div className="px-4 py-3 tracking-wide">
        <p className="text-brownGray font-normal text-sm leading-2">{content}</p>
      </div>
    )}
  </div>
);

AccordionItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}

function JobDescription(){
  const [openIndex, setOpenIndex] = useState(0);

  const items = [
    {
      image: image6,
      pattern: pattern1,
      title: "Refine Imperfect Job Descriptions",
      content: "Athmick helps craft detailed, accurate JDs by analyzing role requirements, offering comprehensive templates, and even supporting JD-less hiring for maximum flexibility."
    },
    {
      image: image4,
      pattern: pattern2,
      title: "Capture the Ideal Candidate Profile",
      content: "Athmiya allows hiring managers to articulate the ideal candidate through voice notes, capturing nuances often missed in traditional JDs. This ensures a more accurate match with the best-fit candidates."
    },
    {
      image: image5,
      pattern: pattern3,
      title: "Enhance Resume Shortlisting",
      content: "Athmick leverages AI and machine learning to evaluate resumes beyond mere keywords, assessing skills, experience, and potential. This leads to more accurate and effective candidate selection."
    }
  ];

  return (
  <div className="flex flex-col-reverse md:flex-row md:gap-10 justify-between">
    <AnimatedDiv className="flex-1 md:pr-8 flex flex-col gap-10 rounded-md h-full">
      {items.map((item, index) => (
        <AccordionItem
          title={item.title}
          content={item.content}
          key={index?.toString()}
          isOpen={index === openIndex}
          onClick={() => setOpenIndex(index === openIndex ? openIndex : index)}
        />
      ))}
    </AnimatedDiv>
    <AnimatedDiv className="flex-1 flex items-center justify-end p-10 md:p-0 min-h-full bg-lightBlue rounded-lg relative">
      <img 
        alt="feature" 
        className="w-3/4"
        src={[items[openIndex]?.image]}  
      />
      <img 
        alt="pattern" 
        className="absolute -left-10 top-[20%] animate-float"
        src={[items[openIndex]?.pattern]}  
      />
    </AnimatedDiv>
  </div>
  )
}

function InitialAssessment(){
  const [openIndex, setOpenIndex] = useState(0);

  const items = [
    {
      image: image7,
      title: "Streamline Initial Assessments",
      pattern: pattern4,
      content: "Athmick automates the early stages of candidate evaluation with AI-driven interviews and questionnaires, significantly reducing HR's time on routine tasks and allowing them to focus on more strategic priorities."
    },
    // {
    //   image: image2,
    //   title: "Enhance Role Articulation",
    //   content: "Athmick provides detailed, consistent role explanations and conducts virtual presentations, ensuring candidates fully grasp the job."
    // },
    {
      image: image3,
      pattern: pattern5,
      title: "Revolutionize Technical Interviews",
      content: "Athmiya, the AI interviewer, conducts tailored technical assessments with instant feedback, freeing up technical managers to focus on more meaningful conversations like rapport building and team alignment."
    },
    {
      image: image2,
      pattern: pattern6,
      title: "Eliminate Inconsistent and Biased Evaluations",
      content: "Athmick removes human biases and ensures that every candidate is evaluated on the same grounds, leading to more objective and equitable hiring decisions."
    }
  ];

  return (
    <div className="flex flex-col-reverse md:flex-row md:gap-10 justify-between h-full">
      <AnimatedDiv className="flex-1 flex items-center justify-end p-10 md:p-0 h-full bg-lightBlue rounded-lg relative">
        <img 
          alt="feature" 
          className="object-center h-3/5"
          src={[items[openIndex]?.image]}  
        />
        <img 
          alt="pattern" 
          className="absolute left-0 top-[20%] animate-float"
          src={[items[openIndex]?.pattern]}  
        />
      </AnimatedDiv>
      <AnimatedDiv className="flex-1 md:pr-8 flex flex-col gap-10 rounded-md h-full">
        {items.map((item, index) => (
          <AccordionItem
            title={item.title}
            content={item.content}
            key={index?.toString()}
            isOpen={index === openIndex}
            onClick={() => setOpenIndex(index === openIndex ? openIndex : index)}
          />
        ))}
      </AnimatedDiv>
    </div>
  )
}

function InterviewScheduling(){
  const [openIndex, setOpenIndex] = useState(0);

  const items = [
    {
      image: image8,
      pattern: pattern7,
      title: "Eliminate the Effort Spent on Interview Scheduling",
      content: "Athmick sends candidates a link to complete their interview at their convenience, completely removing the need for coordination between candidates and technical interviewers."
    },
    {
      image: image9,
      pattern: pattern8,
      title: "Combat Impersonation",
      content: "Athmick employs advanced anomaly detection techniques like lip sync analysis, gaze detection, and tab monitoring to ensure candidate authenticity and mitigate the risk of impersonation."
    },
    {
      image: image10,
      pattern: pattern9,
      title: "Lack of Efficient Assessment Review",
      content: "Athmick securely records and stores interviews, allowing for easy review and skill-based evaluation at any time. This ensures more informed and confident hiring decisions."
    }
  ];

  return (
    <div className="flex flex-col-reverse md:flex-row md:gap-10 justify-between">
      <AnimatedDiv className="flex-1 md:pr-8 flex flex-col gap-10 rounded-md h-full">
        {items.map((item, index) => (
          <AccordionItem
            title={item.title}
            content={item.content}
            key={index?.toString()}
            isOpen={index === openIndex}
            onClick={() => setOpenIndex(index === openIndex ? openIndex : index)}
          />
        ))}
      </AnimatedDiv>
      <AnimatedDiv className="flex-1 flex items-center justify-end p-10 md:p-0 min-h-full bg-lightBlue rounded-lg relative">
        <img 
          alt="feature" 
          className="w-3/4"
          src={[items[openIndex]?.image]}  
        />
        <img 
          alt="pattern" 
          className="absolute -left-10 top-[20%] animate-float"
          src={[items[openIndex]?.pattern]}  
        />
      </AnimatedDiv>
    </div>
  )
}

function RecruitmentChallenges() {
  return (
    <section className="py-5">
      <div className="m-auto flex flex-col gap-10 w-full md:max-w-[80%] py-5">
        <AnimatedText className="w-full md:w-2/3 text-3xl md:text-4xl font-bold text-center m-auto">
          <span className="text-l_g">Use cases</span> addressing common recruitment challenges
        </AnimatedText>
        <AnimatedDiv>
          <p className="m-auto w-full md:w-2/3 px-10 text-brownGray font-normal text-base leading-6 tracking-wider text-center">
            Transform your hiring with our AI-powered talent evaluation platform. Streamline recruitment, boost candidate quality, reduce costs and effort, all while accelerating time-to-hire with features designed for every stage of your process.
          </p>
        </AnimatedDiv>
        <div className='flex flex-col gap-20 mt-10'>
          <JobDescription />
          <InitialAssessment />
          <InterviewScheduling />
        </div>
      </div>
    </section>
  );
}

export default RecruitmentChallenges;