import React from 'react';
import { AnimatedDiv } from '../Common/AnimatedDiv';

const SatisfactionBanner = () => {
  return (
    <AnimatedDiv className="bg-[#F6F7F9] p-4 py-10 flex flex-col md:flex-row gap-10 items-center justify-center space-x-6 text-primary">
      <div className="bg-white py-3 px-4 md:p-4 rounded shadow">
        <span className="font-bold text-gray-800">4.9/5</span>
        <span className="ml-2">Customer Satisfication</span>
      </div>
      <p className="max-w-xl text-brownGray font-normal text-base leading-6 tracking-wider text-center">
        Athmick is the ideal AI-powered recruitment and evaluation platform, empowering you to excel in today’s competitive job market.
      </p>
    </AnimatedDiv>
  );
};

export default SatisfactionBanner;