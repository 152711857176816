import PropTypes from 'prop-types';
import { MdCheck } from "react-icons/md";

function CheckboxComponent({label, value, setValue}){
    const onClick = () => {
        setValue(label, !value)
    };

    return (
        <div 
            onClick={onClick} 
            className='min-h-5 min-w-5 border-l_border border-[1px] rounded text-primary flex justify-center items-center cursor-pointer'>
            {value && <MdCheck size={18} /> }
        </div>
    )
}
CheckboxComponent.propTypes = {
    label: PropTypes.string,
    value: PropTypes.bool,
    setValue: PropTypes.func
}

export default CheckboxComponent;