import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default  function CustomReactMarkdown(props){
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]} 
            className='text-primary markdown flex-1'>
            {props.data}
        </ReactMarkdown>
    )
}

CustomReactMarkdown.propTypes = {
    data: PropTypes.string
}