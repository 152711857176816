import React from 'react';
import greenline from './../../assets/images/green-line.svg';
import pattern from './../../assets/images/athmick-pattern.svg';
import jdResumeDashboard from './../../assets/images/resume-screening-dashboard.svg';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import arrow from '../../assets/images/ring-arrow.svg';
import ellipse from './../../assets/images/green-ellipse.svg';
import { AnimatedImage } from '../Common/AnimatedImage';

const ResumeScreeningSection = () => {
  return (
    <div className="relative p-10 md:p-16 gap-10 flex flex-col text-white bg-gradient-to-r from-[#2B4360] to-[#3C99CA]">
        <img
            alt='pattern'
            src={pattern}
            className='absolute right-0 top-0'
        />
        <div className="max-w-4xl mx-auto flex flex-col gap-10 py-5">
            <AnimatedDiv className="text-2xl md:text-3xl font-bold text-center flex flex-col md:flex-row gap-2">
                <div className='relative'>
                    <h1>Resume screening</h1>   
                    <img 
                        src={greenline}
                        className='absolute -bottom-2'
                        alt='green-line'
                    /> 
                </div>
                <h1>through Evaluations</h1>
            </AnimatedDiv>
        </div>
        <AnimatedDiv className='w-full flex justify-center items-center'>
            <div className='relative justify-center flex flex-col items-center md:w-2/3 w-full'>
                <AnimatedImage
                    src={arrow}
                    alt='arrow'
                    className='md:h-28 md:w-28 h-10 w-10 absolute -left-14 top-0 z-20 animate-float'
                />
                <img
                    alt='ellipse'
                    src={ellipse}
                    className='absolute -bottom-10 -left-28 z-0'
                />
                <img
                    alt='ellipse-2'
                    src={ellipse}
                    className='absolute translate-x-[60%] -translate-y-[60%] z-0'
                />
                <div className="rounded-lg overflow-hidden mb-8 mx-auto relative z-10">
                    <img
                        src={jdResumeDashboard}
                        alt="JD Resume Dashboard"
                        className="object-cover"/>
                </div>
                <div className='flex justify-center items-center'>
                    <ul className='flex flex-col md:flex-row gap-2 md:gap-5 justify-between list-disc list-inside text-center md:text-base text-sm'>
                        <li className='font-semibold text-white'>Resume analysis</li>
                        <li className='font-semibold text-white'>Candidate assessments</li>
                        <li className='font-semibold text-white'>Candidate ranking</li>
                        <li className='font-semibold text-white'>Bias reduction</li>
                    </ul>
                </div>
            </div>
        </AnimatedDiv>
    </div>
  );
};

export default ResumeScreeningSection;