import axiosInstance from ".";

function getSupports(){
    return axiosInstance.get('/supports');
}

function createSupport(data){
    return axiosInstance.post('/supports', data);
}

const SupportService = {
    getSupports,
    createSupport
}

export default SupportService;