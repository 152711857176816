import { Navigate, Route, Routes } from "react-router-dom";
import AdminUserCreate from "../pages/HRAdmin/UserManagement/HRAdminUserCreate";
import AdminUserManagement from "../pages/HRAdmin/UserManagement/HRAdminUserManagement";
import AdminUserManagementHome from "../pages/HRAdmin/UserManagement/HRAdminUserManagementHome";
import HRAdminHome from "../pages/HRAdmin/Dashboard/HRAdminHome";
import HRAdminDashboard from "../pages/HRAdmin/Dashboard/HRAdminDashboard";

import JDRoutes from "./JDRoutes";
import ReportRoutes from "./ReportRoutes";
import HRProfile from "../pages/HR/HRProfile";
import HRSupport from "../pages/HR/HRSupport";
import JDProvider from "../contextProviders/JDInfoContext";
import { ReportProvider } from "../contextProviders/ReportContext";
import NotificationPage from "../pages/HR/Dashboard/NotificationPage";
import HRConfig from "../pages/HR/HRConfig";
import ExitInterviewRoutes from "./ExitInterviewRoutes";

export default function HRAdminRoutes() {

    return (
      <ReportProvider>
        <JDProvider>
          <Routes>
            <Route 
              path="" 
              element={<HRAdminHome />}>
                <Route 
                  index 
                  path="" 
                  element={<HRAdminDashboard />} 
                />
                <Route 
                  path="/users-management" 
                  element={<AdminUserManagementHome />} >

                  <Route 
                    index
                    path="" 
                    element={<AdminUserManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<AdminUserCreate />} 
                  />
                  {/* <Route 
                    path=":id/update" 
                    element={<AdminUserUpdate />}
                  /> */}
                </Route>
                <Route 
                  path="job-description/*" 
                  element={<JDRoutes />} 
                />
                <Route 
                    path="notifications" 
                    element={<NotificationPage />} 
                  />
                <Route 
                  path="user-management" 
                  element={<AdminUserManagementHome />} >

                  <Route 
                    index
                    path="" 
                    element={<AdminUserManagement />} 
                  />
                  <Route 
                    path="create" 
                    element={<AdminUserCreate />} 
                  />
                  {/* <Route 
                    path=":id/update" 
                    element={<AdminUserUpdate />}
                  /> */}
                </Route>
                <Route 
                  path="reports/*" 
                  element={<ReportRoutes />} 
                />
                <Route 
                  path="exit-interview/*" 
                  element={<ExitInterviewRoutes />} 
                />
                <Route 
                  path="profile" 
                  element={<HRProfile />} 
                />
                <Route 
                  path="support" 
                  element={<HRSupport />} 
                />
                <Route 
                  path="config" 
                  element={<HRConfig />} 
                />
                <Route 
                  path="setting" 
                  element={<HRSupport />} 
                />
                <Route 
                  path="*" 
                  element={<Navigate to="/notfound"/>}
                />
            </Route>
          </Routes>
        </JDProvider>
      </ReportProvider>
    );
  }