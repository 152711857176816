import React, { 
  createContext, 
  useState, 
  useContext, 
  useEffect 
} from 'react';
import { RESOLUTIONS } from '../utils/common';
import testAudio from './../assets/audios/test.mp3';
import { useSessionContext } from './SessionContext';
import { useMediaContext } from './InterviewMediaContext';
import LogService from '../services/log.service';
import ConsoleHelper from '../utils/consoleHelper';

const MediaTestContext = createContext();

// Create a provider component
export const MediaTestProvider = ({ children }) => {
  const {sessionInfo} = useSessionContext();
  const [cameraStatus, setCameraStatus] = useState('untested');
  const [speakerStatus, setSpeakerStatus] = useState('untested');
  const [microphoneStatus, setMicrophoneStatus] = useState('untested');
  const [screenSharingStatus, setScreenSharingStatus] = useState('untested');
  const {setDisplayStream, setAudioStream, setVideoStream} = useMediaContext();
  const token = sessionInfo?.token || localStorage.getItem('sessionToken');

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const micPermission = await navigator.permissions.query({ name: 'microphone' });
        const cameraPermission = await navigator.permissions.query({ name: 'camera' });

        setMicrophoneStatus(micPermission.state);
        setCameraStatus(cameraPermission.state)

        micPermission.onchange = () => {
          setMicrophoneStatus(micPermission.state);
        };

        cameraPermission.onchange = () => {
          setCameraStatus(cameraPermission.state);
        };
      } catch (error) {
        ConsoleHelper.error(`Error checking permissions: ${JSON.stringify(error)}`);
      }
    };

    checkPermissions();
  }, []);


  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream?.getAudioTracks().forEach(track => { track.enabled = false });
      setAudioStream(stream);
      // stream.getTracks().forEach(track => track.stop());
      setMicrophoneStatus('working');
    } catch (error) {
      setMicrophoneStatus('denied');
      ConsoleHelper.error(`Microphone test failed: ${JSON.stringify(error)}`);
    }
  };

  const requestCameraPermission = async () => {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: {
          frameRate: { 
            exact: sessionInfo?.config?.frameRate || 24 
          },
          width: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 
          },
          height: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 
          },
        }, 
        audio: false,
        playinline: true 
    });
    LogService.uploadLog(token, sessionInfo?.session_id, `requestCameraPermission: ${JSON.stringify(
      {
          frameRate: { 
            exact: sessionInfo?.config?.frameRate || 24 
          },
          width: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 
          },
          height: { 
            exact: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 
          },
        }, 
    )}`);
      setVideoStream(videoStream);
      // stream.getTracks().forEach(track => track.stop());
      setCameraStatus('working');
    } catch (error) {
      setCameraStatus('denied');
      ConsoleHelper.error(`Camera test failed: ${JSON.stringify(error)}`);

    }
  };

  const requestScreeningSharingPermission = async () => {
    try {
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
            displaySurface: "monitor",
            cursor: "always",
            frameRate: { ideal: sessionInfo?.config?.frameRate || 24 },
            width: { ideal: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.width || 1280 },
            height: { ideal: RESOLUTIONS.find((item) => item.value === sessionInfo?.config?.recordingResolution)?.height || 720 },
        },
        audio: false
      });
      setDisplayStream(displayStream);
      setScreenSharingStatus('working');
    } catch (error) {
      setScreenSharingStatus('denied');
      ConsoleHelper.error(`Screen sharing test failed: ${JSON.stringify(error)}`);
    }
  };

  const requestSpeakerPermission = () => {
    
    const audio = new Audio(testAudio);
    audio.onended = () => {
      setSpeakerStatus('working');
    };
    audio.onerror = () => {
      setSpeakerStatus('denied');
      ConsoleHelper.error('Speaker test failed');
    };
    audio.play();
  };

  const value = {
    cameraStatus,
    speakerStatus,
    microphoneStatus,
    screenSharingStatus,
    requestCameraPermission,
    requestSpeakerPermission,
    requestMicrophonePermission,
    requestScreeningSharingPermission,
  };

  return (
    <MediaTestContext.Provider value={value}>
      {children}
    </MediaTestContext.Provider>
  );
};

// Custom hook to use the context
export const useMediaTest = () => {
  const context = useContext(MediaTestContext);
  if (context === undefined) {
    throw new Error('useMediaTest must be used within a MediaTestProvider');
  }
  return context;
};