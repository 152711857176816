import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Rating } from "primereact/rating";
import logo from './../../assets/icons/white-logo.svg';
import { IoIosArrowForward } from "react-icons/io";
import { isChromeBrowser } from '../../utils/common';
import { InputTextarea } from 'primereact/inputtextarea';
import CommonService from '../../services/common.service';
import { useToast } from '../../contextProviders/ToastContext';
import { useSessionContext } from '../../contextProviders/SessionContext';
import ConsoleHelper from '../../utils/consoleHelper';

export default function InterviewDialog(){
    const [rating, setRating] = useState(0);
    const {showErrorMessage} = useToast();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const {state, sessionInfo, setState} = useSessionContext();

    const isSupported = isChromeBrowser();

    const visible = !isSupported ||  state === "EXPIRED" || state === "ALREADY_COMPLETED" || state === 'FEEDBACK_SUBMITTED';

    const getHeader = (isSupported, state) => {
        if (!isSupported) {
          return 'Unsupported Browser';
        }
        switch (state) {
          case 'EXPIRED':
            return 'Session Expired';
          case 'ALREADY_COMPLETED':
            return 'Session Already Completed';
          case 'FEEDBACK_SUBMITTED':
            return 'Feedback Submitted';
          default:
            return 'Welcome';
        }
    };

    const header = getHeader(isSupported, state);

    const submitSessionFeedback = async () => {
        try {
            setLoading(true);
            const requestData = {
                status: state,
                token: sessionInfo?.token,  
            };
            const feedback = {rating}
            if(message) feedback['message'] = message;

            requestData['feedback'] = feedback;

            const response = await CommonService.submitSessionFeedback(requestData);
            setLoading(false);
            if(response.status === 200)
                setState('FEEDBACK_SUBMITTED');
            
        } catch (error) {
            setLoading(false);
            ConsoleHelper.error(JSON.stringify(error));
            showErrorMessage({
                summary: 'Failed', 
                detail: 'Failed to submit feedback'
            })
        }
    }

    const render = () => {
        
        if(!isSupported)
            return <p>Please open the interview in Google Chrome.</p>
        if(state === 'EXPIRED')
            return <p>Your interview session has expired.</p>
        else if (state === 'ALREADY_COMPLETED')
            return <p>Your interview session has been successfully completed. Thank you for your time. You will be contacted soon with further details.</p>
        else if (state === 'FEEDBACK_SUBMITTED') 
            return (
                <div className='flex flex-col gap-3'>
                    <h1>Thank you for your feedback.</h1>
                </div>
            )
    }

    if(state === "EXITED"){
        return (
            <div className='flex flex-col items-center absolute z-40 w-full h-screen overflow-y-scroll'>
                <div className='xl:w-[80%] flex flex-col py-10 gap-5'>
                    <img 
                        src={logo}
                        alt='logo'
                        className='h-8 xl:h-10 ml-auto'
                    />
                    <div className='bg-white flex-1 rounded-xl justify-between flex flex-col p-10 px-20 mt-5'>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='font-semibold text-primary text-xl 2xl:text-2xl flex gap-2 flex-col items-center'>
                                <h2 className=''>Interview Incomplete 😦</h2>
                                {/* <h2 className=''>for completing the interview process!</h2> */}
                                {/* <span className='text-brownGray font-[400] text-sm'>It looks like you have exited the interview before completing it.</span> */}
                            </div>
                            <div className='border-t-[1px] border-b-[1px] border-[#EAECF0] py-6 flex w-full mt-10 flex-col text-sm 2xl:text-base'>
                                <p className='font-[400]'>It looks like you have exited the interview before completing it.</p>
                                <p className='font-[400]'>If you have any questions or need assistance, please contact us at support@athmick.com</p>
                            </div>
                            <div className='flex flex-col w-full gap-5 mt-5 2xl:mt-10'>
                                <div className='flex gap-2 items-center'>
                                    <div className='2xl:h-7 2xl:w-7 w-6 h-6 rounded-full bg-[#ECF5FA] justify-center items-center flex'>
                                        <IoIosArrowForward 
                                            size={window.screen.width > 1500 ? 18 : 14}
                                            color='#161616'
                                        />
                                    </div>
                                    <div className='flex gap-2 justify-center'>
                                        <h4 className='text-[#161616] font-semibold text-sm 2xl:text-base'>Feedback:</h4>
                                        <p className='text-brownGray text-sm'>We value your feedback. Please take a moment to share your interview experience</p>
                                    </div>
                                </div>
                                <InputTextarea 
                                    value={message}
                                    placeholder='Enter feedback'
                                    onChange={(e) => setMessage(e.target.value)}
                                    className='bg-[#F6F7F9] p-2 2xl:p-4 h-20 2xl:h-40 font-normal rounded-lg text-sm'
                                />
                            </div>
                            <div className='flex items-center py-6 w-full gap-2 mt-5 2xl:mt-10 border-t-[1px] border-b-[1px] border-[#EAECF0]'>
                                <div className='2xl:h-7 2xl:w-7 w-6 h-6 rounded-full bg-[#ECF5FA] justify-center items-center flex'>
                                    <IoIosArrowForward 
                                        size={window.screen.width > 1500 ? 18 : 14}
                                        color='#161616'
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <h4 className='text-[#161616] font-semibold text-sm 2xl:text-base'>Rate Your Experience:</h4>
                                    <Rating
                                        size={30} 
                                        value={rating}
                                        cancel={false}
                                        defaultChecked={0}
                                        className='flex justify-center text-primary'
                                        onChange={(e) => setRating(e.value)} 
                                        pt={{
                                            root: {className: 'flex gap-3'},
                                            onIcon: {className: 'text-do 2xl:h-6 2xl:w-6 w-5 h-5'},
                                            offIcon: {className: 'text-do 2xl:h-6 2xl:w-6 w-5 h-5'}
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <p className='text-brownGray py-7 font-[400]'>Thank you for considering a career with [Company Name]. We wish you the <span className='text-[#161616] font-semibold'>Best of luck</span> 👋 and look forward to potentially working with you!</p> */}
                        </div>
                    </div>
                    <button 
                        onClick={submitSessionFeedback}
                        className='h-9 2xl:h-12 bg-blue w-32 2xl:w-40 rounded text-white font-semibold text-xs 2xl:text-sm self-center mt-10'>
                        Submit
                    </button>
                </div>
            </div>
        )
    }

    else if(state === "COMPLETED"){
        return (
            <div className='flex flex-col items-center absolute z-40 w-full h-screen overflow-y-scroll'>
                <div className='xl:w-[80%] flex flex-col py-10 gap-2 2xl:gap-5'>
                    <img 
                        src={logo}
                        alt='logo'
                        className='h-8 xl:h-10 ml-auto'
                    />
                    <div className='bg-white flex-1 rounded-xl justify-between flex flex-col p-10 px-20 mt-5'>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='font-semibold text-primary text-xl 2xl:text-2xl flex gap-2 flex-col items-center'>
                                <h2 className=''>Thank you ✨</h2>
                                <h2 className=''>for completing the interview process!</h2>
                                <span className='text-brownGray font-[400] text-sm'>Your Interview is Successfully Submitted</span>
                            </div>
                            <div className='border-t-[1px] border-b-[1px] border-[#EAECF0] py-6 flex w-full mt-10 flex-col'>
                                <p className='font-[400] text-sm 2xl:text-base'>Our team will review the conversation and will contact you shortly.</p>
                            {/* <p className='font-[400]'>You shortly for the next steps in the hiring process</p> */}
                            </div>
                            <div className='flex flex-col w-full gap-5 mt-5 2xl:mt-10'>
                                <div className='flex gap-2 items-center'>
                                    <div className='2xl:h-7 2xl:w-7 w-6 h-6 rounded-full bg-[#ECF5FA] justify-center items-center flex'>
                                        <IoIosArrowForward 
                                            size={window.screen.width > 1500 ? 18 : 14}
                                            color='#161616'
                                        />
                                    </div>
                                    <div className='flex gap-2 justify-center'>
                                        <h4 className='text-[#161616] font-semibold text-sm 2xl:text-base'>Feedback:</h4>
                                        <p className='text-brownGray text-sm'>We value your feedback. Please take a moment to share your interview experience.</p>
                                    </div>
                                </div>
                                <InputTextarea 
                                    value={message}
                                    placeholder='Enter feedback'
                                    onChange={(e) => setMessage(e.target.value)}
                                    className='bg-[#F6F7F9] p-2 2xl:p-4 h-20 2xl:h-40 font-normal rounded-lg text-sm'
                                />
                            </div>
                            <div className='flex items-center py-6 w-full gap-5 mt-5 2xl:mt-10 border-t-[1px] border-b-[1px] border-[#EAECF0]'>
                                <div className='2xl:h-7 2xl:w-7 w-6 h-6 rounded-full bg-[#ECF5FA] justify-center items-center flex'>
                                    <IoIosArrowForward 
                                        size={window.screen.width > 1500 ? 18 : 14}
                                        color='#161616'
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <h4 className='text-[#161616] font-semibold text-sm 2xl:text-base'>Rate Your Experience:</h4>
                                    <Rating
                                        size={30} 
                                        value={rating}
                                        cancel={false}
                                        defaultChecked={0}
                                        className='flex justify-center text-primary'
                                        onChange={(e) => setRating(e.value)} 
                                        pt={{
                                            root: {className: 'flex gap-3'},
                                            onIcon: {className: 'text-do 2xl:h-6 2xl:w-6 w-5 h-5'},
                                            offIcon: {className: 'text-do 2xl:h-6 2xl:w-6 w-5 h-5'}
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <p className='text-brownGray py-7 font-[400]'>Thank you for considering a career with [Company Name]. We wish you the <span className='text-[#161616] font-semibold'>Best of luck</span> 👋 and look forward to potentially working with you!</p> */}
                        </div>
                    </div>
                    <button 
                        onClick={submitSessionFeedback}
                        className='h-9 2xl:h-12 bg-blue w-32 2xl:w-40 rounded text-white font-semibold text-xs 2xl:text-sm self-center mt-10'>
                        Submit
                    </button>
                </div>
            </div>
        )
    }

    return (
        <Dialog 
            visible={visible}
            closable={false}
            draggable={false}
            header={
                <h1 className='text-base 2xl:text-xl'>
                    {header}
                </h1>
            }
            className="w-[90%] md:w-[50%] 2xl:w-[30%]">
            <div className='text-sm 2xl:text-base'>{render()}</div>
        </Dialog>
    )
}