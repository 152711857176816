import moment from 'moment/moment';
import { MdDelete } from "react-icons/md";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataView } from 'primereact/dataview';
import logo from './../../../assets/icons/logo.svg';
import addIcon from './../../../assets/icons/add.svg';
import ConsoleHelper from '../../../utils/consoleHelper';
import PromptService from '../../../services/prompt.service';
import { getPaginationLimitForJD} from '../../../utils/common';
import { useToast } from '../../../contextProviders/ToastContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import { PROMPT_INTERVIEW_TYPES } from '../../../utils/constants';

export default function PromptManagement(){

    const navigate = useNavigate();
    const [first, setFirst] = useState(0);
    const [prompts, setPrompts] = useState();
    
    const [loading, setLoading] = useState(false);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const {showErrorMessage, showSuccessMessage} = useToast();
    const [isDeletingPrompt, setIsDeletingPrompt] = useState(false);

    const [rows, setRows] = useState(getPaginationLimitForJD());
    const [limit, setLimit] = useState(getPaginationLimitForJD());

    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const navigateTo = (path) => {
        navigate(path);
    }

    const deletePrompt = async (promptId) => {
        try {
            setIsDeletingPrompt(true);
            const response = await PromptService.deletePrompt(promptId);
            setIsDeletingPrompt(false);
            if(response.status === 200){
                const data = prompts?.filter((item => item?.id !== promptId));
                setPrompts(data);
                setShowConfirmationDialog(false);
                showSuccessMessage({
                    summary: 'Success', 
                    detail: "Deleted prompt"
                })
            }
        } catch (error) {
            setIsDeletingPrompt(false);
            setShowConfirmationDialog(false);
            showErrorMessage({
                summary: 'Failed', 
                detail: "Failed to delete prompt"
            })
        }
    }

    useEffect(() => {
        
        const handleResize = () => {
            setLimit(getPaginationLimitForJD())
            setRows(getPaginationLimitForJD())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        async function getPrompts(){
            try {
                setLoading(true);
                const response = await PromptService.getPrompts(currentPage, limit);
                setLoading(false);
                if(response.status === 200){
                    const {data, totalCount, totalPage} = response.data;
                    setPrompts(data)
                    setTotalCount(totalCount);
                    setTotalPage(totalPage);
                }
            } catch (error) {
                setLoading(false);
                ConsoleHelper.log(error?.response?.data?.message)
            }
        }
        getPrompts();
    }, [currentPage, limit]);

    const itemTemplate = (item) => {
        return (
            <div 
                key={item.id}
                className="w-full md:w-[30%] xl:w-[20%] bg-white hover:shadow-md rounded-md col-12 sm:col-6 lg:col-12 xl:col-4 relative cursor-pointer">
                <div className="surface-border surface-card border-round flex justify-between flex-col" onClick={navigateTo.bind(this, `/dashboard/prompts-management/${item?.id}/update`)}>
                    <div className="flex flex-col p-4 gap-2">
                        <h1 className="text-sm text-darkGray font-semibold line-clamp-2">{item?.title}</h1>
                        <p className="text-xs line-clamp-2">{item?.description}</p>
                        <span className="text-xs font-semibold mt-auto">{moment(item?.createdAt).format('DD-MMM-YYYY')}</span>  
                    </div>
                </div>
                <div 
                    onClick={() => {
                        setSelectedPrompt(item);
                        setShowConfirmationDialog(true);
                    }}
                    className='h-8 w-8 cursor-pointer flex justify-center items-center text-primary bg-white absolute -top-3 -right-3 rounded-full shadow-md drop-shadow-md'>
                    <MdDelete size={20}/>
                </div>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((item, index) => {
            return itemTemplate(item, index);
        });

        return <div className="flex gap-10 flex-wrap bg-lightBlue">{list}</div>;
    };

    function getLabelByValue(value) {
        const found = PROMPT_INTERVIEW_TYPES.find(item => item.value === value);
        return found ? found.label : null;
    }
    
    return (
        <div 
            className="flex flex-col h-full gap-4 p-5">
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {isDeletingPrompt && <LoadingComponent/>}
                    <div className={`${isDeletingPrompt? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <p className="text-brownGray text-base">Would you like to delete this prompt?</p>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button  
                                onClick={() => setShowConfirmationDialog(false)}
                                className="h-10 border border-primary w-32 font-medium">
                                NO
                            </button>
                            <button
                                onClick={deletePrompt.bind(this, selectedPrompt?.id)} 
                                className="bg-primary h-10 rounded w-32 text-white font-medium">
                                YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <h1 className='text-[#161616] text-xl font-bold'>Prompt Management</h1>
                <div 
                    onClick={navigate.bind(this, '/dashboard/prompts-management/create')}
                    className="bg-blue cursor-pointer text-white px-4 h-10 rounded font-semibold text-sm flex items-center gap-4">
                    <span>Create Prompt</span>
                    <img 
                        alt='add'
                        src={addIcon}
                        className='h-5 w-5'
                    />
                </div>
            </div>
            <div className={`mt-2 p-2 flex flex-col gap-10 ${!loading && prompts && Object.keys(prompts || {}).length > 0 ? 'visible': 'hidden'}`}>
                {prompts && Object.keys(prompts).length > 0 && Object.keys(prompts)?.map((item) => (
                    <div className='flex flex-col gap-2'>
                        <h2 className={`text-base font-medium ${ prompts[item]?.length > 0 ? 'visible': 'hidden'}`}>
                            {getLabelByValue(item)}
                        </h2>
                        <DataView 
                            value={prompts[item]}  
                            className={`${ prompts[item]?.length > 0 ? 'visible': 'hidden'}`}
                            listTemplate={listTemplate}>
                        </DataView>
                    </div>
                ))}
            </div>
            <div className={`mt-2 p-2 m-auto h-full w-full flex justify-center items-center ${loading ? 'visible': 'hidden'}`}>
                <LoadingComponent />
            </div>

            <div className={`flex justify-center items-center ${!loading && Object.keys(prompts || {}).length === 0 ? 'visible': 'hidden'}`}>
                <span>No prompts available</span>
            </div>
        </div>
    )
}