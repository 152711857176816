import axiosInstance from ".";

function getJDResumes(jdId, page=1, limit=10, stage){
    let query = `page=${page}&limit=${limit}`
    if(stage) query += `&stage=${stage}`
    return axiosInstance.get(`/jds/${jdId}/resumes?${query}`)
}

function getResumes(page, limit){
    // if(page && limit)
    //     return axiosInstance.get(`/resumes?page=${page}&limit=${limit}`)
    return axiosInstance.get(`/resumes`);
}

function updateResume(resumeId, data){
    return axiosInstance.put(`/resumes/${resumeId}`, data)
}

function uploadResumes(data){
    const requestData = {};
    if(data['urls'])
        requestData['urls'] = data?.urls;
    else if (data['resumeIds'])
        requestData['resumeIds'] = data?.resumeIds;

    return axiosInstance.post(`/jds/${data?.jdId}/resumes`, requestData)
}

function sortlistResumes(jdId, resumeIds){
    return axiosInstance.post(`/jds/${jdId}/sortlist-resumes`, { resumeIds})
}

function getRecordings(resumeId){
    return axiosInstance.get(`/resumes/${resumeId}/recordings`,)
}

function getResumesByIds(ids){
    return axiosInstance.get(`/resumes/batch?ids=${ids?.join(",")}`,)
}

const ResumeService = {
    updateResume,
    getResumes,
    getRecordings,
    getJDResumes,
    uploadResumes,
    sortlistResumes,
    getResumesByIds
}

export default ResumeService;