import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { PiVideoConferenceBold } from 'react-icons/pi';
import { TbReportSearch } from 'react-icons/tb';
import flagIcon from './../../assets/icons/flag.svg';
import codeIcon from './../../assets/icons/code.svg';
import { RESUME_STAGES } from '../../utils/constants';
import { useToast } from '../../contextProviders/ToastContext';

function ActionDropdown({ isBelongsToAlorica, resumes, menuVisibility, onInviteDropdownClick, config, sendInterviewInviteAll, sendInterviewInvites, selectedResumes, handleMenuVisibility }) {
    const { showErrorMessage } = useToast();

    return (
        <div
            onClick={onInviteDropdownClick}
            className={`bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer ${resumes?.length ? 'visible' : 'hidden'}`}
        >
            <span className="text-sm">Take next action</span>
            {menuVisibility ? <MdOutlineKeyboardArrowUp size={22} /> : <MdOutlineKeyboardArrowDown size={22} />}

            {menuVisibility && (
                <div
                    onClick={(event) => event.stopPropagation()}
                    className="rounded-md absolute shadow-lg right-0 bg-white"
                >
                    <ul className="p-2 gap-2 flex flex-col w-48 text-sm">
                        {config?.enabledServices?.['videoProfiling'] && (
                            <li
                                className="py-2 text-[#3C99CA] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer"
                                onClick={() => {
                                    if (selectedResumes?.length > 0) {
                                        sendInterviewInviteAll(RESUME_STAGES.VIDEO_PROFILING);
                                    } else {
                                        showErrorMessage({ summary: 'Required', detail: 'Please select resumes' });
                                    }
                                    handleMenuVisibility(false);
                                }}
                                style={{ backgroundColor: 'rgba(60, 153, 202, .1)' }}
                            >
                                <PiVideoConferenceBold size={16} />
                                <h4 className="text-sm">Video Profiling</h4>
                            </li>
                        )}
                        {config?.enabledServices?.['resumeScreening'] && (
                            <li
                                className="py-2 text-[#FF3B30] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer"
                                onClick={() => sendInterviewInvites(RESUME_STAGES.RESUME_SCREENING)}
                                style={{ backgroundColor: 'rgba(255, 59, 48, .1)' }}
                            >
                                <TbReportSearch size={16} />
                                <h4 className="text-sm">Resume Screening</h4>
                            </li>
                        )}
                        {config?.enabledServices?.['hrScreening'] && (
                            <li
                                style={{ backgroundColor: 'rgba(255, 149, 0, .1)' }}
                                onClick={() => sendInterviewInvites(RESUME_STAGES.INITIAL_SCREENING)}
                                className={`text-[#FF9500] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer`}
                            >
                                <img alt="flag-icon" src={flagIcon} className="h-4 w-4" />
                                <span>Send Initial Invite</span>
                            </li>
                        )}
                        {config?.enabledServices?.['bpoHrScreening'] && (
                            <li
                                style={{ backgroundColor: 'rgba(255, 149, 0, .1)' }}
                                onClick={() => sendInterviewInvites(RESUME_STAGES.BPO_HR_SCREENING)}
                                className={`text-[#FF9500] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer`}
                            >
                                <img alt="flag-icon" src={flagIcon} className="h-4 w-4" />
                                <span>Send Initial Invite</span>
                            </li>
                        )}
                        {config?.enabledServices?.['technicalScreening'] && !isBelongsToAlorica && (
                            <li
                                style={{ backgroundColor: 'rgba(22, 93, 255, .1)' }}
                                onClick={() => sendInterviewInvites(RESUME_STAGES.TECHNICAL_SCREENING)}
                                className="text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer"
                            >
                                <img alt="code-icon" src={codeIcon} className="h-4 w-4" />
                                <span>Technical Invite</span>
                            </li>
                        )}
                        {config?.enabledServices?.['opsScreening'] && isBelongsToAlorica && (
                            <li
                                style={{ backgroundColor: 'rgba(22, 93, 255, .1)' }}
                                onClick={() => sendInterviewInvites(RESUME_STAGES.OPS_SCREENING)}
                                className="text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer"
                            >
                                <img alt="code-icon" src={codeIcon} className="h-4 w-4" />
                                <span>OPS Invite</span>
                            </li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default ActionDropdown;
