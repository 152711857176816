import React from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import GaugeChart from 'react-gauge-chart'
import { Column } from "primereact/column";
import { Tooltip } from 'primereact/tooltip';
import { FiPlusCircle } from "react-icons/fi";
import { InputText } from 'primereact/inputtext';
import { TbReportSearch } from "react-icons/tb";
import { AiOutlineUpload } from "react-icons/ai";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { FaRegFileLines } from "react-icons/fa6";
import { CgFileDocument } from "react-icons/cg";
import { useEffect, useRef, useState } from "react";
import JDService from "../../../services/jd.service";
import { PiVideoConferenceBold } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConsoleHelper from '../../../utils/consoleHelper';
import flagIcon from './../../../assets/icons/flag.svg';
import codeIcon from './../../../assets/icons/code.svg';
import { formatLabel, getPaginationLimit, isValidEmail } from "../../../utils/common";
import { useLocation, useNavigate } from "react-router-dom";
import ResumeService from "../../../services/resume.service";
import { useAuth } from '../../../contextProviders/AuthContext';
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useToast } from "../../../contextProviders/ToastContext";
import { RESUME_STAGES, RESUME_STATUS } from "../../../utils/constants";
import ResumeViewerPopup from '../../../components/JD/ResumeViewerPopup';
import AddResumeLessPopup from "../../../components/Resume/AddResumeLessPopup";
import ResumesUploadPopup from "../../../components/Resume/ResumesUploadPopup";
import ResumeSelectPopup from '../../../components/Resume/ResumesSelectPopup';
import { useConfig } from '../../../contextProviders/ConfigContext';
import ActionDropdown from '../../../components/JD/ActionDropdown';

const REFRESH_INTERVAL = 3000;

const JDDetailsResumes = React.memo((props) => {
    const {user} = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const intervalResumeInQueueRef = useRef(null);
    const intervalResumeShortListRef = useRef(null);

    const [first, setFirst] = useState(0);
    const [resumeUrl, setResumeUrl] = useState(0);
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const {menuVisibility, handleMenuVisibility} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(getPaginationLimit());
    const {showErrorMessage, showSuccessMessage} = useToast();
    const [selectedResumes, setSelectedResumes] = useState(null);

    const [expandedRows, setExpandedRows] = useState(null);

    const [rows, setRows] = useState(getPaginationLimit());
    const [selectedResume, setSelectedResume] = useState(null);

    const jdId = pathname.replace('/dashboard/job-description/', '');

    const [showResumesSelectPopup, setShowResumesSelectPopup] = useState(false);
    const [showResumelessPopup, setShowResumelessPopup] = useState(false);
    const [showResumesUploadPopup, setShowResumesUploadPopup] = useState(false);

    const [showResumePopup, setShowResumePopup] = useState(false);

    const onInviteDropdownClick = (event) => {
        event.stopPropagation();
        setSelectedResume(null);
        handleMenuVisibility(true);
    }

    useEffect(() => {
        const handleResize = () => {
            setLimit(getPaginationLimit())
            setRows(getPaginationLimit())
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const refreshQueuedResumes = useCallback(async () => {
        try {
            const queuedResumeIds = resumes
                .filter(item => item?.stage === RESUME_STAGES.IN_QUEUE)
                .map(item => item?._id);

            if (queuedResumeIds.length === 0) return;
    
            const response = await ResumeService.getResumesByIds(queuedResumeIds);
            if (response.status === 200) {
                    const updatedResumes = response?.data?.data || [];
                    setResumes(prev => prev.map(resume => {
                    const data = updatedResumes.find(r => r._id === resume._id);
                    const isUpdated = resume?.stage !== data?.stage;
                    return isUpdated ? { ...resume, ...data } : resume;
                }));
        
                const hasQueuedResumes = updatedResumes.some(resume => resume?.stage === RESUME_STAGES.IN_QUEUE);
                if (!hasQueuedResumes && intervalResumeInQueueRef.current) {
                    clearInterval(intervalResumeInQueueRef.current);
                    intervalResumeInQueueRef.current = null;
                    ConsoleHelper.log('All resumes processed. Stopping auto-refresh.');
                }
            }
        } catch (error) {
            ConsoleHelper.error(`Error refreshing queued resumes: ${JSON.stringify(error)}`);
        }
    }, [resumes]);

    const refreshShortlistInProgressResumes = useCallback(async () => {
        try {
            const resumeIds = resumes
                .filter(item => item?.status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS)
                .map(item => item?._id);

            if (resumeIds.length === 0) return;
    
            const response = await ResumeService.getResumesByIds(resumeIds);
            if (response.status === 200) {
                const updatedResumes = response?.data?.data || [];
                setResumes(prev => prev.map(resume => {
                    const data = updatedResumes.find(r => r._id === resume._id);
                    const isUpdated = resume?.status !== data?.status;
                    return isUpdated ? { ...resume, ...data } : resume;
                }));
        
                const hasInProgressResumes = updatedResumes.some(resume => resume?.status === RESUME_STATUS.SHORTLISTING_IN_PROGRESS);
                if (!hasInProgressResumes && intervalResumeShortListRef.current) {
                    clearInterval(intervalResumeShortListRef.current);
                    intervalResumeShortListRef.current = null;
                    ConsoleHelper.log('Stopping auto-refresh.');
                }
            }
        } catch (error) {
            ConsoleHelper.error(`Error refreshing in progress resumes: ${JSON.stringify(error)}`);
        }
    }, [resumes]);

    const getResumes = async () => {
        try {
            setLoading(true);
            const response = await ResumeService.getJDResumes(jdId, currentPage, limit);
            setLoading(false);
            if(response.status === 200){
                const {data, totalCount} = response.data || []; 
                const list = data?.map((item) => ({...item, selected: false}))
                setResumes(list);
                setTotalCount(totalCount);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getResumes();
    }, [currentPage, limit]);

    useEffect(() => {
        intervalResumeInQueueRef.current = setInterval(refreshQueuedResumes, REFRESH_INTERVAL);
        return () => {
          if (intervalResumeInQueueRef.current) {
            clearInterval(intervalResumeInQueueRef.current);
          }
        };
    }, [refreshQueuedResumes]);

    useEffect(() => {
        intervalResumeShortListRef.current = setInterval(refreshShortlistInProgressResumes, REFRESH_INTERVAL);
        return () => {
          if (intervalResumeShortListRef.current) {
            clearInterval(intervalResumeShortListRef.current);
          }
        };
    }, [refreshShortlistInProgressResumes]);

    function isBelongsToAlorica() {
        return user?.email?.includes('alorica') ? true: false
    }

    const reload =  async () => {
        setShowResumesSelectPopup(false)
        await getResumes();
    }

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        setCurrentPage(event.page + 1);
    };

    const resumeScreening = async () => {
        try {
            setLoading(true);
            const resumeIds = selectedResumes?.map((item) => item?._id)
            const response = await ResumeService.sortlistResumes(jdId, resumeIds);
            setLoading(false);
            if(response.status === 200){
                setSelectedResumes(null);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }

    const onRowEditComplete = async (e) => {
        try {
            let data = [...resumes];
            let { newData, index } = e;
            const email = newData['email'];
            const name = newData['name'];

            const meta = {...newData.meta};
            if(name) meta['Name'] = name;
            if(email) meta['Email'] = email;

            const requestData = {};
            if(name) requestData['name'] = name;
            if(email) requestData['email'] = email?.trim();
            if(Object.keys(requestData).length){
                const response = await ResumeService.updateResume(newData?._id, requestData);
                if(response.status === 200){              
                    data[index] = { ...newData, meta: meta };
                    setResumes(data);
                    showSuccessMessage({ summary: 'Updated', detail: "Updated resume" })
                }
            }
        } catch (error) {
            showErrorMessage({ summary: "Failed", detail: 'Failed to update resume'})
        }
    };

    function getResumeStatus({stage, status, resumeScreening}) {
        const { IN_QUEUE, RESUME_RECEIVED, RESUME_SCREENING } = RESUME_STAGES;
        const { SHORTLISTING_IN_PROGRESS, FAILED } = RESUME_STATUS;
    
        if (
            stage === IN_QUEUE ||
            stage === RESUME_RECEIVED ||
            (stage === RESUME_SCREENING && status === SHORTLISTING_IN_PROGRESS)
        ) {
            return <span>NA</span>;
        } else if (
            stage === RESUME_SCREENING &&
            (status === FAILED || resumeScreening?.status === FAILED)
        ) {
            return <span>NA</span>;
        }
    
        return status;
    }

    const sendInterviewInviteAll = async (interviewType) => {
        try {
            setLoading(true);
            const response = await JDService.sendInterviewInvite(jdId, {
                interviewType: interviewType,
                resumeIds: selectedResumes?.map((item) => item?._id)
            })
            setLoading(false);
            if(response.status === 200){
                let message = 'Interview Invite sent';
                if(interviewType === RESUME_STAGES.INITIAL_SCREENING || interviewType === RESUME_STAGES.BPO_HR_SCREENING)
                    message = 'Initial Interview invite sent';
                else if(interviewType === RESUME_STAGES.OPS_SCREENING)
                    message = 'OPS Interview invite sent';
                else if(interviewType === RESUME_STAGES.TECHNICAL_SCREENING)
                    message = 'Technical Interview invite sent';
                else if(interviewType === RESUME_STAGES.VIDEO_PROFILING)
                    message = 'Video Profiling invite sent';

                showSuccessMessage({
                    summary: 'Invites sent', 
                    detail: message
                });
                setSelectedResumes(null);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: "Failed", detail: 'Failed to sent invite'})
        }
    }

    const sortlistResume = async (resumeId) => {
        try {
            setLoading(true);
            const response = await ResumeService.sortlistResumes(jdId, [resumeId]);
            setLoading(false);
            setSelectedResume(null);
            if(response.status === 200) {
                await getResumes();
                setSelectedResume(null);
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message })
        }
    }

    const sendInterviewInvite = async (resumeId, meta, interviewType) => {
        try {
            if(!meta['Email'] || !isValidEmail(meta['Email']?.trim())){
                showErrorMessage({summary: 'Required', detail: 'Email is required'})
                setSelectedResume(null);
                return;
            }
            setLoading(true);
            const response = await JDService.sendInterviewInvite(jdId, {
                resumeIds: [resumeId],
                interviewType: interviewType,
            }) 
            setLoading(false);
            if(response.status === 200){
                let message = 'Interview Invite sent';
                if(interviewType === RESUME_STAGES.INITIAL_SCREENING || interviewType === RESUME_STAGES.BPO_HR_SCREENING)
                    message = 'Initial Interview invite sent';
                else if(interviewType === RESUME_STAGES.OPS_SCREENING)
                    message = 'OPS Interview invite sent';
                else if(interviewType === RESUME_STAGES.TECHNICAL_SCREENING)
                    message = 'Technical Interview invite sent';
                else if(interviewType === RESUME_STAGES.VIDEO_PROFILING)
                    message = 'Video Profiling invite sent';


                showSuccessMessage({
                    summary: 'Invite Sent', 
                    detail: message
                })
                setSelectedResume(null);
                await getResumes();
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({ summary: "Failed", detail: 'Failed to sent invite'})
        }
    }

    const allowEdit = (rowData) => {
        return rowData?.stage !== RESUME_STAGES.IN_QUEUE;
    };

    const textEditor = (options) => {
        const data = options?.rowData?.meta;
        if(!data) return;

        let defaultValue = null;
        if(options.field === 'name')
            defaultValue = data['Name'];
        else if(options.field === 'email')
            defaultValue = data['Email'];
        return (
            <InputText 
                type="text" 
                value={options?.value} 
                defaultValue={defaultValue}
                className='border border-primary px-1 h-10 max-w-40'
                onChange={(e) => options.editorCallback(e.target.value)} 
            />
        )
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const renderEmail = ({meta, _id}) => {
        const dynamicClass = `tooltip-${_id}`;
        const email = meta?.['Email'] || meta?.['email'];

        if(email){
            return (
                <div className="flex gap-4 items-center w-32" onClick={handleCopy.bind(this, email)}>
                    <Tooltip target={`.${dynamicClass}`} position="bottom">
                        <div className='text-sm font-semibold flex flex-col justify-center items-center gap-1'>
                            <h3 className='text-xs'>{email}</h3>
                            <span className='text-xs text-blue'>Click on email to copy</span>
                        </div>
                    </Tooltip>
                    <span className={`text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ${dynamicClass}`}>{email}</span>
                </div>
            )
        }

        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    Not Available
                </span>
            </div>
        )
    }

    const renderStage = ({stage}) => {

        return (
            <div className="flex gap-4 items-center w-32">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap font-medium cursor-pointer'>
                    {formatLabel(stage)}
                </span>
            </div>
        )
    }

    const renderName = ({meta}) => {
        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {(meta?.['Name'] || meta?.['name']) ?meta?.['Name'] || meta?.['name'] : 'Not Available'}
                </span>
            </div>
        )
    }

    
    const getStatus = (props) => {
        const {initialScreening, stage, status, technicalScreening, opsScreening, resumeScreening } = props;

        if (
            stage === RESUME_STAGES.RESUME_SCREENING &&
            (status === RESUME_STATUS.FAILED || resumeScreening?.status === RESUME_STATUS.FAILED)
        ) {
            return 'RETRY';
        }
    
        switch (stage) {
            case RESUME_STAGES.INITIAL_SCREENING:
                return initialScreening?.status;
            case RESUME_STAGES.BPO_HR_SCREENING:
                return initialScreening?.status;
            case RESUME_STAGES.TECHNICAL_SCREENING:
                return technicalScreening?.status;
            case RESUME_STAGES.OPS_SCREENING:
                return opsScreening?.status;
            default:
                return status;
        }
    };
    

    const renderStatus = (props) => {
        return (
            <div className="flex gap-4 items-center w-28">
                <span className='text-ellipsis font-medium overflow-hidden whitespace-nowrap'>
                    {formatLabel(getStatus(props))}
                </span>
            </div>
        )
    }

    const showResume = (item) => {
        if(item['resumeUrl'])
            setResumeUrl(item['resumeUrl']);
        setShowResumePopup(true);
    }

    const sendInterviewInvites = async (type) => {
        if(selectedResumes?.length > 0)
            if(type === RESUME_STAGES.RESUME_SCREENING)
                await resumeScreening();
            else await sendInterviewInviteAll(type);
        else showErrorMessage({summary: 'Required', detail: 'Please select resumes'})
        handleMenuVisibility(false);
    }

    const viewResume = (item) => {
        return (
            <div 
                onClick={(event) => {
                    event?.stopPropagation();
                    handleMenuVisibility(false);
                    setSelectedResume(null)
                }}
                className="flex gap-4 items-center w-10">
                <CgFileDocument 
                    size={20}
                    onClick={showResume.bind(this, item)}
                    className='cursor-pointer text-primary'
                />
            </div>
        )
    }

    const renderUploadedBy = ({userDetails}) => {
        return (
            <div className="flex gap-4 text-sm items-center">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {!userDetails ? 'NA' : user?.['_id'] === userDetails?.['_id'] ?  'Self' : (userDetails?.['firstName'])}
                </span>
            </div>
        )
    }

    const renderScore = useCallback(({resumeScreening, stage, status}) => {
        if(stage === RESUME_STAGES.RESUME_RECEIVED || stage === RESUME_STAGES.IN_QUEUE)
            return <span>Pending</span>;
            
        // const resumeStatus = getResumeStatus({stage, status, resumeScreening});
        // if(resumeStatus === 'NA') return resumeStatus;

        return (
            <div className="flex items-center gap-5">
                <div className="flex flex-col items-center justify-center gap-2">
                    <GaugeChart 
                        id="gauge-chart1" 
                        nrOfLevels={4}
                        hideText={true}
                        style={{ width: 40 }}
                        colors={["#FF3B30", "#FF9500", '#FED000', '#4DC284']} 
                        percent={parseFloat(resumeScreening?.['meta']?.['score'] || 0).toFixed(1) / 100} 
                    />
                    <span className="text-sm">{parseFloat(resumeScreening?.['meta']?.['score'] || 0 ).toFixed(1)}%</span>
                </div>
                {/* <div 
                    onClick={() => navigate(`/dashboard/reports/${_id}`)}
                    className="h-8 w-8 rounded-full justify-center items-center flex cursor-pointer" 
                    style={{backgroundColor: 'rgba(60, 153, 202, 0.1)'}}>
                    <FaRegFileLines size={16} color="#3C99CA" />
                </div> */}
            </div>
        )
    }, []);

    const options = (item) => {
        return (
            <div 
                onClick={(event) => {
                    event?.stopPropagation();
                    handleMenuVisibility(false);
                }}
                className={`absolute z-50 transform -translate-x-1/2 -translate-y-1/2 ${props.jd?.active === false ? 'hidden': 'visible'}`}>
                <div className={`${selectedResume?._id === item?._id ? 'visible' : 'hidden'} rounded-lg  shadow-gray shadow bg-white z-50 -top-12 right-0 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col w-48 text-sm">
                        {config?.enabledServices?.['videoProfiling'] && <li 
                            className='py-2 text-[#3C99CA] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer'
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.VIDEO_PROFILING)}
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}} >
                                <PiVideoConferenceBold size={16}/>
                                <h4 className='text-sm'>Video Profiling</h4>
                        </li>}
                        {config?.enabledServices?.['resumeScreening'] && <li 
                            className='py-2 text-[#FF3B30] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer'
                            onClick={sortlistResume.bind(this, item?._id)} 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                                <TbReportSearch size={16}/>
                                <h4 className='text-sm'>Resume Screening</h4>
                        </li>}
                        {config?.enabledServices?.['hrScreening'] && <li 
                            style={{backgroundColor: 'rgba(255, 149, 0, .1)'}}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.INITIAL_SCREENING)}
                            className={`
                                text-[#FF9500] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED) ? 'hidden' : 'visible'}
                            `}>
                                <img alt="flag-icon" src={flagIcon} className="h-4 w-4" />
                                <span>Send Initial Invite</span>
                        </li>}
                        {config?.enabledServices?.['bpoHrScreening'] && <li 
                            style={{backgroundColor: 'rgba(255, 149, 0, .1)'}}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.BPO_HR_SCREENING)}
                            className={`
                                text-[#FF9500] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED) ? 'hidden' : 'visible'}
                            `}>
                                <img alt="flag-icon" src={flagIcon} className="h-4 w-4" />
                                <span>Send Initial Invite</span>
                        </li>}
                        {config?.enabledServices?.['technicalScreening']  && <li 
                            style={{backgroundColor: 'rgba(22, 93, 255, .1)'}}
                            className={`
                                text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED || isBelongsToAlorica()) ? 'hidden' : 'visible'}
                            `}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.TECHNICAL_SCREENING)} >
                                <img alt="code-icon" src={codeIcon} className="h-4 w-4"/>
                                <span>Technical Invite</span>
                        </li>}
                        {config?.enabledServices?.['opsScreening']  && <li 
                            style={{backgroundColor: 'rgba(22, 93, 255, .1)'}}
                            onClick={sendInterviewInvite.bind(this, item?._id, item?.meta, RESUME_STAGES.OPS_SCREENING)}
                            className={`text-[#165DFF] px-2 flex gap-2 rounded-md h-8 items-center cursor-pointer
                                ${(selectedResume?.stage === RESUME_STAGES.IN_QUEUE || selectedResume?.stage === RESUME_STAGES.RESUME_RECEIVED || !isBelongsToAlorica()) ? 'hidden' : 'visible'}
                            `}>
                            <img alt="code-icon" src={codeIcon} className="h-4 w-4"/>
                            <span>OPS Invite</span>
                        </li>}
                    </ul>
                </div>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className={`cursor-pointer z-0 ${item?.stage === RESUME_STAGES.IN_QUEUE ? 'hidden': 'visible'}`}
                    onClick={() => setSelectedResume(item)}
                />
            </div>
        )
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div className="flex gap-7 justify-center">
                <div className={`flex items-center ${config?.enabledServices?.['videoProfiling'] ? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Video Profiling:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.videoProfiling?.status || 'NA'}</span>
                </div>
                <div className={`flex items-center ${config?.enabledServices?.['resumeScreening'] ? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Resume Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.resumeScreening?.status || 'NA'}</span>
                </div>
                <div className={`flex items-center ${config?.enabledServices?.['hrScreening'] || config?.enabledServices?.['bpoHrScreening'] ? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Initial Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>{data?.initialScreening?.status || 'NA'}</span>
                </div>
                <div className={`flex items-center ${(config?.enabledServices?.['technicalScreening'] ) ? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Technical Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>
                        {data?.technicalScreening?.status || 'NA'}
                    </span>
                </div>
                <div className={`flex items-center ${(config?.enabledServices?.['opsScreening'])? 'visible': 'hidden'}`}>
                    <h2 className='text-sm font-normal text-brownGray'>Ops Screening:</h2>
                    <span className='ml-2 font-semibold text-sm'>
                        {data?.opsScreening?.status || 'NA'}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <section
            className='flex-1 mt-5' 
            onClick={() => {
                handleMenuVisibility(false);
                setSelectedResume(null);
            }}>
            {showResumePopup && (
                <ResumeViewerPopup 
                    resumeUrl={resumeUrl}
                    header="Resume"
                    close={() => setShowResumePopup(false)}
                />
            )}
            { showResumesSelectPopup && (
                <ResumeSelectPopup 
                    reload={reload}
                    hide={() => setShowResumesSelectPopup(false)}
                />
            )}
            { showResumelessPopup && (
                <AddResumeLessPopup 
                    hide={() => setShowResumelessPopup(false)}
                />
            )}
            { showResumesUploadPopup &&  (
                <ResumesUploadPopup 
                    getResumes={getResumes} 
                    hide={() => setShowResumesUploadPopup(false)} 
                />
            )}
            <div className="flex justify-between items-center relative z-30">
                <h2 className="font-semibold text-xl text-darkGray">Resumes</h2>
                <div className={`flex items-center gap-5 text-brownGray ${props.jd?.active === false ? 'hidden': 'visible'}`}>
                    {/* <button 
                        onClick={() => getResumes()}
                        className="bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer">
                        <span className="text-sm">Refresh</span>
                        <MdRefresh size={20} />
                    </button> */}
                    <button 
                        onClick={() => setShowResumesSelectPopup(true)}
                        className="bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer">
                        <span className="text-sm">Select Resumes</span>
                        {/* <MdRefresh size={20} /> */}
                    </button>
                    <button 
                        onClick={() => setShowResumesUploadPopup(true)}
                        className="bg-white h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer">
                        <span className="text-sm">Upload resume</span>
                        <AiOutlineUpload size={20} />
                    </button>
                    <button
                        // onClick={() => {setShowResumelessPopup(true)}}
                        className="bg-white opacity-40 h-10 justify-center flex items-center px-4 rounded-md gap-3 cursor-pointer add-resume">
                        <span className="text-sm">Add resume</span>
                        <FiPlusCircle size={20} />
                    </button>
                    <Tooltip 
                        target='.add-resume'
                        position="bottom">
                        <span className='text-sm font-semibold'>For resume less only</span>
                    </Tooltip>
                    <ActionDropdown
                        config={config}
                        resumes={resumes}
                        menuVisibility={menuVisibility}
                        selectedResumes={selectedResumes}
                        isBelongsToAlorica={isBelongsToAlorica()}
                        onInviteDropdownClick={onInviteDropdownClick}
                        sendInterviewInviteAll={sendInterviewInviteAll}
                        sendInterviewInvites={sendInterviewInvites}
                        handleMenuVisibility={handleMenuVisibility}
                    
                    />
                </div>
            </div>
            <div className="bg-white rounded mt-5">
                <DataTable
                    dataKey="_id"
                    editMode="row" 
                    value={resumes}
                    loading={loading}
                    selectionMode='checkbox'
                    expandedRows={expandedRows} 
                    className="text-brownGray"
                    selection={selectedResumes}
                    emptyMessage="No resume available"
                    onRowEditComplete={onRowEditComplete}  
                    rowExpansionTemplate={rowExpansionTemplate}
                    onSelectionChange={(e) => setSelectedResumes(e.value)}
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                    onRowClick={({data}) => {
                        if(data['stage'] === RESUME_STAGES.IN_QUEUE || data['stage'] === RESUME_STAGES.RESUME_RECEIVED) 
                            return;

                        navigate(`/dashboard/reports/${data?.['_id']}`);
                    }}>
                    <Column 
                        selectionMode="multiple" 
                        headerClassName='max-w-5'
                        className='max-w-10'
                    />
                    <Column 
                        body={viewResume}  
                        className='w-10 p-0 pl-3'
                        headerClassName='text-sm 2xl:text-base' 
                    />
                    <Column 
                        expander={true} 
                        className='max-w-10 px-0 z-50' 
                        body={(item) => (
                            <div
                                onClick={(event) => {
                                    event?.stopPropagation();
                                    handleMenuVisibility(false);
                                    setSelectedResume(null)
                                }}>
                                <IoIosArrowForward
                                    onClick={() => setExpandedRows({[item?._id]: true})} 
                                    className={`check-prior-status cursor-pointer ${expandedRows?.[item?._id] ? 'hidden': 'visible'}`}
                                />
                                <IoIosArrowDown
                                    onClick={() => setExpandedRows(null)} 
                                    className={`check-prior-status cursor-pointer ${expandedRows?.[item?._id] ? 'visible': 'hidden'}`}
                                />
                                 <Tooltip 
                                    target='.check-prior-status'
                                    position="right">
                                    <span className='text-sm font-semibold'>Check Prior Status</span>
                                </Tooltip>
                            </div>
                        )}
                    />
                    <Column  
                        header="Name" 
                        field="name" 
                        body={renderName}
                        className='text-sm 2xl:text-base'
                        editor={(options) => textEditor(options)}
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Email" 
                        field="email" 
                        body={renderEmail}
                        className='text-sm 2xl:text-base'
                        editor={(options) => textEditor(options)}
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        header="Mobile number" 
                        body={renderMobileNumber}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    /> */}
                    <Column 
                        header="Stage" 
                        body={renderStage}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        header="Score"
                        body={renderScore} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {user?.['role'] === 'hrAdmin' && (
                        <Column 
                            header="Uploaded By"
                            body={renderUploadedBy}
                            className='text-sm 2xl:text-base text-center'
                            headerClassName='text-sm 2xl:text-base bg-white text-center' 
                        />
                    )}
                    <Column 
                        field="status" 
                        header="Status"
                        body={renderStatus}  
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        rowEditor={allowEdit} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white'>
                    </Column>
                </DataTable>
            </div>
            <Paginator 
                rows={rows}
                first={first}
                totalRecords={totalCount}
                onPageChange={onPageChange}
                className={`bg-[#ECF5FA] justify-end mt-5 ${totalCount / rows > 1 ? 'visible': 'hidden'}`}
            />
        </section>
    )
});

JDDetailsResumes.propTypes = {
    menuVisibility: PropTypes.bool,
    handleMenuVisibility: PropTypes.func
}

export default JDDetailsResumes;