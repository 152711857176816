import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate, useParams } from 'react-router-dom';
import PromptService from '../../../services/prompt.service';
import { useToast } from '../../../contextProviders/ToastContext';
import LoadingComponent from '../../../components/Common/LoadingComponent';
import { Dropdown } from 'primereact/dropdown';
import { PROMPT_INTERVIEW_TYPES } from '../../../utils/constants';

export default function PromptManagementUpdate(){
    const {id} = useParams();
    const [error, setError] = useState({});
    const [title, setTitle] = useState('');
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState('');

    const {showSuccessMessage, showErrorMessage} = useToast();

    const navigate = useNavigate();
    const [initialTitle, setInitialTitle] = useState(null);
    const [initialPrompt, setInitialPrompt] = useState(null);
    const [initialDescription, setInitialDescription] = useState(null);
    const [initialInterviewType, setInitialInterviewType] = useState(null);

    const [interviewType, setInterviewType] = useState(null);

    const goBack = () => navigate(-1);
    const navigateTo = (path) => navigate(path)

    const isValuesChanged = () => {
        return (
            initialTitle !== title 
            || initialPrompt !== prompt 
            || initialDescription !== description
            || initialInterviewType !== interviewType
        );
    }

    useEffect(() => {
        async function init(){
            try {
                setLoading(true);
                const response = await PromptService.getPrompt(id);
                setLoading(false);
                if(response.status === 200){
                    const data = response?.data?.data || {};
                    setTitle(data['title'] || '');
                    setPrompt(data['prompt'] || '');
                    setDescription(data['description'] || '');
                    setInterviewType(data['interviewType']);

                    setInitialTitle(data['title'] || '');
                    setInitialPrompt(data['prompt'] || '');
                    setInitialDescription(data['description'] || '');
                    setInitialInterviewType(data['interviewType']);
                }
            } catch (error) {
                setLoading(false);
            }
        }

        init();

    }, []);


    const validate = () => {
        setError({})
        const error = {};

        if(!title || title?.trim()?.length === 0)
            error['title'] = 'Title required';

        if(!prompt || prompt?.trim()?.length === 0)
            error['prompt'] = 'Content required';

        if(!interviewType)
            error['interviewType'] = 'Interview Type required';

        if(Object.keys(error).length){
            setError(error)
            return false;
        }
        return true;
    }

    const handleInput = (key, event) => {
        const value = event?.target?.value;
        if(key === "title")
            setTitle(value)
        if(key === "prompt")
            setPrompt(value)
        else if(key === "description")
            setDescription(value)
    }

    const submit = async (event) => {
        event.preventDefault(); 
        if(!validate()) return;

        try {
            setLoading(true);
            const requestData = {};
            if(initialTitle !== title)
                requestData['title'] = title;
            if(initialPrompt !== prompt)
                requestData['prompt'] = prompt;
            if(initialDescription !== description)
                requestData['description'] = description;
            if(initialInterviewType !== interviewType)
                requestData['interviewType'] = interviewType;

            const response = await PromptService.updatePrompt(id, requestData);
            setLoading(false);
            if(response.status === 200){
                showSuccessMessage({
                    summary: 'Success', 
                    detail: 'Successfully updated prompt'
                })
                navigateTo('/dashboard/prompts-management')
            }
        } catch (error) {
            setLoading(false);
            const message = error?.response?.data?.message;
            showErrorMessage({
                summary: 'Failed', 
                detail: message || 'Failed to updated prompt'
            })
        }
    }
  
    return (
        <div className="flex flex-col min-h-full gap-4 p-5">
            <div className={`${loading ? 'visible': 'hidden'} h-screen w-screen top-0 left-0 absolute z-50 bg-transparent justify-center flex items-center`}>
                <div className='w-40'>
                    <LoadingComponent />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between w-full">
                <div 
                    onClick={goBack}
                    className="flex items-center gap-1 text-primary cursor-pointer">
                    <MdKeyboardArrowLeft size={26} />
                    <h1 className='text-xl text-primary font-bold'>Update Prompt</h1>
                </div>
            </div>
            <div className='bg-white p-5 rounded-md flex flex-col gap-3'>
                <div className="flex-col flex gap-2">
                    <label className="text-darkGray font-medium text-sm" htmlFor="title">Title *</label>
                    <InputText 
                        id="title"
                        value={title}
                        placeholder="Enter Title" 
                        onChange={handleInput.bind(this, 'title')}
                        className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                    />
                    {(error['title'] || (!title || title?.trim()?.length === 0)) && <span className="text-dr font-normal text-xs">{error['title']}</span>}
                </div>
                <div className="flex-1 flex flex-col gap-2">
                    <label className="text-darkGray font-medium text-sm" htmlFor="interviewType">Interview Type</label>
                    <Dropdown
                        checkmark={true} 
                        optionLabel="label"  
                        value={interviewType}
                        highlightOnSelect={false}
                        options={PROMPT_INTERVIEW_TYPES}  
                        defaultValue={interviewType} 
                        onChange={(e) => setInterviewType(e.value)} 
                        placeholder="Select employment type" 
                        className="h-10 border-l_border border-[1px] w-1/3" 
                        pt={{
                            input: {className: 'text-sm'},
                            itemLabel: {className: 'text-sm'},
                            item: {className: 'p-3'}
                        }} 
                    />
                    {error['interviewType'] && <span className="text-dr font-normal text-xs">{error['interviewType']}</span>}
                </div>
                <div className="flex-col flex gap-2">
                    <label className="text-darkGray font-medium text-sm" htmlFor="description">Description</label>
                    <InputText 
                        id="description"
                        value={description}
                        placeholder="Enter description"
                        onChange={handleInput.bind(this, 'description')} 
                        className="border-l_border border-[1px] h-10 p-2 text-sm font-normal"
                    />
                </div>
                <div className='flex-col flex gap-2'>
                    <label className="text-darkGray font-medium text-sm" htmlFor="prompt">Prompt *</label>
                    <InputTextarea
                        rows={15}
                        value={prompt}
                        placeholder="Write or paste prompt" 
                        onChange={handleInput.bind(this, 'prompt')}
                        className='border border-l_border text-sm p-2 font-normal'
                    />
                    {(error['prompt'] || prompt?.trim()?.length === 0) && <span className="text-dr font-normal text-xs">{error['prompt']}</span>}
                </div>
            </div>
            <button 
                onClick={submit}
                disabled={!isValuesChanged()}
                className={`bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-40 mt-5 ${isValuesChanged() ? 'opacity-100': 'opacity-40'}`}>
                Update
            </button>
        </div>
    )
}