import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pattern from './../../assets/images/pattern-7.svg';
import pattern2 from './../../assets/images/pattern-8.svg';
import AnimatedText from '../Common/AnimatedText';
import { motion, useAnimationControls } from 'framer-motion';
import useInView from '../../utils/animations/useInView';
import { AnimatedImage } from '../Common/AnimatedImage';
const AnimatedNumber = motion.span;

const StatCard = ({ percentage, title, color }) => {
    const controls = useAnimationControls();
    const [count, setCount] = useState(0);
    const [ref, isInView] = useInView({ threshold: 0.1 });
    const [hasAnimated, setHasAnimated] = useState(false); // New state to track animation
  
    useEffect(() => {
      if (isInView && !hasAnimated) { // Check if the animation has not run yet
        controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.5 },
        });
  
        const animateValue = (start, end, duration) => {
          let startTimestamp = null;
          const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            setCount(Math.floor(progress * (end - start) + start));
            if (progress < 1) {
              window.requestAnimationFrame(step);
            } else {
              setHasAnimated(true); // Set the animation as completed
            }
          };
          window.requestAnimationFrame(step);
        };
  
        animateValue(0, parseInt(percentage, 10), 2000);
      }
    }, [isInView, percentage, controls, hasAnimated]);
  
    return (
      <motion.div
        ref={ref}
        className="bg-white p-10 py-14 w-full md:w-1/2 lg:w-1/4 rounded-md flex drop-shadow justify-center flex-col items-center gap-5 z-10 shadow md:shadow-none"
        initial={{ opacity: 0, y: 20 }}
        animate={controls}
      >
        <h1 className="text-4xl md:text-5xl font-bold" style={{ color }}>
          <AnimatedNumber>{count}</AnimatedNumber>{`${title === 'Interview scalability' ? 'x' : '% +' }`}
        </h1>
        <p className="text-brownGray text-center text-xl font-normal leading-5">{title}</p>
      </motion.div>
    );
};

StatCard.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    percentage: PropTypes.number,
}

const HiringStats = () => (
  <div className="bg-white p-5  md:pb-0 relative">
    <div className='md:max-w-6xl mx-auto py-10 flex flex-col items-center gap-3 md:h-[80%]'>
        <div className='flex justify-center items-center relative md:w-2/3'>
          <AnimatedImage
            src={pattern2}
            className="absolute top-0 md:-top-5 -right-2 md:right-2 md:h-20 md:w-20 h-10 w-10 animate-float"
          />
          <AnimatedText className="text-3xl md:text-4xl font-bold text-darkGray mb-4 text-center">
              We've empowered top hiring managers to hire the best talent
          </AnimatedText>
        </div>
        <AnimatedText className="text-base text-brownGray font-normal mb-12 md:w-2/3 text-center">
          Transform your hiring strategy with us and effortlessly secure the best-fit candidates with unmatched precision
        </AnimatedText>
        <div className="flex flex-wrap justify-center gap-6 w-full px-10 md:py-10">
            <StatCard percentage={90} title="Reduction in hiring cycle" color="#4DC284" />
            <StatCard percentage={50} title="Reduction in the cost" color="#FF9500" />
            <StatCard percentage={98} title="Anomaly detection" color="#165DFF" />
            <StatCard percentage={100} title="Interview scalability" color="#7E18FF" />
        </div>
    </div>
    <img 
        alt='pattern'
        src={pattern}
        className="w-full h-full absolute top-0 left-0"
    />
  </div>
);

export default HiringStats;