import axiosInstance from ".";

function getBlogs(page=1, limit=10){
    return axiosInstance.get(`/blogs?page=${page}&limit=${limit}`)
}

function getBlog(blogId){
    return axiosInstance.get(`/blogs/${blogId}`)
}

function createBlog(data){
    return axiosInstance.post('/blogs', data)
}

function updateBlog(blogId, data){
    return axiosInstance.put(`/blogs/${blogId}`, data)
}

function deleteBlog(blogId){
    return axiosInstance.delete(`/blogs/${blogId}`)
}

const BlogService = {
    getBlog,
    getBlogs,
    createBlog,
    updateBlog,
    deleteBlog
}

export default BlogService;