import React from 'react';

const AnimatedButton = ({ children, onClick, className }) => {
  return (
    <button 
      onClick={onClick}
      className={`
        relative overflow-hidden
        px-6 py-3 rounded-full
        bg-green-500 font-semibold
        transition-all duration-300 ease-out
        transform hover:scale-105 hover:shadow-lg
        active:scale-95
        group
        ${className}
      `}
    >
      <span className="relative z-10">{children}</span>
    </button>
  );
};

export default AnimatedButton;