import { useState, useEffect } from 'react';
import { MdClear } from "react-icons/md";
import pattern from './../../assets/images/pattern-2.svg';
import { Document, Page, pdfjs } from 'react-pdf';
import mammoth from 'mammoth';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js`;

export default function ResumeViewerPopup({ header, close, resumeUrl }) {

    const [numPages, setNumPages] = useState(null);
    const [error, setError] = useState(null);
    const [docContent, setDocContent] = useState("");

    useEffect(() => {
        if (resumeUrl && resumeUrl?.includes('.docx')) {
            fetch(resumeUrl)
                .then(response => response.arrayBuffer())
                .then(data => {
                    return mammoth.extractRawText({ arrayBuffer: data });
                })
                .then(result => {
                    setDocContent(result.value);
                    setError(null);
                })
                .catch(error => {
                    console.error("Failed to load DOCX:", error);
                    setError("Failed to load the document.");
                });
        }
    }, [resumeUrl]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setError(null);
    }

    function onDocumentLoadError(error) {
        console.error("Failed to load PDF:", error);
        setError("Failed to load the document.");
    }

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 overflow-hidden">
            <div className="w-1/2 max-w-screen-lg h-full md:h-auto md:max-h-[95%] flex flex-col gap-5 relative mx-4 md:mx-0">
                <div
                    onClick={close}
                    className="rounded-full shadow-md top-6 right-6 absolute z-20 h-8 w-8 bg-white flex justify-center items-center text-darkGray cursor-pointer">
                    <MdClear size={20} />
                </div>
                <div className="flex-1 flex flex-col gap-6 bg-white p-4 md:p-5 rounded-xl overflow-y-auto">
                    <div 
                        className="bg-gradient-to-b from-[#2B4360] to-[#3C99CA] p-4 md:p-6 rounded-md text-white flex flex-col gap-2 relative">
                        <h1 className="font-bold text-lg md:text-xl">{header}</h1>
                        <img 
                            src={pattern} 
                            className="absolute top-0 left-0 w-20 h-20 opacity-20"
                            alt="pattern"
                        />
                    </div>
                    {error ? (
                        <div className="text-center text-red-600">{error}</div>
                    ) : resumeUrl && resumeUrl?.includes('.pdf') ? (
                        <Document
                            file={resumeUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentLoadError}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    scale={1.0} // Reduced scale for better mobile view
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    renderTextLayer={false}
                                />
                            ))}
                        </Document>
                    ) : resumeUrl && resumeUrl?.includes('.docx') ? (
                        <div className="docx-content p-2 md:p-4">
                            <pre className="whitespace-pre-wrap">{docContent}</pre>
                        </div>
                    ) : (
                        <div className="text-center text-red-600">
                            Unsupported file format.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
