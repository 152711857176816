import CheckboxComponent from "../../Common/CheckboxComponent";

const services = [
    { label: 'Video Profiling', key: 'videoProfiling' },
    { label: 'Resume Screening', key: 'resumeScreening' },
    { label: 'HR Screening', key: 'hrScreening' },
    { label: 'Technical Screening', key: 'technicalScreening' },
    { label: 'OPS Screening', key: 'opsScreening' },
    { label: 'BPO HR Screening', key: 'bpoHrScreening' },
    { label: 'Exit Interview', key: 'exitInterview' },
];

const OrganizationServiceOptions = ({ selectedOptions, handleChanges }) => {
    return (
        <div className='flex gap-5 flex-wrap'>
            {services.map((option) => (
                <div key={option.key} className="flex gap-3 items-center">
                    <CheckboxComponent
                        value={selectedOptions[option.key]}
                        setValue={(label, value) => handleChanges(option.key, value)}
                    />
                    <label className="text-sm font-medium w-full mr-2">{option.label}</label>
                </div>
            ))}
        </div>
    );
};

export default OrganizationServiceOptions;