import useInView from "../../utils/animations/useInView";

const AnimatedText = ({ children, className }) => {
    const [ref, isInView] = useInView({ threshold: 0.1, triggerOnce: true });
    return (
      <h1 
        ref={ref}
        className={`${className} transition-all duration-700 ease-out ${
          isInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        {children}
      </h1>
    );
};

export default AnimatedText;