import { useState } from 'react';

const useFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateForm = (formData) => {
    const newErrors = {};

    // First Name validation
    if (!formData.firstName || formData.firstName.trim().length === 0) {
      newErrors.firstName = 'First name is required';
    }

    // Last Name validation
    if (!formData.lastName || formData.lastName.trim().length === 0) {
      newErrors.lastName = 'Last name is required';
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = 'Valid email is required';
    }

    // // Subject validation
    // if (!formData.subject || formData.subject.trim().length === 0) {
    //   newErrors.subject = 'Subject is required';
    // }

    // // Message validation
    // if (!formData.message || formData.message.trim().length === 0) {
    //   newErrors.message = 'Message is required';
    // }

    // Mobile Number validation
    // const mobileRegex = /^\d{10}$/;
    // if (!formData.mobileNumber || !mobileRegex.test(formData.mobileNumber)) {
    //   newErrors.mobileNumber = 'Valid 10-digit mobile number is required';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return { errors, validateForm };
};

export default useFormValidation;