import axiosInstance from ".";

function getPrompts(page=1, limit=10){
    return axiosInstance.get(`/prompts?page=${page}&limit=${limit}`)
}

function getPrompt(promptId){
    return axiosInstance.get(`/prompts/${promptId}`)
}

function createPrompt(data){
    return axiosInstance.post('/prompts', data)
}

function updatePrompt(promptId, data){
    return axiosInstance.put(`/prompts/${promptId}`, data)
}

function deletePrompt(promptId){
    return axiosInstance.delete(`/prompts/${promptId}`)
}

const PromptService = {
    getPrompt,
    getPrompts,
    createPrompt,
    updatePrompt,
    deletePrompt
}

export default PromptService;