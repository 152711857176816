/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import LogService from '../../services/log.service';
import ConsoleHelper from '../../utils/consoleHelper';
import { useSessionContext } from '../../contextProviders/SessionContext';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';
import { useBotImages } from '../../contextProviders/BotImageContext';

const BotComponent = (props) => {

    const {
        isConnected, 
        onCompleted,
        isConnectionIssue,
        startMediaRecorder
    } = props;


   const { botImages } = useBotImages();

    const audioRef = useRef();
    const lastVisemeIdRef = useRef(null);
    const visemeTimeoutsRef = useRef([]);
    const {audioStream} = useMediaContext();
    const [message, setMessage] = useState(null);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [currentVisemeId, setCurrentVisemeId] = useState(0);

    const {
        sessionInfo,
        transcripts,
        messageQueue, 
        setMessageQueue,
        setIsBotSpeaking,
        addMessageToTranscript,
    } = useSessionContext();

    const token = sessionInfo?.token || localStorage.getItem('sessionToken');

    const isMicOn = (desiredState = true) => {
        // Log the desired state
        ConsoleHelper.log(`isMicOn ${desiredState}`);
    
        // Check if the desired state is different from the current state
        const audioTracks = audioStream?.getAudioTracks();
    
        if (audioTracks) {
            // Determine the current state based on the tracks' enabled property
            const currentState = audioTracks[0]?.enabled; // Assuming all tracks have the same state
    
            // Only change the state if it's different
            if (currentState !== desiredState) {
                LogService.uploadLog(token, sessionInfo?.session_id, 
                    `BotComponent isMicOn state=${desiredState} is audioStream null=${audioStream === null}`
                );
    
                audioTracks.forEach(track => { track.enabled = desiredState });
            }
        } else {
            LogService.uploadLog(token, sessionInfo?.session_id, 
                `BotComponent audioStream is null; state change not possible`
            );
        }
    };
    
    useEffect(() => {
        const audioElement = audioRef?.current;
        if (!audioElement) return;
    
        const syncVisemes = () => {
        
            const currentTime = audioElement.currentTime * 1000; // Convert to milliseconds
        
            const visemesInNextSecond = message?.viseme.filter(v =>
                currentTime <= v.offset && v.offset < currentTime + 1000
            );
        
            visemesInNextSecond.forEach((viseme) => {
                const delay = viseme.offset - currentTime;
                const timeoutId = setTimeout(() => {
                    if (lastVisemeIdRef.current !== viseme.viseme_id) {
                        lastVisemeIdRef.current = viseme.viseme_id;
                        setCurrentVisemeId(viseme.viseme_id);
                    }
                }, delay);
                visemeTimeoutsRef.current.push(timeoutId);
            });
        };

        audioElement.addEventListener("timeupdate", syncVisemes);
    
        return () => {
            visemeTimeoutsRef.current = [];
            lastVisemeIdRef.current = null;
            audioElement.removeEventListener("timeupdate", syncVisemes);
            visemeTimeoutsRef.current.forEach((timeoutId) => clearTimeout(timeoutId));
        };
        
    }, [audioRef, message]);

    useEffect(() => {
        if (audioRef.current && messageQueue.length > 0 && !isSpeaking) {
            const message = messageQueue[0];
            const exists = transcripts.some(obj => obj?.questionId === message?.questionId);
            if(!exists || !message?.questionId){
                const url = createAudioUrl(message?.audio_stream);

                addMessageToTranscript(message);
                setMessage(message);

                audioRef.current.src = url;
                const playPromise = audioRef.current.play();
    
                if (playPromise !== undefined)
                    playPromise.catch(error => {setIsBotSpeaking(false)});
            }
            else setMessageQueue((prevQueue) => prevQueue.slice(1));
        }

    }, [audioRef?.current, isSpeaking, messageQueue])

    const createAudioUrl = (audioStream) => {
        const binaryString = atob(audioStream);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++)
            bytes[i] = binaryString.charCodeAt(i);
        const blob = new Blob([bytes], { type: 'audio/mpeg' });
        return URL.createObjectURL(blob);
    };

    const ringTheme = !isConnectionIssue && isConnected ? 'ring-4 ring-[#4DC284] ring-offset-4 ring-offset-white'
        : !isConnected && !isConnectionIssue ? 'ring-4 ring-do ring-offset-4 ring-offset-white' : 'ring-4 ring-dr ring-offset-4 ring-offset-white'

    return (
        <div className={`${isSpeaking ? 'animate-scaleUp' : 'animate-scaleDown'} border-4 rounded-md justify-center items-center flex flex-col gap-2`}>
            <div className={`h-36 w-36 rounded-md ${ringTheme}`}>
                <img
                    alt={`Bot Avatar ${currentVisemeId}`}
                    src={botImages[currentVisemeId] || ""}
                    className='h-36 w-36 bg-lightBlue rounded-md'
                />
            </div>
            <audio 
                ref={audioRef}
                id='bot-audio'  
                onPlaying={() => {
                    startMediaRecorder();
                    setIsBotSpeaking(true);
                    setIsSpeaking(true);
                }}
                onEnded={() => {
                    setCurrentVisemeId(0);
                    setIsSpeaking(false);
                    setIsBotSpeaking(false);
                    setMessageQueue((prevQueue) => prevQueue.slice(1));

                    if(message?.type !== 'ACKNOWLEDGEMENT' || message?.type === 'NEXT_QUESTION'){
                        if(!message?.is_text_question && messageQueue?.length === 1)
                            isMicOn(true);
                    }
        
                    if (message?.is_completed) {
                        isMicOn(false);
                        onCompleted();
                    }
                }}
            />
        </div>
    )
}

export default React.memo(BotComponent);