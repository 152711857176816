import PropTypes from 'prop-types';
import successLogo from './../../assets/icons/success.svg';

function SuccessPopup(props){
    const {message} = props;
    return (
        <div 
            style={{animation: 'fadeIn 0.3s ease-out forwards'}}
            className="absolute h-screen w-screen top-0 left-0 z-40 flex justify-center items-center bg-transparent">
            <div 
                className="bg-white p-7 rounded-md flex justify-center items-center w-1/4 shadow shadow-gray"
                style={{animation: 'scaleIn 0.3s ease-out forwards'}}>
                <div className='flex flex-col justify-center items-center gap-6'>
                    <img
                        alt="logo" 
                        src={successLogo} 
                        className="h-20 w-20"
                    />
                    <p className="text-[#333333] text-base font-medium text-center w-2/3">
                        {message || 'Your HR Initial screening questions has been saved.'}
                    </p>
                </div>
            </div>
        </div>
    )
}

SuccessPopup.protoTypes = {
    message: PropTypes.string
}

export default SuccessPopup;