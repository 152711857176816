import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import InterviewHome from "../pages/Interview/InterviewHome";
import InterviewRoom from "../pages/Interview/InterviewRoom";
import ProtectedRoute from "../components/Auth/ProtectedRoute";
import InterviewLanding from "../pages/Interview/InterviewLanding";
import { SessionProvider } from "../contextProviders/SessionContext";
import { MonitorProvider } from "../contextProviders/MonitorContext";
import { MediaTestProvider } from "../contextProviders/MediaTestContext";
import { AnomaliesProvider } from "../contextProviders/AnomaliesContext";
import { InterviewMediaProvider } from "../contextProviders/InterviewMediaContext";
import { InterviewAuthProvider } from "../contextProviders/InterviewAuthContext";
import { ImageProvider } from "../contextProviders/BotImageContext";

export default function InterviewRoutes(){

    useEffect(() => {
        const handlePaste = (e) => {
          e.preventDefault();
          alert("Pasting from outside sources is disabled.");
        };
    
        // Add event listener to block paste
        document.addEventListener('paste', handlePaste);
    
        return () => {
          // Cleanup event listener
          document.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <InterviewAuthProvider>
            <InterviewMediaProvider>
                <SessionProvider>
                    <AnomaliesProvider>
                        <MediaTestProvider>
                            <MonitorProvider>
                                <ImageProvider>
                                    <Routes>
                                        <Route path="" element={<InterviewHome />}>
                                            <Route index path=":id" element={<ProtectedRoute element={<InterviewLanding />} />}/>
                                            <Route path="room/:id" element={<ProtectedRoute element={<InterviewRoom />} />}/>
                                            <Route path="*" element={<InterviewHome />}/>
                                        </Route>
                                    </Routes>
                                </ImageProvider>
                            </MonitorProvider>
                        </MediaTestProvider>
                    </AnomaliesProvider>
                </SessionProvider>
            </InterviewMediaProvider>
        </InterviewAuthProvider>
    )
}