import { useState } from 'react';
import { MdClear } from "react-icons/md";
import pattern from './../../assets/images/pattern-2.svg';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js`;

export default function JDViewerPopup(props){
    const {header, close, pdfUrl} = props;

    const [numPages, setNumPages] = useState();
  
    function onDocumentLoadSuccess({ numPages }){
      setNumPages(numPages);
    }

    return (
        <div className="absolute top-0 left-0 bottom-6 h-screen w-screen flex justify-center items-center bg-transparent z-50 overflow-hidden">
            <div className='min-w-1/2 max-h-[95%] flex flex-col gap-5 relative'>
                <div
                    onClick={close}
                    className='rounded-full shadow-md top-6 absolute z-20 -right-2 h-8 w-8 bg-white flex justify-center items-center text-darkGray cursor-pointer'>
                    <MdClear 
                        size={20}
                    />
                </div>
                <div className='my-10 max-h-[95%] flex flex-col gap-6 bg-white p-5 rounded-xl overflow-y-scroll'>
                    <div 
                        className='bg-gradient-to-b from-[#2B4360] to-[#3C99CA] p-6 rounded-md text-white flex flex-col gap-2'>
                        <h3 className="font-normal text-base">Job description</h3>
                        <h1 className='font-bold text-xl'>{header}</h1>
                        <img 
                            src={pattern} 
                            className="absolute"
                            alt='pattern'
                        />
                    </div>
                    <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                scale={1.3}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                renderTextLayer={false}
                            />
                        ))}
                    </Document>
                </div>
            </div>
        </div>
    )
}