import { Column } from 'primereact/column';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaToggleOff, FaToggleOn} from "react-icons/fa6";
import { DataTable } from 'primereact/datatable';
import { BsThreeDotsVertical } from "react-icons/bs";
import JDService from "../../../services/jd.service";
import editIcon from './../../../assets/icons/edit.svg';
import { useJDInfo } from '../../../contextProviders/JDInfoContext';
import LoadingComponent from '../../Common/LoadingComponent';
import { useToast } from '../../../contextProviders/ToastContext';
import logo from './../../../assets/icons/logo.svg';
import moment from 'moment';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/common';
import ConsoleHelper from '../../../utils/consoleHelper';

export default function HRDashboardJDSection(props){
    const navigate = useNavigate();
    const [jds, setJDs] = useState();
    const [uploading, setUploading] = useState(false);
    const {clearJD, changeJDInfo} = useJDInfo();
    const [loading, setLoading] = useState(false);
    const {selectedJD, handleSelectedJd} = props;
    
    const [clickedOn, setClickedOn] = useState(null);
    const { showErrorMessage, showSuccessMessage} = useToast();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);


    useEffect(() => {
        async function getJDs(){
            try {
                setLoading(true);
                const response = await JDService.getJds(1, 10);
                setLoading(false);
                if(response.status === 200){
                    const {data} = response.data;
                    setJDs(data)
                }
            } catch (error) {
                setLoading(false);
                ConsoleHelper.log(error?.response?.data?.message)
            }
        }
        getJDs();
    }, [])

    const renderPrimarySkills = ({primarySkills}) => {
        const list = primarySkills[0]?.name ? primarySkills?.map((item) => item?.name) : primarySkills;
        return (
            <div className="flex gap-4 text-sm items-center w-40">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {list?.join(", ")}
                </span>
            </div>
        )
    }


    const renderLocation = ({location}) => {
        return (
            <div className="flex gap-4 text-sm items-center w-40">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {(!location || location?.length === 0) ?  'Not Provided' : location?.map((item) => capitalizeFirstLetterOfEachWord(item))?.join(",")}
                </span>
            </div>
        )
    }

    const renderCreatedAt = ({createdAt}) => {
        return (
            <div className='rounded-full justify-center items-center flex'>
                <span className='w-40 text-sm'>{moment(createdAt).format('DD MMM yyyy')}</span>
            </div>
         )
    }

    const renderJobTitle = (item) => {
        return (
            <div className="flex gap-4 text-sm items-center w-44">
                <span className='text-ellipsis overflow-hidden whitespace-nowrap'>
                    {item?.title}
                </span>
            </div>
        )
    }
    
    const resumeCount = ({resumeCount}) => {
        return (
            <div className='bg-primary h-8 w-8 rounded-full justify-center items-center flex'>
                <span className=' text-white'>{resumeCount || 0}</span>
            </div>
         )
    }

    const onEditClick = (item) => {
        clearJD();
        changeJDInfo(item);
        navigate(`/dashboard/job-description/${selectedJD._id}/edit`)
    }

    const inActivateJDClick = () => {
        setClickedOn("inactivate");
        setShowConfirmationDialog(true);
    }

    const activateJDClick = () => {
        setClickedOn("activate");
        setShowConfirmationDialog(true);
    }

    const toggleJDActiveStatus = async () => {
        try {
            setUploading(true);
            const jdResponse = 
                clickedOn === "activate" ? await JDService.activateJD(selectedJD?._id) : await JDService.inactivateJD(selectedJD?._id);
            setUploading(false);
            if(jdResponse.status === 200){
                setShowConfirmationDialog(false);
                setJDs(jds?.map((item => {
                    if(item?._id === selectedJD?._id)
                        return {...item, active: clickedOn === "activate" ? true : false}
                    return item;
                })))
                if(clickedOn === "inactivate"){
                    showSuccessMessage({
                        life: 5000,
                        summary: 'Alert', 
                        detail: "Please ensure there are no active interview invites. Candidate can continue the interview if any invites are sent"
                    })
                }
                else {
                    showSuccessMessage({
                        summary: 'Success', 
                        detail: "JD is activated"
                    })
                }
            } 
        }
        catch (error) {
            setUploading(false);
            setShowConfirmationDialog(false);
            showErrorMessage({summary: 'Failed', detail: error?.response?.data?.message})
        }
    }
    const options = (item) => {
        return (
            <div 
                onClick={(event) => event?.stopPropagation()}
                className={`absolute transform -translate-x-1/2 -translate-y-1/2`}>
                <BsThreeDotsVertical
                    size={20} 
                    color='#666666'
                    className="cursor-pointer"
                    onClick={handleSelectedJd.bind(this, item)}
                />
                <div className={`${selectedJD?._id === item?._id ? 'visible' : 'hidden'} rounded-lg shadow shadow-gray bg-white -top-5 right-0 z-20 absolute`}>
                    <ul className="p-2 gap-2 flex flex-col">
                        <li
                            style={{backgroundColor: 'rgba(60, 153, 202, .1)'}}
                            onClick={onEditClick.bind(this, item)} 
                            className={`py-2 text-blue flex items-center gap-3 px-3 rounded cursor-pointer ${item?.active === false ? 'hidden': 'visible'}`}>
                            <img 
                                alt='edit-icon'
                                src={editIcon}
                                className='h-4 w-4'
                            />
                            <h4 className='text-sm'>Edit</h4>
                        </li>
                        <li 
                            onClick={inActivateJDClick}
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active !== false ? 'visible': 'hidden'}`} 
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOff size={20}/>
                            <h4 className='text-sm bg'>Inactive</h4>
                        </li>
                        <li 
                            onClick={activateJDClick}
                            className={`py-2 text-[#FF3B30] flex items-center gap-3 px-3 justify-between rounded cursor-pointer ${item?.active === false ? 'visible': 'hidden'}`}
                            style={{backgroundColor: 'rgba(255, 59, 48, .1)'}}>
                            <FaToggleOn size={20} />
                            <h4 className='text-sm'>Active</h4>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }



    return (
        <div className="flex flex-col gap-6" onClick={handleSelectedJd.bind(this, null)}>
            <div className={`${showConfirmationDialog? 'visible': 'hidden'} absolute h-screen w-screen bg-transparent top-0 left-0 z-10 flex justify-center items-center`}>
                <div className="bg-white p-5 rounded-md flex justify-center items-center">
                    {uploading && <LoadingComponent/>}
                    <div className={`${uploading? 'hidden': 'visible'} flex flex-col justify-center items-center gap-5`}>
                        <div className="h-16 w-16 bg-[#F6F7F9] rounded-full justify-center items-center flex"><img src={logo} className="h-11 w-11" alt="logo" /></div>
                        <h2 className="text-[#333333] font-bold text-xl">Are you sure?</h2>
                        <div className='flex flex-col items-center justify-center'>
                            <p className="text-brownGray text-base">Would you want to {clickedOn === "activate"? "activate" : "inactivate"} the JD?</p>
                        </div>
                        <div className="flex items-center justify-center gap-10 py-5 text-sm">
                            <button className="h-10 border border-primary w-32 font-medium" onClick={() => setShowConfirmationDialog(false)}>NO</button>
                            <button className="bg-primary h-10 rounded w-32 text-white font-medium" onClick={toggleJDActiveStatus}>YES</button>
                        </div>
                    </div>
                </div>
            </div>
            <h1 className='text-xl text-[#161616] font-bold'>Job Description List</h1>
                {/* <div className="flex gap-5 text-brownGray font-normal">
                    <div className="bg-white h-10 px-2 flex rounded justify-center items-center">
                        <InputText
                            className="text-sm h-10" 
                            placeholder="Search"
                            color="#666666"
                        />
                        <IoSearchSharp 
                            size={20} 
                            color="#666666"
                        />
                    </div>
                    <div className="bg-white h-10 px-2 rounded flex items-center gap-5 cursor-pointer">
                        <span className="text-sm ">Experience</span>
                        <MdOutlineKeyboardArrowDown 
                            size={20} 
                        />
                    </div>
                    <div className="bg-white h-10 px-2 rounded flex items-center gap-5 cursor-pointer">
                        <span className="text-sm ">Location</span>
                        <MdOutlineKeyboardArrowDown 
                            size={20} 
                        />
                    </div>
                </div> */}
            <div className="pb-10">
                <DataTable 
                    value={jds}
                    loading={loading}
                    className="text-sm bg-white" 
                    rowClassName='bg-white hover:text-blue hover:cursor-pointer'
                    onRowClick={({data}) => {
                        navigate(`/dashboard/job-description/${data._id}`, {state: data})
                    }}
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column 
                        field="title" 
                        header="Job title" 
                        body={renderJobTitle}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="experience" 
                        header="Experience" 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="primarySkills" 
                        header="Primary skills" 
                        body={renderPrimarySkills}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    {/* <Column 
                        field="secondarySkills" 
                        header="Secondary skills" 
                        body={renderSecondarySkills} 
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white bg-white' 
                    /> */}
                    <Column 
                        field="resumeCount" 
                        header="Resume Count" 
                        body={resumeCount}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="location" 
                        header="Location"
                        body={renderLocation}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        field="createdAt" 
                        header="Created Dt"
                        body={renderCreatedAt}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                    <Column 
                        body={options}
                        className='text-sm 2xl:text-base'
                        headerClassName='text-sm 2xl:text-base bg-white' 
                    />
                </DataTable>
                {/* <Paginator 
                    rows={rows}
                    first={first}
                    totalRecords={50}
                    onPageChange={onPageChange}
                    className="bg-[#ECF5FA] justify-end mt-5"
                /> */}
            </div>
        </div>
    )
}