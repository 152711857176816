import { PopupModal } from "react-calendly";
import { useNavigate } from 'react-router-dom';

function CalendlyModel(){
    const navigate = useNavigate();
    // document.body.style.overflow = 'hidden';

    return (
        <PopupModal
            open={true}
            color="#00a2ff"
            textColor="#ffffff"
            onModalClose={() => {
                document.body.style.overflow = 'scroll';
                navigate('/')
            }}
            url="https://calendly.com/roopa-raj-athmick/30min"
            rootElement={document.getElementById("root")}
        />
    )
}

export default CalendlyModel;