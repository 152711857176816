import PropTypes from 'prop-types';
import { MdClear } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import { VscSettings } from "react-icons/vsc";
import firefoxPermissionImage from './../../assets/images/permissions_blocked_firefox.svg';
import safariPermissionImage from './../../assets/images/permissions_blocked_safari.svg';
import chromePermissionImage from './../../assets/images/permissions_blocked_chrome.svg';
import { isChromeBrowser, isFirefoxBrowser, isSafariBrowser } from '../../utils/common';
import { useMediaTest } from '../../contextProviders/MediaTestContext';

function PermissionsBlockedPopup(props){
    const {
        microphoneStatus,
        cameraStatus,
    } = useMediaTest();

    const image = isFirefoxBrowser() ? firefoxPermissionImage : isSafariBrowser() ? safariPermissionImage : chromePermissionImage;
    return (
        <div className={`${props.visible ? 'visible': 'hidden'} h-screen w-screen absolute top-0 left-0 justify-center items-center flex bg-transparent z-30`}>
            <div className="p-5 bg-white w-1/2 drop-shadow-md rounded-md">
                <MdClear onClick={props.hide} size={24} className='ml-auto cursor-pointer'/>
                <div className='flex justify-center items-center'>
                    <div className='flex-1'>
                        <img
                            alt='permissions'
                            src={image}
                            className='h-80'
                        />
                    </div>
                    <div className='flex-1 text-darkGray flex-col flex gap-5'>
                        <h3 className='font-medium text-xl'>Interview has been blocked from using your {cameraStatus === "denied" && microphoneStatus === "denied" ? 'microphone and camera' : cameraStatus === "denied"? 'camera': 'microphone'}</h3>
                        <ul className={`font-normal text-sm flex flex-col gap-2 ${isChromeBrowser() ? 'visible': 'hidden'}`}>
                            <li className='flex flex-col gap-1'>
                                <p className='flex items-center'> 1. Click the <LuSettings2 size={18} className='mx-2'/> page info icon in your</p>
                                <p>browser's address bar</p>
                            </li>
                            <li>2. {cameraStatus === "denied" && microphoneStatus === "denied" ? 'Turn on microphone and camera' : cameraStatus === "denied"? 'Turn on camera': 'Turn on microphone'}</li>
                        </ul>
                        <ul className={`font-normal text-sm flex flex-col gap-2 ${isSafariBrowser() ? 'visible': 'hidden'}`}>
                            <li className='flex flex-col gap-1'>
                                <p className='flex items-center'> 1. Click the 'Safari' menu and open 'Settings</p>
                                <p>for {window.location.host.replace('www.','')}</p>
                            </li>
                            <li>2. {cameraStatus === "denied" && microphoneStatus === "denied" ? 'Allow microphone and camera' : cameraStatus === "denied"? 'Allow camera': 'Allow microphone'}</li>
                        </ul>
                        <ul className={`font-normal text-sm flex flex-col gap-2 ${isFirefoxBrowser() ? 'visible': 'hidden'}`}>
                            <li className='flex flex-col gap-1'>
                                <p className='flex items-center'> 1. Click the <VscSettings size={18} className='mx-2'/> page info icon in your</p>
                                <p>browser's address bar</p>
                            </li>
                            <li>2. {cameraStatus === "denied" && microphoneStatus === "denied" ? 'Clear blocked microphone and camera permissions' : cameraStatus === "denied"? 'Clear blocked camera permission': 'Clear blocked microphone permission'}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

PermissionsBlockedPopup.propTypes = {
    hide: PropTypes.func,
    visible: PropTypes.bool,
}

export default PermissionsBlockedPopup;