import HeroSection from "../components/AboutUs/HeroSection";
import TeamSection from "../components/AboutUs/TeamSection";
import FutureEnvision from "../components/AboutUs/FutureEnvision";
import EnquiryFormSection from "../components/Common/EnquiryFormSection";
import { Helmet } from "react-helmet-async";

export default function AboutUsPage(){
    return (
        <div className="w-screen overflow-hidden">
            <Helmet>
                <title>About Us | Gen AI Interviewer </title>
                <meta name="description" content="Athmick AI is committed to revolutionizing recruitment with our visionary team, driving innovation through AI to create a fair, efficient, and seamless hiring experience." />
                <meta name="keywords" content="AI recruitment, company vision, mission-driven, innovation in hiring, team expertise, recruitment transformation, AI-driven solutions, Athmick.ai team, talent acquisition, leadership in recruitment , Gen-ai technology" />
            </Helmet>
            <HeroSection />
            <FutureEnvision />
            <TeamSection />
            <EnquiryFormSection />
        </div>
    )
}