import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { useToast } from "../../../contextProviders/ToastContext";
import { useJDInfo } from "../../../contextProviders/JDInfoContext";
import CheckboxComponent from "../../../components/Common/CheckboxComponent";
import { Dropdown } from 'primereact/dropdown';

const SKILL_OPTIONS = [
    {label: 'Primary', value: 'primary'}, 
    {label: 'Secondary', value: 'secondary'}
]

function JDManageSkills(props) {
    const {showWarningMessage} = useToast();
    const {goToPreviousTab, goToNextTab} = props;
    const { getJDInfo, changeJDInfo } = useJDInfo();
    const jdInfo = getJDInfo();

    const [skills, setSkills] = useState([]);
    const primarySkills = jdInfo['primarySkills'] || [];
    const secondarySkills = jdInfo['secondarySkills'] || [];

    const maxYear = Math.max(...jdInfo['experience']['code'].match(/\d+/g).map(Number));

    const handleSkillChange = (index, value, field) => {
        const updatedSkills = skills.map((skill, i) => 
            i === index 
                ? { ...skill, [field]: field === 'isPrimary' ? value === 'primary' : value } 
                : skill
        );
        setSkills(updatedSkills);
    };
        
    
    useEffect(() => {
        const list = [
            ...primarySkills?.map(item => ({ ...item, isPrimary: true })) || [],
            ...secondarySkills?.map(item => ({ ...item, isPrimary: false })) || []
        ];
        setSkills(list);
    }, []);


    const onBack = () => {
        const primarySkills = skills
            ?.filter((item) => item.isPrimary)
            .map(({ isPrimary, ...rest }) => rest);

        const secondarySkills = skills
            ?.filter((item) => !item.isPrimary)
            .map(({ isPrimary, ...rest }) => rest);

        changeJDInfo({ 
            primarySkills,
            secondarySkills
        });
        goToPreviousTab();
    };

    const onNext = () => {
        const totalWeightage = getTotalWeightage();

        if (totalWeightage > 100) {
            showWarningMessage({
                life: 5000,
                summary: "Total Weightage Exceeded",
                detail: "The combined weightage across all skills has exceeded 100%. Please adjust the values to ensure the total is exactly 100%.",
            });
            return;
        } else if (totalWeightage < 100) {
            showWarningMessage({
                life: 5000,
                summary: "Total Weightage Incomplete",
                detail: "The combined weightage across all skills is less than 100%. Please adjust the values so that the total adds up to 100%.",
            });
            return;
        }

        const primarySkills = skills
            ?.filter((item) => item.isPrimary)
            .map(({ isPrimary, ...rest }) => rest);

        const secondarySkills = skills
            ?.filter((item) => !item.isPrimary)
            .map(({ isPrimary, ...rest }) => rest);

        changeJDInfo({ 
            primarySkills,
            secondarySkills
        });
        goToNextTab();
    };

    const getTotalWeightage = () => {
        return skills.reduce((obj1, obj2) => obj1 + parseInt(obj2.weightage), 0);
    }

    return (
        <div className="h-full flex flex-col">
            <div className="text-darkGray rounded-md flex flex-col">
                <div className="flex gap-2 flex-col bg-white p-5">
                    <h2 className="text-xl font-medium text-primary">Skills</h2>
                    <div className="flex mt-5">
                        <div className="flex-1">
                            <h3 className="font-medium text-base text-darkGray">Name</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Skill Type</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Weightage (0 - 100 %)</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Mandatory</h3>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                            <h3 className="font-medium text-base text-darkGray">Min. Experience (Years)</h3>
                        </div>
                    </div>
                    <ul className={`flex flex-col gap-5 ${skills?.length > 0 ? 'visible': 'hidden'} mt-3`}>
                        {skills.map((item, index) => (
                            <li key={index} className="flex justify-between items-center">
                                <div className="flex-1"><span>{item.name}</span></div>
                                <div className="flex-1 flex justify-center items-center gap-3">
                                    <Dropdown 
                                        checkmark={true}
                                        optionLabel="label"
                                        options={SKILL_OPTIONS}  
                                        highlightOnSelect={false}
                                        placeholder="Select Skill Type" 
                                        className="h-10 border-l_border border-[1px]"
                                        value={item['isPrimary'] ? 'primary': 'secondary'} 
                                        defaultValue={item['isPrimary'] ? SKILL_OPTIONS[0]: SKILL_OPTIONS[1]}
                                        onChange={(e) => handleSkillChange(index, e.value, 'isPrimary')}  
                                        pt={{
                                            root: { className: 'flex justify-center items-center px-2' },
                                            input: {className: 'text-sm w-28'},
                                            itemLabel: {className: 'text-sm'},
                                            trigger: { className: 'h-3 w-3' }
                                        }} 
                                    />
                                </div>
                                <div className="flex-1 flex justify-center items-center">
                                    <InputText
                                        min="0"
                                        max="100"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        value={item.weightage}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value, 10);
        
                                            if (isNaN(value) || value < 1)
                                                value = 0;

                                            handleSkillChange(index, value, 'weightage');
                                        }}
                                        className="border w-10 rounded-md font-medium text-sm border-l_border h-9 px-1 text-center"
                                    />
                                </div>
                                <div className="flex-1 flex justify-center items-center">
                                    <CheckboxComponent
                                        value={item.mandatory}
                                        setValue={(label, value) => handleSkillChange(index, value, 'mandatory')}
                                    />
                                </div>
                                <div className="flex-1 flex justify-center items-center">
                                    <InputText
                                        min="0"
                                        max={maxYear}
                                        disabled={!item['mandatory']}
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        value={item.experience}
                                        onChange={(e) => {
                                            let value = parseInt(e.target.value, 10);
                                            if (isNaN(value) || value < 1) {
                                                value = 0;
                                            } else if (value > maxYear) {
                                                value = maxYear;
                                            }
                                            handleSkillChange(index, value, 'experience')
                                        }}
                                        className="border w-10 rounded-md font-medium text-sm border-l_border h-9 px-1 text-center"
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                    <span className={`${skills?.length === 0 ? 'visible': 'hidden'} py-3 text-sm`}>
                        No skills available
                    </span>
                </div>

                <div className={`flex py-2 ${skills?.length > 0 ? 'visible': 'hidden'}`}>
                    <div className="flex-1" />
                    <div className="flex-1 flex justify-center items-center" />
                    <div className="flex-1 flex justify-center items-center">
                        <p className="font-medium text-base text-darkGray">Total weightage: 
                            <span className={`ml-1 font-semibold ${getTotalWeightage() > 100 ? 'text-dr': getTotalWeightage() === 100 ? 'text-dg' : 'text-blue'}`}>{getTotalWeightage()} %</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-center items-center" />
                    <div className="flex-1 flex justify-center items-center" />
                </div>
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={onBack}
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>Back</button>
                <button
                    onClick={onNext} 
                    className='bg-blue h-10 text-sm px-10 rounded-md text-white font-semibold w-32 mt-10'>Next</button>
            </div>
        </div>
    )
}

JDManageSkills.propTypes = {
    setActiveTabIndex: PropTypes.func
}

export default JDManageSkills;
