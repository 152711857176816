import moment from "moment/moment";
import { useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function NotificationPage(){
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(notifications?.length);
    // const [notifications, setNotifications] = useState([
    //     {
    //         id: 1,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: false,
    //         createdAt: new Date()
    //     },
    //     {
    //         id: 2,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: true,
    //         createdAt: new Date()
    //     },
    //     {
    //         id: 3,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: true,
    //         createdAt: new Date()
    //     },
    //     {
    //         id: 4,
    //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
    //         read: true,
    //         createdAt: new Date()
    //     }
    // ])

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="flex flex-col h-full p-5">
            <div 
                onClick={goBack}
                className="flex py-2 items-center gap-1 text-[#161616] cursor-pointer">
                <MdKeyboardArrowLeft size={26}/>
                <h1 className='text-xl text-primary font-bold'>Notification</h1>
            </div>
            <div className="mt-5 bg-white p-5 rounded-md">
                <div className="px-5 flex">
                    <div className="border-b-4 flex gap-2 pb-2 border-primary items-center">
                        <h3>All</h3>
                        <div className="bg-blue text-white px-2 rounded-full">
                            <span className="text-xs">{notificationsCount}</span>
                        </div>
                    </div>
                </div>
                <hr className="border-[#E7E7E7]"/>
                <div className={`${notifications?.length === 0 ? 'visible': 'hidden'} flex items-center justify-center py-5`}>
                    <h3 className="text-brownGray">No notifications</h3>
                </div>
                <ul className={`${notifications?.length === 0 ? 'hidden': 'visible'} flex-1 flex flex-col gap-6 py-8`}>
                    {notifications?.map((item) => (
                        <li 
                            key={item.id?.toString()} 
                            className={`flex gap-4 items-center p-4 py-6 rounded-lg  ${item.read ? 'bg-white' : 'bg-[#F6F7F9]'} items-center`}>
                            <div className="min-h-10 min-w-10 bg-primary text-white font-normal rounded-full justify-center items-center flex">
                                <h3 className="text-base">S</h3>
                            </div>
                            <div className="text-brownGray flex flex-col gap-1 flex-1">
                                <p className="text-sm">{item?.message}</p>
                                <span className="text-xs">{moment(item.createdAt).fromNow()}</span>
                            </div>
                            <div className={`bg-blue min-h-3 min-w-3 rounded-full ${item.read ? 'hidden': 'visible'}`} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}