import React, { useState } from "react";
import Editor from '@monaco-editor/react';
import { Dropdown } from "primereact/dropdown";
import VideoPreview from "../Common/VideoPreview";
import { useSessionContext } from "../../contextProviders/SessionContext";

const languageOptions = [
    { label: "Plain Text", value: "plaintext" },
    { label: "ABAP", value: "abap" },
    { label: "Apex", value: "apex" },
    { label: "Azure CLI", value: "azcli" },
    { label: "Batch", value: "bat" },
    { label: "Bicep", value: "bicep" },
    { label: "CameLIGO", value: "cameligo" },
    { label: "Clojure", value: "clojure" },
    { label: "CoffeeScript", value: "coffeescript" },
    { label: "C", value: "c" },
    { label: "C++", value: "cpp" },
    { label: "C#", value: "csharp" },
    { label: "CSP", value: "csp" },
    { label: "CSS", value: "css" },
    { label: "Cypher", value: "cypher" },
    { label: "Dart", value: "dart" },
    { label: "Dockerfile", value: "dockerfile" },
    { label: "ECL", value: "ecl" },
    { label: "Elixir", value: "elixir" },
    { label: "Flow9", value: "flow9" },
    { label: "F#", value: "fsharp" },
    { label: "FreeMarker2", value: "freemarker2" },
    { label: "Go", value: "go" },
    { label: "GraphQL", value: "graphql" },
    { label: "Handlebars", value: "handlebars" },
    { label: "HCL", value: "hcl" },
    { label: "HTML", value: "html" },
    { label: "INI", value: "ini" },
    { label: "Java", value: "java" },
    { label: "JavaScript", value: "javascript" },
    { label: "Julia", value: "julia" },
    { label: "Kotlin", value: "kotlin" },
    { label: "LESS", value: "less" },
    { label: "Lexon", value: "lexon" },
    { label: "Lua", value: "lua" },
    { label: "Liquid", value: "liquid" },
    { label: "M3", value: "m3" },
    { label: "Markdown", value: "markdown" },
    { label: "MDX", value: "mdx" },
    { label: "MIPS", value: "mips" },
    { label: "MSDAX", value: "msdax" },
    { label: "MySQL", value: "mysql" },
    { label: "Objective-C", value: "objective-c" },
    { label: "Pascal", value: "pascal" },
    { label: "PascaLIGO", value: "pascaligo" },
    { label: "Perl", value: "perl" },
    { label: "PostgreSQL", value: "pgsql" },
    { label: "PHP", value: "php" },
    { label: "PLA", value: "pla" },
    { label: "PowerQuery", value: "powerquery" },
    { label: "PowerShell", value: "powershell" },
    { label: "Protocol Buffers", value: "proto" },
    { label: "Pug", value: "pug" },
    { label: "Python", value: "python" },
    { label: "Q#", value: "qsharp" },
    { label: "R", value: "r" },
    { label: "Razor", value: "razor" },
    { label: "Redis", value: "redis" },
    { label: "Redshift", value: "redshift" },
    { label: "reStructuredText", value: "restructuredtext" },
    { label: "Ruby", value: "ruby" },
    { label: "Rust", value: "rust" },
    { label: "SB", value: "sb" },
    { label: "Scala", value: "scala" },
    { label: "Scheme", value: "scheme" },
    { label: "SCSS", value: "scss" },
    { label: "Shell", value: "shell" },
    { label: "Solidity", value: "sol" },
    { label: "AES", value: "aes" },
    { label: "SPARQL", value: "sparql" },
    { label: "SQL", value: "sql" },
    { label: "ST", value: "st" },
    { label: "Swift", value: "swift" },
    { label: "SystemVerilog", value: "systemverilog" },
    { label: "Verilog", value: "verilog" },
    { label: "Tcl", value: "tcl" },
    { label: "Twig", value: "twig" },
    { label: "TypeScript", value: "typescript" },
    { label: "TypeSpec", value: "typespec" },
    { label: "Visual Basic", value: "vb" },
    { label: "WGSL", value: "wgsl" },
    { label: "XML", value: "xml" },
    { label: "JSON", value: "json" },
    { label: "YAML", value: "yaml" }
];

function CodeEditor(props){
    const [code, setCode] = useState(null);
    const [language, setLanguage] = useState("javascript");
    const {transcripts} = useSessionContext();

    function handleEditorChange(value, event) {
        setCode(value);
    }

    return (
        <div className="h-screen w-screen bg-transparent absolute top-0 left-0 flex justify-center items-center gap-10 z-50 p-10">
            <div className="flex-1 w-2/3 gap-2 bg-white h-full rounded-md flex flex-col justify-between">
                <div className="flex justify-between gap-2 p-3">
                    <Dropdown
                        editable 
                        value={language} 
                        optionLabel="label"
                        options={languageOptions}  
                        onChange={(e) => setLanguage(e.value)}  
                        pt={{
                            input: {className: 'text-sm'},
                            itemLabel: {className: 'text-sm'},
                            item: {className: 'p-3'}
                        }} 
                        defaultValue={language}
                        highlightOnSelect={false}
                        placeholder="Select language" 
                        className="h-10 border-l_border border-[1px] pl-3"
                    />
                    <div className="flex gap-2 ml-auto">
                        <button 
                            onClick={props.hideEditor} 
                            className="text-primary bg-white border-primary h-10 px-5 rounded-md text-sm">
                            Cancel
                        </button>
                        <button 
                            onClick={() => {props.submitCode(code)}} 
                            className="bg-primary text-white h-10 px-5 rounded-md text-sm">
                            Submit Code
                        </button>
                    </div>
                </div>
                <div className="px-5 flex flex-col gap-2 mb-3">
                    <h1>Question</h1>
                    <p className="text-sm font-normal">{transcripts[transcripts?.length - 1]?.message}</p>
                </div>
                <Editor 
                    value={code}
                    height="100%"
                    theme="vs-dark"
                    language={language}
                    defaultLanguage={language} 
                    onChange={handleEditorChange}

                />
            </div>
            <div className="flex w-1/3 h-full rounded-md">
                <VideoPreview isRecording={props.isRecording}/>
            </div>
        </div>
    )
}

export default React.memo(CodeEditor)