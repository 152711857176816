import { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    // Define your sitemap content
    const sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>https://www.athmick.ai</loc>
    <lastmod>2024-08-26T09:43:49+00:00</lastmod>
  </url>
  <url>
    <loc>https://www.athmick.ai/products</loc>
    <lastmod>2024-08-26T09:43:49+00:00</lastmod>
  </url>
  <url>
    <loc>https://www.athmick.ai/auth/login</loc>
    <lastmod>2024-08-26T09:43:49+00:00</lastmod>
  </url>
  <url>
    <loc>https://www.athmick.ai/solution</loc>
    <lastmod>2024-08-26T09:43:49+00:00</lastmod>
  </url>
  <url>
    <loc>https://www.athmick.ai/about-us</loc>
    <lastmod>2024-08-26T09:43:49+00:00</lastmod>
  </url>
        </urlset>`;

    // Set the content type to XML
    document.contentType = 'application/xml';
    
    // Write the sitemap content to the document
    document.open();
    document.write(sitemapContent);
    document.close();
  }, []);

  return null;
};

export default Sitemap;
