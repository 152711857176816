import { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

window.Quill = Quill
Quill.register('modules/imageResize', ImageResize)

const fontSizeArr = ['8px','9px','10px','12px','14px','16px','20px','24px','32px','42px','54px','68px','84px','98px'];

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: this.props.editorHtml || '' };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(html) {
    this.setState({ editorHtml: html });
    this.props.handleChange(html);
  }

  render() {
    return (
      <ReactQuill
        theme={this.props.theme}
        onChange={this.handleChange}
        value={this.props.editorHtml}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'#root'}
        placeholder={this.props.placeholder}
      />
    );
  }
}

Editor.modules = {
  toolbar: {
    container: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ 'size': fontSizeArr }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'], // Remove formatting button
      ['code-block'], // Add code block option
    ],
    handlers: {
      size: function (value) {
        const quill = this.quill;
        if (value) {
          quill.format('size', value);
        } else {
          quill.format('size', false); // Remove size format
        }
      },
      'code-block': function () {
        const quill = this.quill;
        const range = quill.getSelection();
        if (range) {
          quill.format('code-block', true); // Toggle code block
        }
      },
    },
  },
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize'],
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'code-block',
];

export default Editor;
