import LoginPage from "../pages/Auth/LoginPage";
import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage";
import { Navigate, Route, Routes } from "react-router-dom";
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage";

export default function AuthRoutes(){

    return (
        <Routes>
            <Route path="login" element={<LoginPage />}/>
            <Route path="forgot-password" element={<ForgotPasswordPage />}/>
            <Route path="reset-password" element={<ResetPasswordPage />}/>
            <Route path="*" element={<Navigate to="/notfound"/>}/>
        </Routes>
    )
}