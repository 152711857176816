import axiosInstance from ".";

function login(data){
    return axiosInstance.post('/auth/login', data)
}

function resetPassword(token, data){
    return axiosInstance.post(`/auth/reset-password?token=${token}` , data)
}

function changePassword(data){
    return axiosInstance.post('/auth/change-password' , data)
}

function forgotPassword(data){
    return axiosInstance.post('/auth/forgot-password', data)
}

function getConfig(){
    return axiosInstance.get('/auth/config')
}

function updateConfig(data){
    return axiosInstance.put('/auth/config', data)
}

function refreshToken(data){
    return axiosInstance.post('/auth/refresh-tokens', data);
}
const AuthService = {
    login,
    getConfig,
    updateConfig,
    refreshToken,
    resetPassword,
    forgotPassword,
    changePassword
}
export default AuthService;