import { Dialog } from 'primereact/dialog';

export default function InterviewExitPopup(props){
    const {cancel, visible, onExit} = props;


    return (
        <Dialog
            closable={false}
            visible={visible} 
            draggable={false}
            dismissableMask={true}
            onHide={() => {}}
            header="Exit Interview!"
        >
            <div className=''>
                <h1>Are you sure you want to exit the interview?</h1>
                <div className='flex mt-8 justify-between'>
                    <button 
                        onClick={cancel}
                        className="bg-dg text-white h-10 w-36 rounded cursor-pointer">Cancel</button>
                    <button 
                        onClick={() => {
                            cancel();
                            onExit();
                        }}
                        className="bg-dr text-white h-10 w-36 rounded cursor-pointer">Yes</button>
                </div>
            </div>
        </Dialog>
    )
}