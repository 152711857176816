import BlogPage from "../components/Blogs/BlogPage";
import BlogsPage from "../components/Blogs/BlogsPage";
import { Route, Routes } from "react-router-dom";

export default function BlogRoutes(){

    return (
        <Routes>
            <Route path="" element={<BlogsPage />}/>
            <Route path=":id" element={<BlogPage />}/>
        </Routes>
    )
}