import useInView from "../../utils/animations/useInView";

export const AnimatedImage = ({ src, alt, className }) => {
    const [ref, isInView] = useInView({ threshold: 0.1, triggerOnce: true });
    return (
      <img 
        ref={ref}
        src={src}
        alt={alt}
        className={`${className} transition-all duration-700 ease-out ${
          isInView ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
        }`}
      />
    );
};