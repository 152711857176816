import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { BounceLoader } from 'react-spinners';
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import logo from './../../assets/icons/logo-2.svg';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AuthService from "../../services/auth.service";
import pattern from './../../assets/images/pattern-3.svg';
import { useAuth } from "../../contextProviders/AuthContext";
import { useToast } from "../../contextProviders/ToastContext";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function LoginPage() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const { showErrorMessage } = useToast();
    const [error, setError] = useState({});
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const validate = () => {
        const error = {};
        if (!email) error['email'] = 'Email required';
        else if (!emailRegex.test(email)) error['email'] = 'Enter correct email';

        if (!password) error['password'] = 'Password required';
        else if (password.trim().length < 8) error['password'] = 'Password must be at least 8 characters.';

        setError(error);
        return Object.keys(error).length === 0;
    };

    const submit = async (event) => {
        event.preventDefault();
        if (!validate()) return;

        try {
            setLoading(true);
            const response = await AuthService.login({ email, password });
            setLoading(false);

            if (response.status === 200) {
                const { user, tokens } = response.data.data;
                const { access, refresh } = tokens;
                login(user, access, refresh);
                navigate('/dashboard');
            }
        } catch (error) {
            setLoading(false);
            showErrorMessage({
                summary: 'Failed',
                detail: error?.response?.data?.message || 'Invalid email or password',
            });
        }
    };

    return (
        <div className="w-screen h-screen justify-center items-center flex" style={{ backgroundImage: `url(${pattern})` }}>
            <Helmet>
                <title>Login | Gen AI Interviewer</title>
                <meta name="description" content="This is the login page of Athmick." />
            </Helmet>
            <div className="bg-white shadow-[1px_4px_10px_rgba(0,0,0,0.12)] items-start w-[80%] md:w-[60%] xl:w-[25%] justify-center flex flex-col p-6 rounded-xl">
                <img src={logo} alt="logo" className="h-12 2xl:h-14" />
                <h1 className="text-primary font-semibold text-xl mt-5">Welcome to Athmick 🖐</h1>
                <form onSubmit={submit} className="w-full mt-10 gap-5 flex flex-col text-sm">
                    <div className="flex flex-col gap-1">
                        <label className="text-primary font-medium text-xs">Email</label>
                        <InputText
                            value={email}
                            className="border border-l_border h-10 px-2 rounded-md text-sm font-normal"
                            placeholder="Enter email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {error.email && <span className="text-dr text-xs mt-1">{error.email}</span>}
                    </div>
                    <div className="flex flex-col gap-1 relative">
                        <label className="text-primary font-semibold text-xs">Password</label>
                        <InputText
                            value={password}
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                            className="border border-l_border h-10 px-2 pr-10 rounded-md text-sm font-normal"
                        />
                        <span
                            onClick={togglePasswordVisibility}
                            className="absolute right-3 top-9 cursor-pointer text-gray-500"
                        >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                        {error.password && <span className="text-dr text-xs mt-1">{error.password}</span>}
                    </div>
                    <button
                        type="submit"
                        className="bg-blue h-10 rounded text-white mt-10 justify-center flex items-center"
                        disabled={loading}
                    >
                        {loading ? <BounceLoader size={26} color="#ffffff" /> : <span className="font-semibold text-xs">Login</span>}
                    </button>
                </form>
            </div>
        </div>
    );
}
