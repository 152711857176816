const LoadingComponent = () => {
    return (
      <div className="flex justify-center items-center">
        <svg className="w-[70px] h-[70px]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle 
            className="fill-none stroke-[#2B4360] stroke-[5] loader-circle" 
            cx="50" 
            cy="50" 
            r="40"
          />
          <svg 
            x="24.5" 
            y="24.5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg" 
            width={window.screen.width > 1500 ? "50" : "40"} 
            height={window.screen.width > 1500 ? "50" : "40"}
            viewBox={window.screen.width > 1500 ? "0 0 50 50" : "0 0 40 40"}>
            <path fillRule="evenodd" clipRule="evenodd" d="M30.9022 34.5572H39.1999C38.0623 32.5066 36.7036 30.0151 35.7235 27.9138L25.5499 8C24.4618 8.69535 21.6842 13.2831 20.7321 14.6383L30.9022 34.5572ZM15.9127 42.2169C15.9127 37.5043 19.2477 32.2838 21.7195 28.0945C22.0892 27.468 22.2325 27.431 22.5475 26.8133C23.4835 24.9853 19.6458 21.1858 18.8569 17.9587C18.6975 18.1358 18.5791 18.2504 18.3756 18.5205L16.0897 22.212C15.212 23.7447 14.4265 24.8704 13.5931 26.4707C11.0719 31.3132 5.91271 41.4238 15.9127 42.2169ZM16.7158 42.2169L31.2647 42.1715L27.6909 35.067H18.5895C17.8094 36.7819 16.7158 38.5612 16.7158 42.2169Z" fill="url(#paint0_linear_1559_4635)"/>
            <path d="M40.8291 37.4136L39.3637 35.1693H35.2717L35.2717 37.4136L40.8291 37.4136Z" fill="url(#paint1_linear_1559_4635)"/>
            <path d="M31.1798 39.6582H35.2717V37.4136H29.7178L31.1798 39.6582Z" fill="url(#paint2_linear_1559_4635)"/>
            <defs>
              <linearGradient id="paint0_linear_1559_4635" x1="12.0628" y1="9.19949" x2="37.2881" y2="10.9472" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2B4360"/>
                <stop offset="1" stopColor="#3C99CA"/>
              </linearGradient>
              <linearGradient id="paint1_linear_1559_4635" x1="12.0628" y1="9.19949" x2="37.2881" y2="10.9472" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2B4360"/>
                <stop offset="1" stopColor="#3C99CA"/>
              </linearGradient>
              <linearGradient id="paint2_linear_1559_4635" x1="12.0628" y1="9.19949" x2="37.2881" y2="10.9472" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2B4360"/>
                <stop offset="1" stopColor="#3C99CA"/>
              </linearGradient>
            </defs>
          </svg>
        </svg>
      </div>
    );
  };

  export default LoadingComponent