import ConsoleHelper from '../utils/consoleHelper';
import React, { createContext, useContext, useState } from 'react';
import { useSessionContext } from './SessionContext';

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
    const {sessionInfo} = useSessionContext();
    const [botImages, setBotImages] = useState({});
    const [loadingBotImages, setLoadingBotImages] = useState(false);
    const avatar = sessionInfo?.['avatar'] || 'avatar1';

    const loadImages = async () => {
        let imageMap = {};
    
        for (let i = 0; i <= 21; i++) {
            const img = new Image();
            img.src = `https://skillassessment.blob.core.windows.net/images/bot-images/${avatar}/viseme-${i}.png`;
    
            try {
                await img.decode();
                imageMap[i] = img.src;
                ConsoleHelper.log(`Loaded viseme-${i}.png`);
            } catch (error) {
                ConsoleHelper.error(`Failed to load viseme-${i}.png`, error);
            }
        }
    
        return imageMap;
    };

    const downloadImages = async () => {
        try {
            setLoadingBotImages(true);
            const preloadedImages = await loadImages();
            setBotImages(preloadedImages);
            setLoadingBotImages(false);
        } catch (error) {
            setLoadingBotImages(false);
        }
    }

    return (
        <ImageContext.Provider value={{ botImages, loadingBotImages, downloadImages }}>
            {children}
        </ImageContext.Provider>
    );
};

export const useBotImages = () => useContext(ImageContext);
