import { useState } from "react";
import JDViewerPopup from "./JDViewerPopup";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import JDQuestionsPreviewPopup from "./JDQuestionsPreviewPopup";
import { useConfig } from "../../contextProviders/ConfigContext";

export default function JDDetailsHeaderSection(props){
    const navigate = useNavigate();
    const config = useConfig();

    const goBack = () => navigate(-1);

    const [showJDPopup, setShowJDPopup] = useState(false);
    const [showJDDetails, setShowJDDetails] = useState(false);
    const [showOpsQuestionsPopup, setShowOpsQuestionsPopup] = useState(false);
    const [showInitialQuestionsPopup, setShowInitialQuestionsPopup] = useState(false);
    const [showTechnicalQuestionsPopup, setShowTechnicalQuestionsPopup] = useState(false);

    const renderValues = (list) => {
        return (
            <ul className="flex items-center gap-2 flex-1">
                {list?.map((item, index) => (
                    <li 
                        key={item}
                        className="bg-white border-[#EAECF0] text-brownGray px-4 rounded-md text-sm text-center py-2">
                        {item?.['name'] || item}
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <section className="">
            <div className={`${showJDPopup ? 'visible' : 'hidden'}`}>
                <JDViewerPopup 
                    pdfUrl={props.jd?.jdUrl}
                    header={props.jd?.title}
                    close={() => setShowJDPopup(false)}
                />
            </div>
            <div className={`${showInitialQuestionsPopup ? 'visible' : 'hidden'}`}>
                <JDQuestionsPreviewPopup 
                    questions={props.jd?.initialScreeningQAs || []}
                    close={() => setShowInitialQuestionsPopup(false)}
                />
            </div>
            <div className={`${showTechnicalQuestionsPopup ? 'visible' : 'hidden'}`}>
                <JDQuestionsPreviewPopup 
                    questions={props.jd?.technicalScreeningQAs || []}
                    close={() => setShowTechnicalQuestionsPopup(false)}
                />
            </div>
            <div className={`${showOpsQuestionsPopup ? 'visible' : 'hidden'}`}>
                <JDQuestionsPreviewPopup 
                    questions={props.jd?.opsScreeningQAs || []}
                    close={() => setShowOpsQuestionsPopup(false)}
                />
            </div>
            <div
                className="flex items-center gap-4 text-darkGray">
                <div onClick={goBack} className="flex items-center gap-2 cursor-pointer">
                    <MdKeyboardArrowLeft size={26}/>
                    <h1 className='text-xl text-primary font-bold'>{props.jd?.title}</h1>
                </div>
                {/* <span onClick={() => setShowJDPopup(true)} className="text-blue underline text-sm cursor-pointer">View JD</span> */}
            </div>
            <div className="px-5 mt-5 flex gap-5">
                <button 
                    onClick={() => setShowJDPopup(true)} 
                    className="bg-blue text-white font-normal text-sm px-6 h-8 rounded-md">
                    View JD
                </button>
                <button 
                    onClick={() => setShowJDDetails(!showJDDetails)} 
                    className="bg-blue text-white font-normal text-sm px-6 h-8 rounded-md">
                    {showJDDetails ? 'Hide JD Details' : 'View JD Details' }
                </button>
            </div>
            <div className={`p-2 py-10 flex flex-col gap-5 font-normal ${showJDDetails ? 'visible': 'hidden'}`}>
                <div className="flex flex-wrap gap-5">
                    <div className={`flex gap-3 items-center ${props.jd?.experience ? 'visible': 'hidden'}`}>
                        <span>* Experience</span>
                        <p className="bg-white border-[#EAECF0] text-brownGray px-4 rounded-md text-sm text-center py-2">{props.jd?.experience}</p>
                    </div>
                    <div className={`flex gap-3 items-center ${props.jd?.location?.length > 0 ? 'visible': 'hidden'}`}>
                        <span>* Location</span>
                        {renderValues(props.jd?.location)}
                    </div>
                    <div className={`flex gap-3 items-center ${props.jd?.employmentType ? 'visible': 'hidden'}`}>
                        <span>* Employment Type</span>
                        <p className="bg-white border-[#EAECF0] text-brownGray px-4 rounded-md text-sm text-center py-2">{props.jd?.employmentType}</p>
                    </div>
                    <div className={`flex gap-3 items-center ${props.jd?.primarySkills?.length > 0 ? 'visible': 'hidden'}`}>
                        <span>* Primary skills</span>
                        {renderValues(props.jd?.primarySkills)}
                    </div>
                    <div className={`flex gap-3 items-center ${props.jd?.secondarySkills?.length > 0 ? 'visible': 'hidden'}`}>
                        <span>* Secondary skills</span>
                        {renderValues(props.jd?.secondarySkills)}
                    </div>
                </div>
                <div className={`flex flex-col gap-2 ${props.jd?.idealCandidateText ? 'visible': 'hidden'}`}>
                    <span>* Ideal Candidate</span>
                    <p className="text-brownGray px-2 rounded-md text-base py-2">{props.jd?.idealCandidateText}</p>
                </div>
                {/* <div className={`flex flex-col gap-2 ${props.jd?.idealCandidateAudioUrl ? 'visible': 'hidden'}`}>
                    <span>* Ideal Candidate</span>
                    <div className='flex items-center w-1/2 gap-5 bg-white border-[#EAECF0] h-10 px-4 rounded-md text-sm text-center py-2'>
                        <span >
                            {props?.jd?.idealCandidateAudioUrl?.substring(props?.jd?.idealCandidateAudioUrl.lastIndexOf('/') + 1, props?.jd?.idealCandidateAudioUrl.indexOf('?'))}
                        </span>
                        <IoPlayCircleOutline 
                            size={20}
                            onClick={playAudio}
                            className={`cursor-pointer ${!isPlaying ? 'visible': 'hidden'}`}
                        />
                        <IoStopCircle 
                            size={20}
                            onClick={stopAudio}
                            className={`cursor-pointer ${isPlaying ? 'visible': 'hidden'}`}
                        />
                    </div>
                    <audio 
                        ref={audioRef} 
                        src={props?.jd?.idealCandidateAudioUrl}
                        onEnded={() => setIsPlaying(false)} 
                    />
                </div> */}
                <div className="flex gap-3">
                    <button
                        onClick={() => setShowInitialQuestionsPopup(true)} 
                        className={`bg-blue text-white font-medium p-2 px-4 rounded-md text-sm ${(config?.enabledServices?.['hrScreening'] || config?.enabledServices?.['bpoHrScreening']) ? 'visible': 'hidden'}`}>Initial Screening Questions</button>
                    <button
                        onClick={() => setShowTechnicalQuestionsPopup(true)} 
                        className={`bg-blue text-white font-medium p-2 px-4 rounded-md text-sm ${config?.enabledServices?.['technicalScreening'] ? 'visible': 'hidden'}`}>Technical Screening Questions</button>
                    <button
                        onClick={() => setShowOpsQuestionsPopup(true)} 
                        className={`bg-blue text-white font-medium p-2 px-4 rounded-md text-sm ${config?.enabledServices?.['opsScreening'] ? 'visible': 'hidden'}`}>Ops Screening Questions</button>
                </div>
            </div>
        </section>
    )
}