import React from 'react';
import AnimatedText from '../Common/AnimatedText';
import { AnimatedDiv } from '../Common/AnimatedDiv';
import { AnimatedImage } from '../Common/AnimatedImage';
import pattern from './../../assets/images/green-ellipse.svg';
import dashboardImage from './../../assets/images/dashboard.png';
import pattern2 from './../../assets/images/dashboard-pattern1.svg';
import pattern3 from './../../assets/images/dashboard-pattern2.svg';

export default function InterviewPlatformSection(){
  return (
    <div className="pt-5">
      <div className="max-w-7xl m-auto flex flex-col gap-5 py-10">
        <AnimatedText className="text-3xl md:text-4xl font-bold text-center md:w-1/2 m-auto">
            <span className="text-l_g">Next gen</span> recruitment platform
        </AnimatedText>
        <AnimatedDiv>
            <p className="text-center text-brownGray md:w-2/3 m-auto px-10 font-normal text-base leading-6 tracking-wider">
            Unlock unprecedented hiring efficiency with a platform that seamlessly manages JD creation, HR assessments, dynamic AI-driven interviews, and comprehensive evaluations.
            </p>
        </AnimatedDiv>
        
        <div className="relative max-w-6xl m-auto flex justify-center md:py-20">

            <div className="relative inline-block w-full px-10 md:px-0 md:w-2/3">
              <img
                alt='pattern'
                src={pattern}
                className="absolute left-0 top-20 -translate-x-1/2 -translate-y-1/2 transform animate-pulse"
              />
              <AnimatedImage
                alt='pattern'
                src={pattern2}
                className="absolute md:right-0 right-9 top-20 translate-x-1/2 -translate-y-1/2 animate-float w-20 md:w-fit"
              />
              <AnimatedImage
                alt='pattern'
                src={pattern3}
                className="absolute -left-10 md:-left-28 -bottom-20 md:-bottom-28 animate-float w-20 md:w-fit"
              />
              <img
                alt="dashboard"
                src={dashboardImage}
                className="relative z-10 m-auto mt-10 object-center"
              />
            </div>
          </div>
        </div>
    </div>
  );
};