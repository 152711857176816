import axiosInstance from ".";

function getExitInterviews(page=1, limit=10){
    return axiosInstance.get(`/exit-interviews?page=${page}&limit=${limit}`)
}

function getExitInterview(id){
    return axiosInstance.get(`/exit-interviews/${id}`)
}

function createExitInterview(data){
    return axiosInstance.post(`/exit-interviews`, data)
}

function updateExitInterview(id, data){
    return axiosInstance.put(`/exit-interviews/${id}`, data)
}

function sendExitInterviewInvite(id){
    return axiosInstance.get(`/exit-interviews/${id}/sendInvite`)
}


const ExitInterviewService = {
    getExitInterview,
    getExitInterviews,
    createExitInterview,
    updateExitInterview,
    sendExitInterviewInvite
}

export default ExitInterviewService;