import { useEffect, useState } from "react"
import {BounceLoader} from 'react-spinners';
import { Dropdown } from 'primereact/dropdown';
import ConsoleHelper from "../../../utils/consoleHelper";
import ScreeningService from "../../../services/screening.service";

export default function OrganizationAvatarBotVoiceComponent(props){
    const {selectedAvatar, selectedBotVoice, selectBotVoice, selectAvatar, } = props;

    const [botVoices, setBotVoices] = useState([]);
    const [loadingVoiceAudio, setLoadingVoiceAudio] = useState(false);

    const avatars = [
        {
            id: 1,
            active: true,
            name: 'avatar1',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar1.svg', 
        },
        {
            id: 2, 
            active: true,
            name: 'avatar2',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar2.jpg', 
        },
        {
            id: 3, 
            active: false,
            name: 'avatar3',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar3.jpg', 
        },
        {
            id: 4, 
            active: false,
            name: 'avatar4',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar4.jpg', 
        },
        {
            id: 5, 
            active: false,
            name: 'avatar5',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar5.jpg', 
        },
        {
            id: 6, 
            active: false,
            name: 'avatar7',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar6.jpg', 
        },
        {
            id: 7, 
            active: false,
            name: 'avatar7',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar7.jpg', 
        },
        {
            id: 8, 
            active: false,
            name: 'avatar8',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar8.jpg', 
        },
        {
            id: 9, 
            active: false,
            name: 'avatar9',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar9.jpg', 
        },
        {
            id: 10, 
            active: false,
            name: 'avatar10',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar10.jpg', 
        },
        {
            id: 11, 
            active: false,
            name: 'avatar11',
            url: 'https://skillassessment.blob.core.windows.net/images/avatars/avatar11.jpg', 
        },
    ];

    const playVoice = async () => {
        try {
            setLoadingVoiceAudio(true);
            const requestData = {
                text: 'Hello, This is Athmiya'
            };
            if(typeof selectedBotVoice === Object)
                requestData['voice'] = selectedBotVoice['value'];
            else requestData['voice'] = selectedBotVoice;

            const response = await ScreeningService.getVoiceAudio(requestData);
            setLoadingVoiceAudio(false);
            if(response.status === 200){
                const url = createAudioUrl(response.data['audio_stream']);
                const audio = new Audio(url);;
                audio.play();  
            }
        } catch (error) {
            setLoadingVoiceAudio(false);
            ConsoleHelper.log(`playVoice ${JSON.stringify(error)}`)
        }
    };

    const createAudioUrl = (audioStream) => {
        const binaryString = atob(audioStream);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++)
            bytes[i] = binaryString.charCodeAt(i);
        const blob = new Blob([bytes], { type: 'audio/mpeg' });
        return URL.createObjectURL(blob);
    };

    useEffect(() => {
        async function getVoices(){
            try {
                const response = await ScreeningService.getVoices();
                if(response.status === 200){
                    const data = response.data;
                    setBotVoices(data?.map((item) => ({label: item['name'], value: item['name'], language: item['language']})));
                }
            } catch (error) {
                ConsoleHelper.error(JSON.stringify(error));
            }
        }

        getVoices();
    }, []);
    
    return (
        <div className="bg-white rounded-md p-5 flex text-darkGray flex-col gap-5">
            <h1 className='text-lg text-primary font-bold'>Avatar and Bot Voice Configure</h1>
            <div className="flex-1 flex gap-2 justify-between items-center">
                <label 
                    className="text-darkGray font-medium text-base" 
                    htmlFor="selectedBotVoice">* Bot Audio Voice</label>
                <div className='flex flex-col items-end'>
                    <Dropdown
                        checkmark={true} 
                        optionLabel="label"  
                        options={botVoices}  
                        value={selectedBotVoice}
                        highlightOnSelect={false}
                        placeholder="Select Bot Voice"
                        defaultValue={selectedBotVoice}
                        onChange={(e) => selectBotVoice(e.value)}  
                        className="h-10 border-l_border border-[1px]" 
                        pt={{
                            itemLabel: {className: 'text-sm'},
                            input: {className: 'text-sm p-2 font-semibold text-sm'},
                        }} 
                    />
                    <button 
                        onClick={playVoice.bind(this)} 
                        className='text-blue font-semibold text-xs my-2'>
                        {loadingVoiceAudio && <BounceLoader size={14} color="#3C99CA" /> }
                        <span className={`font-semibold text-xs ${!loadingVoiceAudio ? 'visible': 'hidden'}`}>Play Voice</span>
                    </button>
                </div>
            </div>
            <div className="flex-1 flex gap-5 flex-col">
                <label 
                    className="text-darkGray font-medium text-base" 
                    htmlFor="recordingResolution">* Select Bot Avatar</label>
                <ul className='flex gap-10 flex-wrap'> 
                    {avatars?.map((avatar) => (
                        <li 
                            onClick={() => {
                                if(avatar.active) selectAvatar(avatar.name)
                            }}
                            className={`relative flex justify-center items-center ${(selectedAvatar === avatar.name && avatar.active) ? 'border-blue rounded-md border-4' : ''}`}>
                            <img 
                                alt='avatar'
                                src={avatar['url']}
                                key={avatar['id']?.toString()}
                                className={`h-24 w-24 rounded ${avatar.active ? 'cursor-pointer opacity-100': 'opacity-30'}`}
                            />
                            {!avatar?.active && (
                                <div className='flex justify-center items-center absolute bottom-2'>
                                    <span className='font-medium text-xs text-center'>Available soon</span>
                                </div>
                                )
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}