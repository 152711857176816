import { useAuth } from './AuthContext';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfigService from '../services/config.service';
import LoadingComponent from '../components/Common/LoadingComponent';


const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const {user} = useAuth();

    const getEnabledServices = (hasOrganization) => ({
        resumeScreening: true,
        hrScreening: hasOrganization ? false : true,
        technicalScreening: hasOrganization ? false : true,
        opsScreening: false,
        exitInterview: false,
        bpoHrScreening: false,
        videoProfiling: false,
    });
    
    const enabledServices = getEnabledServices(!!user?.organization);

    const [config, setConfig] = useState({enabledServices});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await ConfigService.getConfig(user?.['organization']);
                if (response.status === 200) {
                    const data = response.data['data'];
                    const configData = {
                        withWeightage: data?.['withWeightage'] || false,
                        enabledServices: data?.['enabledServices'] || {}
                    };
                    setConfig(configData);
                }
                else navigate('/auth/login');
            } catch (error) {
                console.error("Failed to fetch config:", error);
                navigate('/auth/login');
            } finally {
                setLoading(false);
            }
        };

        if(user && user?.['organization'])
            fetchConfig();

    }, [user]);

    if(loading) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <LoadingComponent />
            </div>
        )
    }

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);
