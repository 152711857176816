import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAnomalies } from './AnomaliesContext';

const MonitorContext = createContext();

export function MonitorProvider({ children }) {
  const { anomaliesData, updateAnomalies } = useAnomalies();
  const [hasMultipleMonitors, setHasMultipleMonitors] = useState(false);

  useEffect(() => {
    const checkMonitors = () => {
      const multipleMonitorsDetected = window.screen && window.screen.isExtended;
      if (multipleMonitorsDetected !== hasMultipleMonitors) {
        updateAnomalies({ 
          multi_monitor_detected_count: anomaliesData.multi_monitor_detected_count + 1 
        });
        setHasMultipleMonitors(multipleMonitorsDetected);
      }
    };

    const intervalId = setInterval(checkMonitors, 1000);

    return () => clearInterval(intervalId);
  }, [hasMultipleMonitors]);


  return (
    <MonitorContext.Provider value={{ hasMultipleMonitors }}>
      {children}
    </MonitorContext.Provider>
  );
}

export function useMonitorStatus() {
  return useContext(MonitorContext);
}