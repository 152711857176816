/* eslint-disable react-hooks/exhaustive-deps */
import propTypes from 'prop-types';
import { IoMdSend } from "react-icons/io";
import React, { useRef, useEffect, useState } from 'react';
import {InputText} from 'primereact/inputtext';
import { useMediaContext } from '../../contextProviders/InterviewMediaContext';
import { useSessionContext } from '../../contextProviders/SessionContext';
import LogService from '../../services/log.service';

const VideoPreview = (props) => {
  const videoRef = useRef(null);
  const [value, setValue] = useState(null);
  const {submitTextAnswer} = props;
  const {transcripts, sessionInfo} = useSessionContext();
  let {videoStream, setVideoStream} = useMediaContext();

  const token = sessionInfo?.token || localStorage.getItem('sessionToken');

  const isTextQuestion = transcripts[transcripts?.length - 1]?.is_text_question;

  useEffect(() => {
    async function init(){
      if(!videoStream){
        videoStream = await navigator.mediaDevices.getUserMedia({ 
          video: true, 
          audio: false 
        });
        setVideoStream(videoStream);
      }

      if(videoRef.current){
        videoRef.current.srcObject = videoStream;
      }
    }
    init();
  }, [videoStream]);

  const submit = async () => {
    try {
      await submitTextAnswer(value);
      LogService.uploadLog(token, sessionInfo?.session_id, `submitTextAnswer submited answer ${value}`);
      setValue("");
    } catch (error) {
      LogService.uploadLog(token, sessionInfo?.session_id, `VideoPreview submit onerror`);
    }
  }

  return (
    <div className="relative h-full overflow-hidden">
      <div className='absolute left-5 top-5 flex bg-white text-xs 2xl:text-sm h-9 2xl:h-10 rounded-full items-center justify-center px-5'>
        {!props.isRecording && <span className="text-gray-900">Recording Off</span>}
        {props.isRecording && (
          <div className='flex gap-2 justify-center items-center'>
            <div className='h-4 w-4 rounded-full bg-dr'></div>
            <span className="">Recording On</span>
          </div>
        )}
      </div>
      <video 
          muted 
          autoPlay 
          ref={videoRef} 
          disablePictureInPicture
          className='h-full aspect-video rounded-2xl bg-black'
      />
      <div className={`flex absolute z-40 bottom-5 left-10 w-full gap-5 ${isTextQuestion ? 'visible': 'hidden'}`}>
        <InputText 
          id="bpoOpsText"
          value={value}
          placeholder="Enter Text" 
          onChange={(e) => setValue(e.target.value)}
          className='h-10 bg-white w-3/5 px-4 text-sm'
        />
        <button 
          onClick={submit}
          className='bg-white w-10 h-10 rounded-full shadow-md justify-center items-center flex text-primary'>
          <IoMdSend size={24}/>
        </button>
      </div>
  </div>
  );
}

VideoPreview.propTypes = {
  isRecording: propTypes.bool,
  submitTextAnswer: propTypes.func
}

export default React.memo(VideoPreview);