import { CgProfile } from "react-icons/cg";
import { GrConfigure } from "react-icons/gr";
import { TbPrompt } from "react-icons/tb";
import { useEffect, useState } from 'react';
import logo from './../../assets/icons/white-logo.svg';
import logo2 from './../../assets/icons/logo-3.svg';
import jdLogo from './../../assets/icons/jd.svg';
import supportLogo from './../../assets/icons/support.svg';
import settingsLogo from './../../assets/icons/settings.svg';
import dashboardLogo from './../../assets/icons/dashboard.svg';
import logoutLogo from './../../assets/icons/logout.svg';
import exitInterviewLogo from './../../assets/icons/exit.svg';
import SettingsDialog from "./SettingsDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contextProviders/AuthContext';
import { useConfig } from "../../contextProviders/ConfigContext";
import userManagementLogo from './../../assets/icons/user-management.svg';
import { Tooltip } from 'primereact/tooltip';
import { SlOrganization } from "react-icons/sl";
import { capitalizeFirstLetterOfEachWord } from "../../utils/common";

export default function Sidebar() {
    const { user, logout } = useAuth();
    const config = useConfig();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedRoute, setSelectedRoute] = useState(1);
    const [isMinimized, setIsMinimized] = useState(true);
    const [showSettingDialog, setShowSettingDialog] = useState(false);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

    const logoutUser = () => {
        logout();
    };

    const routes = [
        { id: 1, Icon: dashboardLogo, route: '/dashboard', label: 'Dashboard', tooltipId: 'dashboard' },
        ...(user?.role !== 'admin' ? [
            { id: 2, Icon: jdLogo, route: '/dashboard/job-description', label: 'Job Description / Resumes', tooltipId: 'job-description' },
        ] : []),
        ...(user?.role === 'admin' ? [
            {id: 3, Icon: SlOrganization, route: '/dashboard/organisations-management', label: 'Organisations', tooltipId: 'organisations-management'},
        ] : []),
        ...(user?.role === 'hrAdmin' ? [
            {id: 4, Icon: userManagementLogo, route: '/dashboard/user-management', label: 'User Management', tooltipId: 'user-management'},
        ] : []),
        ...(config?.enabledServices?.exitInterview && user?.role !== 'admin' ? [{ id: 5, Icon: exitInterviewLogo, tooltipId: 'exit-interview', route: '/dashboard/exit-interview', label: 'Exit Interview' }] : []),
        ...(user?.role !== 'admin' ? [
            { id: 6, Icon: supportLogo, route: '/dashboard/support', label: 'Support', tooltipId: 'support' },
        ] : []),
        ...(user?.role === 'admin' ? [
            { id: 7, Icon: TbPrompt, route: '/dashboard/prompts-management', label: 'Prompts', tooltipId: 'prompts' },
        ] : []),
        ...(user?.role === 'admin' ? [
            { id: 8, Icon: exitInterviewLogo, route: '/dashboard/blogs-management', label: 'Blogs', tooltipId: 'blogs' },
        ] : []),
        ...(user?.role !== 'admin' ? [
            { id: 9, Icon: GrConfigure, route: '/dashboard/config', label: 'Configuration', tooltipId: 'config' },
        ] : []),
    ];

    useEffect(() => {
        if(location.pathname === '/dashboard'){
            setSelectedRoute(1);
            return;
        }
        routes.shift();
        const matchedRoute = routes.find(route => location.pathname.includes(route.route));
        setSelectedRoute(matchedRoute ? matchedRoute.id : 1);
    }, [location.pathname]);

    const selectRoute = (routeId, route) => {
        setSelectedRoute(routeId);
        navigate(route);
    };

    return (
        <div className={`bg-primary min-h-100vh flex flex-col items-center ${isMinimized ? 'w-[80px]' : 'w-[240px]'} p-5 transition-width duration-300`}>
            <div className='py-5 flex justify-between w-full'>
                <img 
                    src={!isMinimized ? logo : logo2} 
                    alt='logo'
                    className={`${isMinimized ? 'h-8': 'h-12'} cursor-pointer`}
                    onClick={() => navigate('/dashboard')}
                />
                <button 
                    onClick={() => setIsMinimized(!isMinimized)} 
                    className="text-white focus:outline-none"
                >
                    {isMinimized ? '→' : '←'}
                </button>
            </div>

            {showSettingDialog && (
                <SettingsDialog 
                    onHide={() => setShowSettingDialog(false)}
                    showChangePasswordDialog={(event) => {
                        event.preventDefault();
                        setShowChangePasswordDialog(true);
                    }}
                />
            )}
            {showChangePasswordDialog && (
                <ChangePasswordDialog onHide={() => setShowChangePasswordDialog(false)} />    
            )}

            <hr className='border-0 border-b-[.1px] border-white w-full' />

            <ul className='flex flex-1 flex-col w-full py-5 gap-4'>
                {routes.map(({ id, Icon, route, label, tooltipId }) => (
                    <li 
                        key={id}
                        id={`tooltip-${tooltipId}`}
                        onClick={() => selectRoute(id, route)}
                        className={`${id === selectedRoute ? 'bg-blue' : 'bg-primary'} text-white cursor-pointer px-2 w-full h-10 gap-3 flex items-center rounded-sm`}
                    >
                        {(id === 7 || id === 9 || id === 3) ? ( 
                            <Icon className='h-5 w-5' />) : (
                            <img 
                                src={Icon} alt='logo'
                                className='h-5 w-5'
                            />
                        )}
                        {isMinimized && (
                            <Tooltip
                                position="right" 
                                content={label}
                                target={`#tooltip-${tooltipId}`} 
                            />
                        )}
                        {!isMinimized && <span className='text-sm font-normal'>{label}</span>}
                    </li>
                ))}
            </ul>

            <hr className='border-0 border-b-[.1px] border-white w-full' />

            <ul className='flex flex-col w-full py-5 gap-5'>
                <li className={`cursor-pointer w-full h-10 flex items-center ${isMinimized ? 'justify-center': 'justify-start px-2'} gap-3 rounded-sm`}>
                    <div className='bg-white rounded-full flex justify-center items-center'>
                        {user?.imageUrl ? (
                            <img src={user.imageUrl} alt='profile' className='rounded-full h-7 w-7' />
                        ) : (
                            <CgProfile size={20} className='text-primary' />
                        )}
                    </div>
                    {!isMinimized && (
                        <div className='text-white'>
                            <h3 className='text-sm overflow-hidden whitespace-nowrap'>{user?.['organizationName']}</h3>
                            {user?.role?.toLowerCase() === 'hradmin' && user?.['firstName'] && <span className='text-xs'>{capitalizeFirstLetterOfEachWord(user?.['firstName'])} | HR Admin</span>}
                            {user?.role?.toLowerCase() === 'hradmin' && !user?.['firstName'] && <span className='text-xs'>HR Admin</span>}
                            {user?.role?.toLowerCase() === 'hr' && user?.['firstName'] && <span className='text-xs'>{capitalizeFirstLetterOfEachWord(user?.['firstName'])} | HR</span>}
                            {user?.role?.toLowerCase() === 'hr' && !user?.['firstName'] && <span className='text-xs'>HR</span>}
                            {user?.role?.toLowerCase() === 'admin' && <span className='text-xs'>Admin</span>}
                        </div>
                    )}
                </li>
                <li onClick={() => setShowSettingDialog(true)} className="cursor-pointer w-full h-10 flex items-center rounded-sm">
                    <img src={settingsLogo} alt='settings' className='h-10 w-10' />
                    {!isMinimized && <span className='text-sm text-white'>Settings</span>}
                </li>
                <li onClick={logoutUser} className="cursor-pointer w-full h-10 flex items-center rounded-sm">
                    <img src={logoutLogo} alt='logout' className='h-10 w-10' />
                    {!isMinimized && <span className='text-sm text-white'>Logout</span>}
                </li>
            </ul>
        </div>
    );
}
