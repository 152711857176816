import linePattern from './../../assets/images/line-pattern.svg'
import AnimatedText from "../Common/AnimatedText";

export default function FutureEnvision(){
    return (
        <section className="bg-white">
            <div className='w-full md:max-w-[75%] mx-auto py-10 md:py-12 flex items-center gap-5 md:gap-0 flex-col justify-center'>
                <AnimatedText className="text-2xl md:text-3xl font-bold flex-col gap-3 text-darkGray tracking-wide leading-relaxed text-center md:text-left">
                    A future we <span className="text-l_g">envision</span>                   
                </AnimatedText>
                <div className='flex md:gap-20 justify-center items-center flex-1 md:pt-20 md:flex-row flex-col px-5 md:px-0'>
                    <div className="flex flex-col gap-2 p-5 md:p-10 relative flex-1">
                        <img
                            alt='line pattern'
                            src={linePattern}
                            className='absolute left-0 h-1/2'
                        />
                        <h1 className="bg-gradient-to-b text-5xl md:text-7xl font-bold from-[#2B4360] to-[#3C99CA] text-primary bg-clip-text">01</h1>
                        <div className="flex flex-col text-darkGray">
                            <h3 className="font-bold md:text-xl">Global, 24-Hour</h3>
                            <h3 className="font-bold md:text-xl">Role Fulfillment</h3>
                            <p className="text-brownGray font-normal text-base leading-tight tracking-wide text-left pt-4">
                            A world where enterprises can fill any role, in any industry, across the globe within 24 hours
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-5 md:p-10 relative flex-1">
                        <img
                            alt='line pattern'
                            src={linePattern}
                            className='absolute left-0 h-1/2'
                        />
                        <h1 className="bg-gradient-to-b text-5xl md:text-7xl font-bold from-[#2B4360] to-[#3C99CA] text-primary bg-clip-text">02</h1>
                        <div className="flex flex-col text-darkGray">
                            <h3 className="font-bold md:text-xl">Resume-less and </h3>
                            <h3 className="font-bold md:text-xl">JD-less Hiring</h3>
                            <p className="text-brownGray font-normal text-base leading-tight tracking-wide text-left pt-4">
                            A future where traditional resumes and job descriptions are optional
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex md:gap-20 justify-center items-center flex-1 md:flex-row flex-col px-5 md:px-0'>
                    <div className="flex flex-col gap-2 p-5 md:p-10 relative flex-1">
                        <img
                            alt='line pattern'
                            src={linePattern}
                            className='absolute left-0 h-1/2'
                        />
                        <h1 className="bg-gradient-to-b text-5xl md:text-7xl font-bold from-[#2B4360] to-[#3C99CA] text-primary bg-clip-text">03</h1>
                        <div className="flex flex-col text-darkGray">
                            <h3 className="font-bold md:text-xl">Bias-Free</h3>
                            <h3 className="font-bold md:text-xl">Evaluations</h3>
                            <p className="text-brownGray font-normal text-base leading-tight tracking-wide text-left pt-4">
                                An unbiased hiring process where AI-driven assessments ensure every candidate is evaluated fairly and consistently
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-5 md:p-10 relative flex-1">
                        <img
                            alt='line pattern'
                            src={linePattern}
                            className='absolute left-0 h-1/2'
                        />
                        <h1 className="bg-gradient-to-b text-5xl md:text-7xl font-bold from-[#2B4360] to-[#3C99CA] text-primary bg-clip-text">04</h1>
                        <div className="flex flex-col text-darkGray">
                            <h3 className="font-bold md:text-xl">Seamless,</h3>
                            <h3 className="font-bold md:text-xl">Automated Hiring</h3>
                            <p className="text-brownGray font-normal text-base leading-tight tracking-wide text-left pt-4">
                                A world where every step of the recruitment process, from job description creation to final interviews, is fully automated and optimized, requiring zero touch from HR
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}