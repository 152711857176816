import { MdClear } from "react-icons/md";
import PropTypes from 'prop-types';
import CustomReactMarkdown from "../Common/CustomReactMarkdown";

function AudioAnalysisReportPopup(props){
    const {close, audioAnalysis} = props;
    let fillerWords = [];
    let repeatedWords = [];

    if(audioAnalysis?.filler_words && Object.keys(audioAnalysis?.filler_words).length){
        fillerWords = Object.entries(audioAnalysis?.filler_words)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5);
    }


    if(audioAnalysis?.most_repeated_words && Object.keys(audioAnalysis?.most_repeated_words).length){
        repeatedWords = Object.entries(audioAnalysis?.most_repeated_words)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5);
    }


    return (
        <div className="absolute top-0 left-0 bottom-6 h-screen w-screen flex justify-center items-center bg-transparent z-50 overflow-hidden">
            <div className='w-1/2 max-h-[95%] flex flex-col gap-5 relative'>
                <div
                    onClick={close}
                    className='rounded-full shadow-md top-6 absolute z-20 -right-2 h-8 w-8 bg-white flex justify-center items-center text-darkGray cursor-pointer'>
                    <MdClear size={20}/>
                </div>
                <div className='my-10 max-h-[95%] flex flex-col gap-5 bg-white p-5 rounded-xl overflow-y-scroll'>
                    <h1 className="text-xl font-bold text-primary">Communication Skills</h1>
                    <div className="flex flex-col gap-10">
                        <div className={`flex ${fillerWords.length + repeatedWords.length > 0 ? 'visible': 'hidden'}`}>
                            <div className={`flex flex-1 flex-col gap-2 ${fillerWords.length ? 'visible' : 'hidden'}`}>
                                <h2 className="font-semibold text-darkGray">Filler words : </h2>
                                <ul className="flex flex-col gap-2">
                                    {fillerWords?.map((item) => (
                                        <li className="flex gap-2">
                                            <h3 className="font-medium w-32">{item[0]}</h3>
                                            <span className="bg-lo rounded-full font-semibold text-xs text-do py-1 px-2">
                                                {item[1]} times
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={`flex flex-1 flex-col gap-2 ${repeatedWords.length ? 'visible' : 'hidden'}`}>
                                <h2 className="font-semibold text-darkGray">Repeated words</h2>
                                <ul className="flex flex-col gap-2">
                                    {repeatedWords?.map((item) => (
                                        <li className="flex gap-2">
                                            <h3 className="font-medium w-32">{item[0]}</h3>
                                            <span className="bg-lo rounded-full font-semibold text-xs text-do py-1 px-2">
                                                {item[1]} times
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <CustomReactMarkdown data={audioAnalysis?.['summary']?.split("- **Overall Assessment**")[0]} />
                    </div>
                </div>
            </div>
        </div>
    )
}

AudioAnalysisReportPopup.propTypes = {
    close: PropTypes.func,
    audioAnalysis: PropTypes.any
}

export default AudioAnalysisReportPopup;